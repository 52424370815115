import React,{useState,useEffect} from 'react'
import './appointment.css';
import { Link, useParams,useNavigate } from 'react-router-dom';
import { Button,Badge } from 'react-bootstrap';
import http from "../../http"
import TextLineLoader from '../../components/Loaders/TextLineLoader';
import { BsFillCursorFill} from "react-icons/bs";
import loginCheck from '../../helpers/loginCheck';
import Layout from '../../components/layouts/layout';
import ComponentGuard from '../../helpers/ComponentGuard';
import Cookies from 'universal-cookie';


const AppointmentRead = () => {
    const [appointments, setAppointments] = useState('');
    const {id} = useParams()
    const cookies = new Cookies();

    const navigate = useNavigate();

    useEffect(()=> {
        if(!loginCheck()){
            console.log("this works")
            navigate("/login");
        }
    });

    console.log(appointments)
    useEffect(()=>{
        fetchSingleAppointments();
    },[]);

    const fetchSingleAppointments = () => {
        http.get('appointment/get/'+id, {
            headers:{
             "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
            }  
        }).then(res=>{
            setAppointments(res.data.data);
        })
    }

    const formateDate = (value) =>{
        let tempDate = new Date(value).toUTCString().toString();
        let date =  tempDate.substring(0, 16)
        var hours = tempDate.substring(17, 19);
        var minutes = tempDate.substring(20, 22);
        // console.log(hours,minutes)
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        // console.log(strTime);
        return date+" "+strTime
    }

  return (
    <>
    <Layout>
    <div className="read">
        <div className="card-body">
            <div className="col-md-12">
            {appointments == "" ? 
            <><TextLineLoader/><TextLineLoader/><TextLineLoader/></>:
                <div className="row">
                    <div className="col-sm-6">
                        <label>Name:</label>
                    </div>
                    <div className="col-sm-6">
                        {(appointments.name)?appointments.name : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Type:</label>
                    </div>
                    <div className="col-sm-6">
                        {(appointments.type)?appointments.type : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Gender:</label>
                    </div>
                    <div className="col-sm-6">
                        {(appointments.gender)?appointments.gender : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Phone:</label>
                    </div>
                    <div className="col-sm-6">
                        {(appointments.user_for)? appointments?.userfor?.phone : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Email:</label>
                    </div>
                    <div className="col-sm-6">
                        {(appointments.email)?appointments.email : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>


                    <div className="col-sm-6">
                        <label>Birth Date:</label>
                    </div>
                    <div className="col-sm-6">
                        {(appointments.birth_date)?appointments.birth_date.substring(0, 10) : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Nationality:</label>
                    </div>
                    <div className="col-sm-6">
                        {(appointments.nationality)?appointments.nationality : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Relation With Patient:</label>
                    </div>
                    <div className="col-sm-6">
                        {(appointments.relation_with_patient)?appointments.relation_with_patient : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>identity Ref:</label>
                    </div>
                    <div className="col-sm-6">
                        {(appointments.identity_ref)?appointments.identity_ref : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Payment Status:</label>
                    </div>
                    <div className="col-sm-6">
                            {(appointments.payment_status == "UNPAID")? 
                            <Badge bg="danger" text="light">{appointments.payment_status}</Badge>
                            :
                            <Badge bg="success" text="light">{appointments.payment_status}</Badge>}
                    </div>

                    <div className="col-sm-6">
                        <label>Status:</label>
                    </div>
                    <div className="col-sm-6">
                    {(appointments.status == "CONFIRM")
                            ?<Badge bg="success" text="light">{appointments.status}</Badge>
                            : (appointments.status == "PENDING") ? 
                            <Badge bg="warning" text="light">{appointments.status}</Badge>
                            :<Badge bg="danger" text="light">{appointments.status}</Badge>}
                    </div>

                    <div className="col-sm-6">
                        <label>Doctor Name:</label>
                    </div>
                    <div className="col-sm-6">
                        {(appointments.doctor.id)?appointments.doctor.name : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                
                    <div className="col-sm-6">
                        <label>Visit Time:</label>
                    </div>
                    <div className="col-sm-6">
                        {(appointments.date_time)? formateDate(appointments.date_time) : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    

                </div>
            }
            </div>
        </div>
        <ComponentGuard needsPermission={['appointment.update']}>
            <Link to={"/appointment/edit/"+appointments.id}>
                <Button className="read_back" variant='info'>Edit</Button>
            </Link>
        </ComponentGuard>
    </div>
    </Layout>
    </>
  )
}

export default AppointmentRead