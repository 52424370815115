import React ,{useState,useEffect} from 'react'
import './role.css';
import { Link , useNavigate,useParams} from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Button, Form, Toast,Badge } from 'react-bootstrap';
import http from "../../http"
import {type,subType,level,order,lang,department} from '../enum/enum'
import Layout from '../../components/layouts/layout';
import loginCheck from '../../helpers/loginCheck';
import Cookies from 'universal-cookie';
import { SelectPicker,CheckPicker } from 'rsuite';
import { Checkbox, CheckboxGroup } from 'rsuite';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FullEditor from 'ckeditor5-build-full'

const RolePermission = () => {
    const { register, handleSubmit } = useForm();
    const [data,setData] = useState('');
    const [permissionsList,setPermissionsList] = useState([]);
    const [roles, setRoles] = useState('');
    const [keyss,setKeys] = useState([])
    const [values,setValues] = useState([])
    const [getSuccess,setSuccess] = useState(false);
    const [getError,setError] = useState('');
    const [getErrorObj,setErrorObj] = useState({})
    const [permissions, setPermissions] = useState([]);
    const [getPer, setPer] = useState([]);
    const [getLoader,setLoader] = useState(false);
    const cookies = new Cookies();
    const navigate = useNavigate();
    const {id} = useParams()

    useEffect(()=> {
        if(!loginCheck()){
            console.log("this works")
            navigate("/login");
        }
    });

    useEffect(()=>{
        fetchAllPermission();
    },[]);

    const fetchAllPermission = () => {
        http.get(`/role/get/${id}`,{
            headers:{
              "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
             } 
          }).then(res=>{
            console.log(res.data.data)
            setPer(res.data.data);
        })
    }
 
    console.log(getPer);
    const getSearch = (val) =>{
        if(val.length > 0){
            http.get(`/permission/search/${val}`,{
                headers:{
                  "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
                 } 
              }).then((response)=>{
                console.log(response.data.data.data)
                setValues(response.data.data.data);
            })
        }else if(permissions.permission){
            http.get(`/permission/get/${permissions.permission}`,{
                headers:{
                  "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
                 } 
              }).then((response)=>{
                setValues([response.data.data]);
            })
        } else {
            setValues([]);
        }
    }
    useEffect((val) =>{
        http.get(`/permission/all`,{
            headers:{
                "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
                } 
            }).then((response)=>{
            console.log(response.data.data)
            let plist = {}
            response.data.data.map(perm => {
                console.log(perm.name.split(".")[0] in plist);
                perm.name.split(".")[0] in plist ? 
                    plist[`${perm.name.split(".")[0]}`] = [...plist[perm.name.split(".")[0]] ,perm.name] 
                : 
                    plist[perm.name.split(".")[0]] = [perm.name]
            });
            console.log(plist);
            setPermissionsList(plist);
        })
    }, []);

    const onSubmit = (data, e) => {       
        data.permissions =  [...permissions];
        // data.user_id = cookies.get('userAuth').id
        console.log(data);
        setLoader(true)
        http.post(`/role/give-permission-to-role?id=${id}`,data, {
            headers:{
             "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
             "Content-type": "Application/json"
            }  
        }).then((res)=>{
            console.log(res);
            // setSuccess(true);
            localStorage.setItem("success", true);
            // setLoader(false);
            setTimeout(()=>{
              navigate("/role");
              },1000)
            })
        .catch((err)=> {
            setLoader(false);
            console.log(err)
            // console.log(Object.keys(err.response.data.data))
            let errs = err.response.data.data;
            let keys = Object.keys(errs)
            console.log(keys);
            let errors = {};
            keys.map((key)=> errors[key] = errs[key][0])
            console.info(errors);
            setError(err.response.data)
            
            setErrorObj(errors)
        })
    }
    const onError = (errors, e) => console.log(errors, e);

    const handleCheckAll = (value, checked) => {

        let permsToSet = permissionsList[value];
        const permsAlreadySet = permissions.filter(perm => permsToSet.includes(perm));
        
        console.log(permsToSet, permsAlreadySet, checked);
        if (checked)  {
            permsToSet =  permsToSet.filter(perm => !permsAlreadySet.includes(perm));
            console.log(permsToSet);
            setPermissions([...permissions, ...permsToSet]);
        }else {
            setPermissions(permissions.filter(perm => !permsToSet.includes(perm)));
        }
        
    };

    const handleChange = (value) => {
        setPermissions(value)
    }

    useEffect(() =>{
        setPermissions(getPer?.permissions?.map(per => per.name));
    },[getPer])

  return (
    <>
    <Layout>
    { getLoader && 
          <div className="preloader">
              <div className="overlay__inner">
                  <div className="overlay__content"><span className="spin"></span></div>
              </div>
          </div>
        }
        <div className="form_design">
            <h2 style={{color: "black",textDecoration: "underline"}}>Give Permission</h2>
            
            <Form onSubmit={handleSubmit(onSubmit)}>
                {Object.keys(permissionsList).map((permSegment) => {
                    return(<>
                        <h5>
                            <Checkbox
                                indeterminate={permissions?.filter(perm => perm.split(".")[0] === permSegment).length > 0 && permissions?.filter(perm => perm.split(".")[0] === permSegment).length < permissionsList[permSegment].length}
                                checked={permissions?.filter(perm => perm.split(".")[0] === permSegment).length === permissionsList[permSegment].length}
                                onChange={handleCheckAll}
                                value={permSegment}
                                >
                                <span style={{fontWeight:"bold"}}>{permSegment}</span>                            
                            </Checkbox>
                        </h5>
                        <CheckboxGroup name="grantPermissions" value={permissions} onChange={handleChange}>
                            {permissionsList[permSegment].map(segmentPerm => (
                                <Checkbox 
                                    key={segmentPerm}
                                    value={segmentPerm} 
                                    >
                                    {segmentPerm}
                                </Checkbox>
                            ))}
                            <br/>
                            <br/>

                        </CheckboxGroup>
                    </>)
                })}

                {/* <Form.Group>
                    {getPer && 
                    <>
                        <Form.Label className="form_label">Permission List</Form.Label>
                        <p>Role Name: {getPer.name}</p>
                        <div className="d-flex gap-1 p-2 flex-wrap" style={{border:"1px solid #e5e5ea",borderRadius:"6px"}}>
                                {
                                    getPer?.permissions?.map(ele =>
                                        <Badge style={{fontSize:"1em",}}>{ele.name}</Badge>
                                        )
                                }
                        </div>
                    </>
                    }
                </Form.Group> */}

                {/* <Form.Group>
                    <Form.Label className="form_label">Add Permission</Form.Label>
                    <CheckPicker  
                        name="permission" 
                        data={values?.map((item) => ({ label: item.name, value: item.id }))} 
                        style={{ width: "100%" }} 
                        onSearch={(value) => getSearch(value)} 
                        onChange={(value) => setPermissions({...permissions, "permissions": value})} 
                    />

                    {getErrorObj.permissions? 
                    <span className="text-danger">
                    {getErrorObj.permissions}
                    </span> : null
                    }
                </Form.Group> */}

                <div className="mt-4">
                    <Button className="action__btn" variant="primary" type="submit">
                        Save
                    </Button>
                    <Link to="/role">
                        <Button className="action__btn" variant="info">
                            Back to Home
                        </Button>
                    </Link>
                </div>
            </Form>
        </div>  
    </Layout>
    </>
  )
}

export default RolePermission