import React ,{useState,useEffect} from 'react'
import './image.css';
import { Link , useNavigate} from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Button, Form, Toast } from 'react-bootstrap';
import http from "../../http"
import {lang} from '../enum/enum'
import Layout from '../../components/layouts/layout';
import loginCheck from '../../helpers/loginCheck';
import Cookies from 'universal-cookie';
import { SelectPicker } from 'rsuite';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FullEditor from 'ckeditor5-build-full'

const ImageCreate = () => {
    const { register, handleSubmit,formState: { errors } } = useForm();
    const [data,setData] = useState('');
    const [images, setImages] = useState('');
    const [getSuccess,setSuccess] = useState(false);
    const [getError,setError] = useState('');
    const [getErrorObj,setErrorObj] = useState({})
    const [parents, setParents] = useState([]);
    const [getAlbumTitle, setAlbumTitle] = useState([]);
    const [getLangError,setLangError] = useState('');
    const [getLoader,setLoader] = useState(false);
    const [imageFormValues,setImageFormValues] = useState('');
    const navigate = useNavigate();
    const cookies = new Cookies();



    useEffect(()=> {
        if(!loginCheck()){
            console.log("this works")
            navigate("/login");
        }
    });


    // useEffect(()=>{
    //     fetchAllParents();
    // },[]);

    // const fetchAllParents = () => {
    //     http.get('/news/parent', {
    //         headers:{
    //          "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
    //         }  
    //     }).then(res=>{
    //         setParents(res.data.data);
    //     })
    // }

    const getAlbumTitleSearch = (val) =>{
        if(val.length > 0){
            http.get(`/album/search/${val}`, {
                headers:{
                 "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
                }  
            }).then((response)=>{
                setAlbumTitle(response.data.data.data);
            })
        }else if(images.album_id){
            http.get(`/album/get/${images.album_id}`, {
                headers:{
                 "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
                }  
            }).then((response)=>{
                setAlbumTitle([response.data.data]);
            })
        } else {
            setAlbumTitle([]);
        }
    }
    
    function removeEmpty(obj) {
        return Object.fromEntries(
          Object.entries(obj)
            .filter(([_, v]) => v != null && v != "")
            .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
        );
      }
    
    const onSubmit = (data, e) => {
        setErrorObj({})
        setLangError('')
        const image_url = [...data.image_url];   
        
        console.log(image_url);
        let hasNonImage = image_url.map((val)=>{
                return ["image/jpeg", "image/png", "image/jpg" ].includes(val.type)
            }           
        ).includes(false)
        console.log(hasNonImage);

        if(hasNonImage){
            setImageFormValues("The image field must be a file of type: png, jpg, jpeg, svg.")
            return
        } 
        delete images.image_url;
        data = {...data, ...images};
        data.user_id = cookies.get('userAuth').id
        data = removeEmpty(data)
        data.image_url = image_url;
        console.log(data)
        // return
        setLoader(true)
        http.post('/album/image/create',data, {
                headers:{
                "Authorization" :"Bearer "+ cookies.get('userAuth').token,
                }  
            }
        )
        .then((res)=>{
            console.log(res);
            setSuccess(true);
            localStorage.setItem("success", true);
            // setLoader(false);
            setTimeout(()=>{
              navigate("/image");
              },1000)
        })
        .catch((err)=> {
            setLoader(false);
            // console.log(Object.keys(err.response.data.data))
            if(err.response.data.message == "Validation Error."){
                let errs = err.response.data.data;
                let keys = Object.keys(errs)
                console.log(keys);
                let errors = {};
                keys.map((key)=> errors[key] = errs[key][0])
                console.info(errors);
                setError(err.response.data)
                
                setErrorObj(errors)
              }
              else{
                setLangError(err.response.data.data)
              }
        })
    }
    const onError = (errors, e) => console.log(errors, e);

    const handleChange = (e) =>{
        console.log(e);
        let image = {...images};
        image[e.target.name] = e.target.value
        setImages(image);
    }

  return (
    <>
    <Layout>

        { getLoader && 
          <div className="preloader">
              <div className="overlay__inner">
                  <div className="overlay__content"><span className="spin"></span></div>
              </div>
          </div>
        }

        <div className="form_design">
            <h2 style={{color: "black",textDecoration: "underline"}}>Create Album</h2>
            <Form onSubmit={handleSubmit(onSubmit)}>
                {/* <Form.Group>
                    <Form.Label className="form_label">Title</Form.Label>
                    <Form.Control
                        type="text"
                        name="album_id"
                        placeholder="Enter title"
                        // {...register("title")}
                        onChange={(e) => handleChange(e)}

                    />
                    {getErrorObj.album_id? 
                    <span className="text-danger">
                      {getErrorObj.album_id}
                    </span> : null
                    }
                </Form.Group> */}

                <Form.Group>
                    <Form.Label className="form_label">Title</Form.Label>
                    <SelectPicker 
                          name="album_id" 
                          data={getAlbumTitle?.map((item) => ({ label: item.title, value: item.id }))} 
                          style={{ width: "100%" }} 
                          {...register("album_id")} 
                          onSearch={(val)=>getAlbumTitleSearch(val)}
                          onChange={(value) => setImages({...images, "album_id": value})}
                      />
                    {getErrorObj.department_id? 
                    <span className="text-danger">
                      {getErrorObj.department_id}
                    </span> : null
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Image</Form.Label>
                    <Form.Control
                        type="file"
                        multiple 
                        name="image_url"
                        placeholder="Enter Image file"
                        {...register("image_url", 
                        { required: 
                            {
                                value:true,
                                message:"image_url field is required"
                            },
                        })}
                       
                    />
                    {getErrorObj.image_url? 
                    <span className="text-danger">
                      {getErrorObj.image_url}
                    </span> : null
                    }
                    {errors.image_url? 
                        <span className="text-danger">
                        Please select an image
                        </span> : null
                    }
                    {
                        imageFormValues ? <span className="text-danger">
                        {imageFormValues}
                       </span> : null
                    }
                    
                </Form.Group>
                


                <div className="mt-4">
                    <Button className="action__btn" variant="primary" type="submit">
                        Save
                    </Button>
                    <Link to="/image">
                        <Button className="action__btn" variant="info">
                            Back to Home
                        </Button>
                    </Link>
                </div>
            </Form>
        </div>  
    </Layout>
    </>
  )
}

export default ImageCreate