import React ,{useState,useEffect} from 'react'
import './doctorVisit.css';
import { Link , useNavigate} from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Button, Form, Toast } from 'react-bootstrap';
import http from "../../http"
import {lang} from '../enum/enum'
import Layout from '../../components/layouts/layout';
import loginCheck from '../../helpers/loginCheck';
import Cookies from 'universal-cookie';
import { SelectPicker, DateRangePicker,CheckPicker,DatePicker} from 'rsuite';
import {day} from '../enum/enum'
import moment from 'moment/moment';

const DoctorVisitCreate = () => {
    const { register, handleSubmit } = useForm();
    const [data,setData] = useState('');
    const [doctors,setDoctors] = useState('');
    const [doctorsVisit,setDoctorsVisit] = useState('');
    const [val,setval] = useState('');
    const [getSuccess,setSuccess] = useState(false);
    const [getError,setError] = useState('');
    const [getErrorObj,setErrorObj] = useState({})
    const [keys,setKeys] = useState([]);
    const [values,setValues] = useState([]);
    const [holidays,setHolidays] = useState([]);
    const [getDoc,setDoc] = useState([]);
    const [date,setDate] = useState([]);
    const [getFromTime,setFromTime] = useState({});
    const [getToTime,setToTime] = useState({});
    const [from,setFrom] = useState({});
    const [to,setTo] = useState({});
    const [getLangError,setLangError] = useState('');
    const [getLoader,setLoader] = useState(false);
    const cookies = new Cookies();

    const navigate = useNavigate();

    useEffect(()=> {
        if(!loginCheck()){
            console.log("this works")
            navigate("/login");
        }
    });

    // obj to array convert
    let keyarr = []
    let valarr = []
    useEffect(()=> {
        Object.entries(day).forEach(([key,value])=>{
            keyarr.push(key)
            valarr.push(value)
        })

        setKeys(keyarr);
        setValues(valarr);
    },[day]);

    const getSearch = (val) =>{
        if(val.length > 0){
            http.get(`/doctor/search/${val}`, {
                headers:{
                 "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
                }  
            }).then((response)=>{
                setDoc(response.data.data.data);
            })
        }else if(doctorsVisit.doctor_id){
            http.get(`/doctor/get/${doctorsVisit.doctor_id}`, {
                headers:{
                 "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
                }  
            }).then((response)=>{
                setDoc([response.data.data]);
            })
        } else {
            setDoc([]);
        }
    }

    const formateDate = (val) =>{
        let day = holidays.holiday;   
        let tempDateVal0 = new Date(val[0]).toUTCString().toString();
        let tempDateVal1 = new Date(val[1]).toUTCString().toString();
        // console.log(tempDate)
        let fetchDateVal0 = tempDateVal0.substring(5, 7);
        let fetchDateVal1 = tempDateVal1.substring(5, 7);

        let monthConvertVal0 = 
        tempDateVal0.substring(8, 11)=='Jan' ? "01" :
        tempDateVal0.substring(8, 11)=='Feb' ? "02" :
        tempDateVal0.substring(8, 11)=='Mar' ? "03" :
        tempDateVal0.substring(8, 11)=='Apr' ? "04" :
        tempDateVal0.substring(8, 11)=='May' ? "05" :
        tempDateVal0.substring(8, 11)=='Jun' ? "06" :
        tempDateVal0.substring(8, 11)=='Jul' ? "07" :
        tempDateVal0.substring(8, 11)=='Aug' ? "08" :
        tempDateVal0.substring(8, 11)=='Sep' ? "09" :
        tempDateVal0.substring(8, 11)=='Oct' ? "10" :
        tempDateVal0.substring(8, 11)=='Nov' ? "11" : 
        tempDateVal0.substring(8, 11)=='Dec' ? "12" : null
        // let date = fetchDate + tempDate.substring(8, 11) + ", " + tempDate.substring(12, 16) + " at " 
        let dateVal0 = tempDateVal0.substring(12, 16) + "-" + monthConvertVal0 + "-" + fetchDateVal0    
        
        let monthConvertVal1 = 
        tempDateVal1.substring(8, 11)=='Jan' ? "01" :
        tempDateVal1.substring(8, 11)=='Feb' ? "02" :
        tempDateVal1.substring(8, 11)=='Mar' ? "03" :
        tempDateVal1.substring(8, 11)=='Apr' ? "04" :
        tempDateVal1.substring(8, 11)=='May' ? "05" :
        tempDateVal1.substring(8, 11)=='Jun' ? "06" :
        tempDateVal1.substring(8, 11)=='Jul' ? "07" :
        tempDateVal1.substring(8, 11)=='Aug' ? "08" :
        tempDateVal1.substring(8, 11)=='Sep' ? "09" :
        tempDateVal1.substring(8, 11)=='Oct' ? "10" :
        tempDateVal1.substring(8, 11)=='Nov' ? "11" : 
        tempDateVal1.substring(8, 11)=='Dec' ? "12" : null
        // let date = fetchDate + tempDate.substring(8, 11) + ", " + tempDate.substring(12, 16) + " at " 
        let dateVal1 = tempDateVal1.substring(12, 16) + "-" + monthConvertVal1 + "-" + fetchDateVal1 
        return [dateVal0, dateVal1]
    }

    useEffect(()=>{
        finalDate();
    },[
        doctorsVisit.day,
        doctorsVisit.date,
        getToTime,
        getFromTime
    ]);

    const finalDate = () =>{
       
        console.log(doctorsVisit,getToTime,getFromTime)

        if(doctorsVisit.date && doctorsVisit.day){
            // Input dates and days
            let startDate = doctorsVisit.date[0];
            let endDate = doctorsVisit.date[1];
            let specificDaysOfWeek = doctorsVisit.day;

            // Parse input dates
            let startMoment = moment(startDate);
            let endMoment = moment(endDate);

            // Initialize an array to store the specific dates
            let specificDatesArray = [];

            // Calculate the range of dates
            while (startMoment.isSameOrBefore(endMoment)) {
            // Check if the current date is one of the specific days of the week
            let dayOfWeek = startMoment.format('dddd');
            if (specificDaysOfWeek.includes(dayOfWeek)) {
                specificDatesArray.push(startMoment.format('YYYY-MM-DD'));
            }
            
            // Move to the next day
            startMoment.add(1, 'day');
            }

            // Print the array of specific dates
            console.log(specificDatesArray);

            // return 



            // var start = moment(doctorsVisit.date[0]);
            // var end   = moment(doctorsVisit.date[1]);
            // var day  = doctorsVisit.day;
            // console.log(day)
            // var result = [];
            // var current = start.clone();
            // for(let i = 0; i<day.length;i++){
            //     while (current.day(7 + day[i]).isBefore(end)) {
            //     result.push(current.clone());
            //     }
            //     current = start.clone();
            // }
            // let empArr = []
    
            // result.map((m)=>
            //     {
            //         let x = m.format('LLLL')
            //         empArr.push(dateConvert(x))
            //     }
            // )
    
            // console.log(getFromTime?.fromTime)
    
            let from = [];
            specificDatesArray.map((m)=>
                {
                    from.push(m+" "+getFromTime?.fromTime)
                }
            )
    
            let to = [];
            specificDatesArray.map((m)=>
                {
                    to.push(m+" "+getToTime?.toTime)
                }
            )
            setFrom(from)
            setTo(to)
            console.log(from)
            console.log(to)
        }
        return [];
    }
    // console.log(date)
    
    function dateConvert(val){
       
        let tempDate = new Date(val).toUTCString().toString();
        let fetchDate = tempDate.substring(5, 7);

        let monthConvert = 
        tempDate.substring(8, 11)=='Jan' ? "01" :
        tempDate.substring(8, 11)=='Feb' ? "02" :
        tempDate.substring(8, 11)=='Mar' ? "03" :
        tempDate.substring(8, 11)=='Apr' ? "04" :
        tempDate.substring(8, 11)=='May' ? "05" :
        tempDate.substring(8, 11)=='Jun' ? "06" :
        tempDate.substring(8, 11)=='Jul' ? "07" :
        tempDate.substring(8, 11)=='Aug' ? "08" :
        tempDate.substring(8, 11)=='Sep' ? "09" :
        tempDate.substring(8, 11)=='Oct' ? "10" :
        tempDate.substring(8, 11)=='Nov' ? "11" : 
        tempDate.substring(8, 11)=='Dec' ? "12" : null
        let date = tempDate.substring(12, 16) + "-" + monthConvert + "-" + fetchDate    
        // console.log(/date)
        return date;
    }


    function handleKeyPress(e) {
        // console.log(e.code)
        if (['Minus', 'NumpadSubtract'].includes(e.code)) {
            console.log(e.code);
            e.target.value = e.target.value.slice(0, -1)
        }
        else if(/^0/.test(e.target.value)) {
            e.target.value = e.target.value.replace(/^0/, "")
        }
        let intpart = e.target.value.split(".")[0];
        let decimal = e.target.value.split(".")[1].slice(0, 4);
        decimal = decimal.length > 0 ? "."+decimal : "";
        e.target.value = intpart + decimal;
        }
            
    function removeEmpty(obj) {
        return Object.fromEntries(
            Object.entries(obj)
            .filter(([_, v]) => v != null && v != "")
            .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
        );
    }

    const onSubmit = (data, e) => {
        data = {...data, ...doctorsVisit,from,to};
        data.user_id = cookies.get('userAuth').id
        data = removeEmpty(data)
        console.log(data);
        
        setLoader(true)
        http.post('/visit/create',data, {
            headers:{
                "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
                "Content-type": "Application/json"
            }  
        }
        )
        .then((res)=>{
            console.log(res);
            // setSuccess(true);
            localStorage.setItem("success", true);
            // setLoader(false);
            setTimeout(()=>{
                navigate("/doctor-visit");
              },1000)
            })
        .catch((err)=> {
            setLoader(false);
            // if()
            console.log(err)
            // console.log(Object.keys(err.response.data.data))
            if(err.response.data.message == "Validation Error."){
                let errs = err.response.data.data;
                let keys = Object.keys(errs)
                console.log(keys);
                let errors = {};
                keys.map((key)=> errors[key] = errs[key][0])
                console.info(errors);
                setError(err.response.data)
                
                setErrorObj(errors)
              }
              else{
                setLangError(err.response.data.data)
              }
        })
    }
    const onError = (errors, e) => console.log(errors, e);
    const handleChange = (e) =>{
        let doctorVisit = {...doctorsVisit};
        doctorVisit[e.target.name] = e.target.value
        setDoctorsVisit(doctorVisit);
    }

    const formateTime = (val) => {
        // console.log(val);
        let tempDate = new Date(val).toString();
        let fetchDate = tempDate.substring(16, 24);
        // console.log(fetchDate)
        return fetchDate;
    }
    // console.log(getFromTime.fromTime);
    console.log(getLangError);
  return (
    <>
    <Layout>
        { getLoader && 
          <div className="preloader">
              <div className="overlay__inner">
                  <div className="overlay__content"><span className="spin"></span></div>
              </div>
          </div>
        }

        <div className="form_design">
            <h2 style={{color: "black",textDecoration: "underline"}}>Create Doctor Visit</h2>
            <Form onSubmit={handleSubmit(onSubmit)}>

                <Form.Group>
                    <Form.Label className="form_label">Max Visitor</Form.Label>
                    <Form.Control
                        type="number"
                        name="max_visitor"
                        step="1.00"
                        placeholder="Enter max visitor"
                        onKeyUp={(e) => handleKeyPress(e)}
                        // {...register("visit")}
                        onChange={(e) => handleChange(e)}
                    />
                    {getErrorObj.max_visitor? 
                    <span className="text-danger">
                      {getErrorObj.max_visitor}
                    </span> : null
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Doctor</Form.Label>
                      <SelectPicker 
                          name="doctor_id" 
                          data={getDoc?.map((item) => ({ label: item.name, value: item.id }))} 
                          style={{ width: "100%" }} 
                          onSearch={(val)=>getSearch(val)}
                          onChange={(value) => setDoctorsVisit({...doctorsVisit, "doctor_id": value})}
                      />
                    {getErrorObj.doctor_id? 
                    <span className="text-danger">
                      {getErrorObj.doctor_id}
                    </span> : null
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Days</Form.Label>
                    <CheckPicker  name="holiday" 
                        data={keys?.map((item,ele) => ({ label: day[item], value: day[item] }))} 
                        style={{ width: "100%" }} 
                        //   {...register("dr_speciality_id")} 
                        onChange={(value) => setDoctorsVisit({...doctorsVisit, "day": value})} 
                    />

                    {getErrorObj.from && getErrorObj.to? 
                    <span className="text-danger">
                    {getErrorObj.from +" & "+ getErrorObj.to}
                    </span> : null
                    }
                </Form.Group>
                    
                
                <Form.Group>
                    <Form.Label className="form_label">Date Range</Form.Label>
                    <DateRangePicker placeholder="Select Date Range" 
                        style={{ width: "100%" }}
                        onChange={(value) => setDoctorsVisit({...doctorsVisit, "date": formateDate(value)})}
                    />
                    {getErrorObj.from && getErrorObj.to? 
                    <span className="text-danger">
                    {getErrorObj.from +" & "+ getErrorObj.to}
                    </span> : null
                    }
                    {getLangError? 
                        <span className="text-danger">
                        {getLangError}
                        </span> : null
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">From Time</Form.Label>
                    <DatePicker format="hh:mm aa" 
                        showMeridian  
                        style={{ width: "100%" }}
                        onChange={(value) => setFromTime({...holidays, "fromTime": formateTime(value)})}
                    />
                    {getErrorObj.from? 
                        <span className="text-danger">
                        {getErrorObj.from}
                        </span> : null
                    }
                    {getLangError? 
                        <span className="text-danger">
                        {getLangError}
                        </span> : null
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">To Time</Form.Label>
                    <DatePicker format="hh:mm aa" 
                        showMeridian  
                        style={{ width: "100%" }}
                        onChange={(value) => setToTime({...holidays, "toTime": formateTime(value)})}
                    />
                    {getErrorObj.to? 
                    <span className="text-danger">
                    {getErrorObj.to}
                    </span> : null
                    }
                    {getLangError? 
                        <span className="text-danger">
                        {getLangError}
                        </span> : null
                    }
                </Form.Group>


                <div className="mt-4">
                    <Button className="action__btn" variant="primary" type="submit">
                        Save
                    </Button>
                    <Link to="/doctor-visit">
                        <Button className="action__btn" variant="info">
                            Back to Home
                        </Button>
                    </Link>
                </div>
            </Form>
        </div>  
    </Layout>
    </>
  )
}

export default DoctorVisitCreate