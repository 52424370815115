import React ,{useState,useEffect} from 'react'
import { Link, useParams,useNavigate } from 'react-router-dom';
import { useForm,setValue } from "react-hook-form";
import { Button, Form, Toast } from 'react-bootstrap';

import http from "../../http";
import { BsFillCursorFill} from "react-icons/bs";
import {department, lang} from '../enum/enum'
import './treatment.css';
import TextLineLoader from '../../components/Loaders/TextLineLoader';
import loginCheck from '../../helpers/loginCheck';
import Layout from '../../components/layouts/layout';
import Cookies from 'universal-cookie';
import { SelectPicker } from 'rsuite';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FullEditor from 'ckeditor5-build-full'
import uploadPlugin from '../../components/ImageUploader/uploadPlugin'

const TreatmentEdit = () => {
    const {id} = useParams()
    const [treatments, setTreatments] = useState('');
    const {register, handleSubmit } = useForm();
    const [data,setData] = useState('');
    const [getSuccess,setSuccess] = useState(false);
    const [getError,setError] = useState('');
    const [getErrorObj,setErrorObj] = useState({})
    const [departments, setDepartments] = useState([]);
    const [parents, setParents] = useState([]);
    const [getLangError,setLangError] = useState('');
    const [getLoader,setLoader] = useState(false);
    const cookies = new Cookies();
    const image = process.env.REACT_APP_IMAGE_BASE_URL

    const navigate = useNavigate();
    useEffect(()=> {
        if(!loginCheck()){
            console.log("this works")
            navigate("/login");
        }
    });

    useEffect(()=>{
      fetchAllParents();
      // fetchAllDepartments();
    },[]);


    // const fetchAllDepartments = () => {
    //     http.get('/department/admin/all').then(res=>{
    //         setDepartments(res.data.data);
    //     })
    // }

    const fetchAllParents = () => {
        http.get('/treatment/parent', {
          headers:{
           "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
          }  
      }).then(res=>{
            console.log(res.data.data)
            setParents(res.data.data);
        })
    }

    useEffect(()=>{
      if(treatments.department_id)
         getDeptSearch()
    },[treatments]);
  
  
  const getDeptSearch = (val = "") =>{
      let val1 = val.length > 0 ? val : '*';
      if(val.length > 0) {
        http.get(`/department/search/${val}`, {
          headers:{
           "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
          }  
      }).then((response)=>{
          setDepartments(response.data.data);
        })
      }else if(treatments.department_id){
        http.get(`/department/get/${treatments.department_id}`, {
          headers:{
           "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
          }  
      }).then((response)=>{
          setDepartments({'data': [response.data.data]});
        })
      } else {
        http.get(`/department/search/${val1}`, {
          headers:{
           "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
          }  
      }).then((response)=>{
          setDepartments({'data': []});
        })
      }
  }
    
    useEffect(()=>{
        fetchEditTreatments();
    },[]);

    const fetchEditTreatments = () => {
        http.get('treatment/get/'+id, {
          headers:{
           "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
          }  
      }).then(res=>{
            console.log(res.data.data);
            setTreatments(removeEmpty(res.data.data));
        })
    }

    function removeEmpty(obj) {
      return Object.fromEntries(
        Object.entries(obj)
          .filter(([_, v]) => v != null && v != "")
          .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
      );
    }

    const onSubmit = (data, e) => {
        setErrorObj({})
        setLangError('')
        const image_url = data.image_url[0];
        delete treatments.image_url;
        delete treatments.department
        delete treatments.lang_parent
        data = {...data, ...treatments};
        data.user_id = cookies.get('userAuth').id

        // data = removeEmpty(data)
        data.image_url = image_url;
        setLoader(true)
      http.post('/treatment/update/'+id,data,{
        headers:{
            "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
           }  
      })
      .then((res)=>{
          setSuccess(true);
          setTimeout(()=>{
              navigate("/treatment");
          },1500)
      })
      .catch((err)=> {
        setLoader(false);
          // console.log(Object.keys(err.response.data.data))
          if(err.response.data.message == "Validation Error."){
            let errs = err.response.data.data;
            let keys = Object.keys(errs)
            console.log(keys);
            let errors = {};
            keys.map((key)=> errors[key] = errs[key][0])
            console.info(errors);
            setError(err.response.data)
            
            setErrorObj(errors)
          }
          else{
            setLangError(err.response.data.data)
          }
      })
  }
  const onError = (errors, e) => console.log(errors, e);
  const handleChange = (e) =>{
    let treatment = {...treatments};
    treatment[e.target.name] = e.target.value
    setTreatments(treatment);
    }
  return (
    <>
    <Layout>

      { getLoader && 
        <div className="preloader">
            <div className="overlay__inner">
                <div className="overlay__content"><span className="spin"></span></div>
            </div>
        </div>
      }

      {treatments == "" ? 
              <><TextLineLoader/><TextLineLoader/><TextLineLoader/></>:
      <div className="form_design">
            <h2 style={{color: "black",textDecoration: "underline"}}>Edit Treatment</h2>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group>
                  <Form.Label className="form_label">Title</Form.Label>
                  <Form.Control
                      type="text"
                      name="title"
                      defaultValue={treatments.title}
                      placeholder="Enter title"
                    //   {...register("name")}
                    onChange={(e) => handleChange(e)}
                  />
                  {getErrorObj.title? 
                  <span className="text-danger">
                    {getErrorObj.title}
                  </span> : null
                  }
              </Form.Group>


                <Form.Group>
                    <Form.Label className="form_label">Sub Title</Form.Label>
                    <Form.Control
                        type="text"
                        name="sub_title"
                        placeholder="Enter sub_title"
                        defaultValue={treatments.sub_title}
                        // {...register("email")}
                        onChange={(e) => handleChange(e)}
                    />
                    {getErrorObj.email? 
                    <span className="text-danger">
                      {getErrorObj.email}
                    </span> : null
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Department</Form.Label>
                    <SelectPicker 
                          defaultValue={treatments.department_id}
                          name="department_id" 
                          data={departments.data?.map((item) => ({ label: item.title, value: item.id }))} 
                          style={{ width: "100%" }} 
                          onSearch={(val)=>getDeptSearch(val)}
                          onChange={(value) => setTreatments({...treatments, "department_id": value})}
                      />
                    {getErrorObj.department_id? 
                    <span className="text-danger">
                      {getErrorObj.department_id}
                    </span> : null
                    }
                </Form.Group>

                <Form.Group>
                  <Form.Label className="form_label">Image url</Form.Label>
                  <Form.Control
                      type="file"
                      name="image_url"
                      placeholder="Enter Image file"
                      {...register("image_url")}
                  />
                  <p>
                    {
                      (treatments.image_url) ? <a href={image+'/'+treatments.image_url} target="_blank"><BsFillCursorFill/> </a> : ''
                    }
                  </p>
                  {getErrorObj.image_url? 
                  <span className="text-danger">
                    {getErrorObj.image_url}
                  </span> : null
                  }
              </Form.Group>

              <Form.Group>
                    <Form.Label className="form_label">Language Parent(English)</Form.Label>
                    <SelectPicker 
                          defaultValue={treatments.lang_parent_id}
                          data={parents?.map((item) => ({ label: item.title, value: item.id }))} 
                          style={{ width: "100%" }} 
                          onChange={(value) => setTreatments({...treatments, "lang_parent_id": value})}
                      />
                      {getErrorObj.lang_parent_id? 
                    <span className="text-danger">
                      {getErrorObj.lang_parent_id}
                    </span> : null
                    }
                    {getLangError? 
                            <span className="text-danger">
                            {getLangError}
                            </span> : null
                        }
                </Form.Group>


                <Form.Group>
                    <Form.Label className="form_label">Language</Form.Label>
                    <SelectPicker 
                          defaultValue={treatments.lang}
                          data={Object.keys(lang).map((item) => ({ label: item, value: item }))} 
                          style={{ width: "100%" }} 
                          onChange={(value) => setTreatments({...treatments, "lang": value})}
                      />
                    {getErrorObj.lang? 
                    <span className="text-danger">
                      {getErrorObj.lang}
                    </span> : null
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Description</Form.Label>
                    <CKEditor as="textarea" rows={10}
                        config={{
                          extraPlugins: [uploadPlugin]
                        }}
                        data={treatments.description}
                        editor={ FullEditor }
                        type="text"
                        name="description"
                        placeholder="Enter Description"
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            console.log( { event, editor, data } );
                            setTreatments({...treatments, "description": data})
                        } }
                    />
                    {getErrorObj.description? 
                    <span className="text-danger">
                      {getErrorObj.description}
                    </span> : null
                    }
                </Form.Group>


              <div className="mt-4">
                  <Button className="action__btn" variant="primary" type="submit">
                      Update
                  </Button>
                  <Link to="/treatment">
                      <Button className="action__btn" variant="info">
                          Back to Home
                      </Button>
                  </Link>
              </div>
            </Form>
          </div> 
        } 
    </Layout>
    </>
  );
};


export default TreatmentEdit