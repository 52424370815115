import React, { useState,useEffect } from 'react';
import { FaBars,FaExclamationCircle,FaStethoscope,FaHandHoldingMedical,FaSlidersH,FaUserAlt,FaUserTie,FaKey,FaKeybase,FaFile }from "react-icons/fa";
import {MdOutlineDashboardCustomize,MdMedicalServices,MdOutlineHolidayVillage,MdVpnKey,MdOutlineMiscellaneousServices,MdOutlineBedroomParent, MdVideoLibrary} from "react-icons/md";
import {BsFillMenuButtonFill,BsCalendar2Event,BsPencilSquare,BsFillBagFill,BsImageAlt} from "react-icons/bs";
import {GiNewspaper,GiMedicalDrip,GiVideoConference} from "react-icons/gi";
import {FcDepartment} from "react-icons/fc";
import {FiPackage,FiActivity} from "react-icons/fi";
import {TbCalendarTime,TbStethoscope} from "react-icons/tb";
import {ImInfo} from "react-icons/im";
import {RiPagesLine,RiNewspaperFill,RiPagesFill,RiTeamFill,RiGalleryLine,RiDashboardLine, RiPriceTag3Fill} from "react-icons/ri";
import {SiGoogleclassroom} from "react-icons/si";
import {BiPhotoAlbum} from "react-icons/bi";
import { Link, NavLink } from 'react-router-dom';
import avater from '../../images/avater.svg'
import logo from '../../images/Ibrahim-logo.png'
import './sidebar.css'
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import { Button, Modal } from "react-bootstrap";
import ComponentGuard from '../../helpers/ComponentGuard';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUsers } from '../../redux';
import DropdownManu from './DropdownManu';


const Sidebar = ({children}) => {

    const cookies = new Cookies();
    const navigate = useNavigate();
    const {  User, UpdatedUser } = useSelector(state=> state);
    const dispatch = useDispatch()

    // console.table(cookies.get('userAuth')); 
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const removeUser = async()=>
    {
        await cookies.remove('userAuth')
        await cookies.remove('userPermissions')
        await cookies.remove('userRoles')
        navigate("/login")
    }
    const token = cookies.get('userAuth')?.token
    useEffect(()=>{
       
        if(token){
            dispatch(fetchUsers());
        }
    },[token]);

    const[isOpen ,setIsOpen] = useState(true);
    const toggle = () => setIsOpen (!isOpen);
    useEffect(()=>{
        if( window.innerWidth < 764 ){
            setIsOpen(false)
        }
    },[window.innerWidth]);

    const menuItem=[
        {   
            id:0,
            path:"/",
            name:"Dashboard",
            icon:<RiDashboardLine/>,
            permissions: []
        },
        // {
        //     path:"/form-builder",
        //     name:"Form Builder",
        //     icon:<BiPhotoAlbum/>,
        //     permissions: ["image.getAll","image.create","image.update","image.details","image.delete","album.getAll","album.create","album.update","album.delete"]
        // },
        {   
            id: 1,
            path:"/file",
            name:"File System",
            icon:<FaFile/>,
            permissions: ["file-system.getAll","file-system.create","file-system.update","file-system.search","file-system.delete"]
        },
        {   
            id: 19,
            path:"/youtube-link",
            name:"Youtube Link",
            icon:<MdVideoLibrary />,
            permissions: ["youtube-link.getAll","youtube-link.create","youtube-link.update","youtube-link.search","youtube-link.delete"]
        },
        {   
            id: 2,
            path:"/menu",
            name:"Menu",
            icon:<BsFillMenuButtonFill/>,
            permissions: ["menu.getAll","menu.create","menu.update","menu.details","menu.delete"]
        },

        {   
            id: 3,
            path:"/page",
            name:"General Page",
            icon:<RiPagesLine/>,
            permissions: ["page.getAll","page.create","page.update","page.details","page.delete"]
        },

        {   
            id: 4,
            path:"/appointment",
            name:"Appointment Info",
            icon:<BsPencilSquare/>,
            permissions: ["appointment.getAll","appointment.create","appointment.update","appointment.details","appointment.delete"]
        },

        {   
            id: 5,
            path:"/department",
            name:"Department Info",
            icon:<ImInfo/>,
            permissions: ["department.getAll","department.create","department.update","department.details","department.delete"
                            ,"teams.getAll","teams.create","teams.update","teams.details","teams.delete"],
            submenue:[
                {
                    path:"/team",
                    name:"Team List",
                    icon:<RiTeamFill/>,
                    permissions: ["teams.getAll","teams.create","teams.update","teams.details","teams.delete"]
                },
                {
                    path:"/department",
                    name:"Department List",
                    icon:<FcDepartment/>,
                    permissions: ["department.getAll","department.create","department.update","department.details","department.delete"]
                },
                
            ]
        },

        {   
            id: 6,
            path:"/doctor",
            name:"Doctor Info",
            icon:<TbStethoscope/>,
            permissions: ["speciality.getAll","speciality.create","speciality.update","speciality.details","speciality.delete"
                            ,"doctor.getAll","doctor.create","doctor.update","doctor.details","doctor.delete","visit.getAll","visit.create",
                            "visit.update","visit.details","visit.delete"],
            submenue:[
                {
                    path:"/special-doctor",
                    name:"Doctor Speciality",
                    icon:<FaHandHoldingMedical/>,
                    permissions: ["speciality.getAll","speciality.create","speciality.update","speciality.details","speciality.delete"]
                },
                {
                    path:"/doctor",
                    name:"Doctor List",
                    icon:<FaStethoscope/>,
                    permissions: ["doctor.getAll","doctor.create","doctor.update","doctor.details","doctor.delete"]
                },
                {
                    path:"/doctor-visit",
                    name:"Doctor Visit",
                    icon:<TbCalendarTime/>,
                    permissions: ["visit.getAll","visit.create","visit.update","visit.details","visit.delete"]
                },
            ]
        },

        {   
            id: 7,
            path:"/service",
            name:"Service Info",
            icon:<MdOutlineMiscellaneousServices/>,
            permissions: ["service.getAll","service.create","service.update","service.details","service.delete",
                        "package.getAll","package.create","package.update","package.details","package.delete",
                        "treatment.getAll","treatment.create","treatment.update","treatment.details","treatment.delete"],
            submenue:[
                {
                    path:"/service",
                    name:"Service List",
                    icon:<MdMedicalServices/>,
                    permissions: ["service.getAll","service.create","service.update","service.details","service.delete"]
                },
                {
                    path:"/package",
                    name:"Package List",
                    icon:<FiPackage/>,
                    permissions: ["package.getAll","package.create","package.update","package.details","package.delete"]
                },
                {
                    path:"/price",
                    name:"Price List",
                    icon:<RiPriceTag3Fill />,
                    permissions: ["price.getAll","price.create","price.update","price.details","price.delete"]
                },
                {
                    path:"/treatment",
                    name:"Treatment List",
                    icon:<GiMedicalDrip/>,
                    permissions: ["treatment.getAll","treatment.create","treatment.update","treatment.details","treatment.delete"]
                },
            ]
        },

        {   
            id: 8,
            path:"/image",
            name:"Image Gallery",
            icon:<BsImageAlt/>,
            permissions: ["image.getAll","image.create","image.update","image.details","image.delete","album.getAll","album.create","album.update","album.delete"],
            submenue:[
                {
                    path:"/image",
                    name:"Add Image",
                    icon:<BiPhotoAlbum/>,
                    permissions: ["image.getAll","image.create","image.update","image.details","image.delete"]
                },
                {
                    path:"/album",
                    name:"Album List",
                    icon:<RiGalleryLine/>,
                    permissions: ["album.getAll","album.create","album.update","album.delete"]
                },
            ]
        },

        {
            id: 9,
            path:"/slider",
            name:"Image Slider",
            icon:<FaSlidersH/>,
            permissions: ["slider.getAll","slider.create","slider.update","slider.details","slider.delete"]
        },

        {
            id: 10,
            path:"/news",
            name:"News Page",
            icon:<GiNewspaper/>,
            permissions: ["news.getAll","news.create","news.update","news.details","news.delete"]
        },
        
        {   
            id: 11,
            path:"/event",
            name:"Event Page",
            icon:<BsCalendar2Event/>,
            permissions: ["event.getAll","event.create","event.update","event.details","event.delete"]
        },

        {
            id: 12,
            path:"/publication",
            name:"Publication Info",
            icon:<RiNewspaperFill/>,
            permissions: ["publication.getAll","publication.create","publication.update","publication.details","publication.delete"]
        },
        
        {   
            id: 13,
            path:"/holiday",
            name:"Assign Holidays",
            icon:<MdOutlineHolidayVillage/>,
            permissions: ["holiday.getAll","holiday.create","holiday.update","holiday.details","holiday.delete"]
        },
        {   
            id: 14,
            path:"/room",
            name:"Conference Room ",
            icon:<MdOutlineBedroomParent/>,
            permissions: ["room.getAll","room.create","room.update","room.details","room.delete","booking.getAll","booking.create","booking.update","booking.details","booking.delete"],
            submenue:[
                {
                    path:"/room",
                    name:"Room List",
                    icon:<SiGoogleclassroom/>,
                    permissions: ["room.getAll","room.create","room.update","room.details","room.delete"]
                },
                {
                    path:"/booking",
                    name:"Room Booking",
                    icon:<GiVideoConference/>,
                    permissions: ["booking.getAll","booking.create","booking.update","booking.details","booking.delete"]
                },
            ],
        },
        
        {   
            id: 15,
            path:"/job-post",
            name:"Job Post",
            icon:<BsFillBagFill/>,
            permissions: ["job.getAll","job.create","job.update","job.details","job.delete"]
        },
        {   
            id: 16,
            path:"/inner-page",
            name:"Inner Page",
            icon:<RiPagesFill/>,
            permissions: ["resource.getAll","resource.create","resource.update","resource.details","resource.delete"]
        },
        {   
            id: 17,
            path:"/user",
            name:"User Info",
            icon:<FaUserAlt/>,
            permissions: ["auth.getAll","auth.register.admin"],
            submenue:[
                {
                    path:"/user",
                    name:"User List",
                    icon:<FaUserTie/>,
                    permissions: ["auth.getAll","auth.register.admin","activity.getAll"]
                },
                {
                    path:"/activity/log",
                    name:"Activity Log",
                    icon:<FiActivity/>,
                    permissions: ["activity.getAll"]
                },
            ]
        },
        
        {   
            id: 18,
            path:"/role",
            name:"ACL",
            icon:<MdVpnKey/>,
            permissions: ["role.details","role.create","role.givePermissionToRole","role.revokePermissionFromRole","role.assingRoleToUser","role.userRoles","role.removeRoleFromUser","permission.addRouteAsPermission"],
            submenue:[
                {
                    path:"/role",
                    name:"Role List",
                    icon:<FaKey/>,
                    permissions: ["role.details","role.create","role.givePermissionToRole","role.revokePermissionFromRole","role.assingRoleToUser","role.userRoles","role.removeRoleFromUser","permission.addRouteAsPermission"]
                },
                {
                    path:"/assign-user-role-list",
                    name:"User Assign to Role",
                    icon:<FaKeybase/>,
                    permissions: ["role.userRoles","role.removeRoleFromUser","permission.addRouteAsPermission"]
                },
            ],

        },
    ]
    const image = process.env.REACT_APP_IMAGE_BASE_URL

    // console.log(cookies.get('userAuth'))
    return (
        <>
        <div className="container">
            <div style={{minWidth: isOpen ? "300px" : "50px"}} className={`sidebar ${isOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
                <div className="top_section">
                        <img src={logo} className="logo" />
                        <div className="right_text" >
                            Ibrahim Cardiac
                        </div>
                    <div style={{marginLeft: isOpen ? "40px" : "0px", width: isOpen ? "43px" : "20px"}} className="bars">
                        <FaBars onClick={toggle}/>
                    </div>
                </div>
                {
                    menuItem.map((item, index)=>(
                        <ComponentGuard needsPermission={item.permissions}>
                            {/* <NavLink to={item.path} key={index} className="link"  activeclassName="active">
                                <div className="icon" data-toggle="tooltip" data-placement="right" title={item.name}>{item.icon}</div>
                                <div className="link_text">{item.name}</div>
                            </NavLink> */}
                            <DropdownManu item={item} key={index} isOpen={isOpen}/>
                        </ComponentGuard>
                    ))
                }
            </div>
            <div className="menu_wrapper">
                <div className="top_navbar" style={{marginLeft: isOpen ? "300px" : "50px"}}>
                    {cookies.get['userAuth']?.token !== null && 
                    <>
                        {/* <span className="user_name">Hi {cookies.get('userAuth')?.name}! </span>  */}
                        <div className="user_wraper">
                            <span className="user" >
                                <img src={User?.data?.image_url ? image+'/'+User?.data?.image_url: avater}/>
                                <span className='down_arrow'></span>

                            {/* <span className="user" ><img src={cookies.get('userAuth')?.image_url ? image+'/'+cookies.get('userAuth').image_url: avater}/> */}
                                <ul className="user_profile_list">
                                    {/* <li className='up_item'><a href=''>Dashboard</a></li>  */}
                                    <Link to='/user/profile'><li className='up_item'>User Profile</li></Link>
                                    <li className='up_item' type="button" onClick={handleShow} >logout</li>

                                </ul>
                            </span> 
                            
                        </div>
                     </>
                     }
                </div>
                <main className='mt-3' style={{paddingLeft: isOpen ? "300px" : "80px"}}>
                    <div className="ms-4 children_wrapper">
                        {children}
                    </div>
                </main>
            </div>
        </div>

        {/* <Button variant="primary" onClick={handleShow}>
            Launch demo modal
        </Button> */}

      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{fontSize: "50px", margin:"auto"}}><FaExclamationCircle/></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{textAlign:"center",fontSize: "50px"}}>Wanna out</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={removeUser}>
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
    </>
    );
};

export default Sidebar;