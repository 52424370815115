import React,{useState,useEffect} from 'react'
import './slider.css';
import { Link, useParams,useNavigate } from 'react-router-dom';
import { Button,Badge } from 'react-bootstrap';
import http from "../../http"
import TextLineLoader from '../../components/Loaders/TextLineLoader';
import { BsFillCursorFill} from "react-icons/bs";
import loginCheck from '../../helpers/loginCheck';
import Layout from '../../components/layouts/layout';
import {status,order,lang,SliderType} from '../enum/enum'
import ComponentGuard from '../../helpers/ComponentGuard';
import Cookies from 'universal-cookie';

const SliderRead = () => {
    const [sliders, setSliders] = useState('');
    const {id} = useParams()
    const cookies = new  Cookies()
    const navigate = useNavigate();

    useEffect(()=> {
        if(!loginCheck()){
            console.log("this works")
            navigate("/login");
        }
    });

    console.log(sliders)
    useEffect(()=>{
        fetchSingleSliders();
    },[]);

    const fetchSingleSliders = () => {
        http.get('slider/get/'+id, {
            headers:{
             "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
            }  
        }).then(res=>{
            setSliders(res.data.data);
        })
    }

    const image = process.env.REACT_APP_IMAGE_BASE_URL;

  return (
    <>
    <Layout>
    <div className="read">
        <div className="card-body">
            <div className="col-md-12">
            {sliders == "" ? 
            <><TextLineLoader/><TextLineLoader/><TextLineLoader/></>:
                <div className="row">
                    <div className="col-sm-6">
                        <label>Title:</label>
                    </div>
                    <div className="col-sm-6">
                        {(sliders.title)?sliders.title : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Sub Title:</label>
                    </div>
                    <div className="col-sm-6">
                        {(sliders.sub_title)?sliders.sub_title : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Type:</label>
                    </div>
                    <div className="col-sm-6">
                        {(sliders.type)?SliderType[sliders.type] : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Status:</label>
                    </div>
                    <div className="col-sm-6">
                        {(sliders.status)?status[sliders.status] : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Route:</label>
                    </div>
                    <div className="col-sm-6">
                        {(sliders.route)?sliders.route : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Button Name:</label>
                    </div>
                    <div className="col-sm-6">
                        {(sliders.button_one_name)?sliders.button_one_name : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Button URL:</label>
                    </div>
                    <div className="col-sm-6">
                        {(sliders.button_one_path)?sliders.button_one_path : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    

                    <div className="col-sm-6">
                        <label>Image:</label>
                    </div>
                    <div className="col-sm-6">
                        {(sliders.image_url)?
                             <p>
                             {
                               (sliders.image_url) ? <a href={image+'/'+sliders.image_url} target="_blank"><BsFillCursorFill/> </a> : ''
                             }
                           </p>
                            : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>
                   

                    <div className="col-sm-6">
                        <label>Order:</label>
                    </div>
                    <div className="col-sm-6">
                        {(sliders.order)?sliders.order : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>


                    <div className="col-sm-6">
                        <label>Language Parent(English):</label>
                    </div>
                    <div className="col-sm-6">
                        {(sliders.lang_parent)?sliders.lang_parent.title : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Language:</label>
                    </div>
                    <div className="col-sm-6">
                        {(sliders.lang)?sliders.lang : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                </div>
            }
            </div>
        </div>
        <ComponentGuard needsPermission={['slider.update']}>
            <Link to={"/slider/edit/"+sliders.id}>
                <Button className="read_back" variant='info'>Edit</Button>
            </Link>
        </ComponentGuard>
    </div>
    </Layout>
    </>
  )
}

export default SliderRead