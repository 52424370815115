import React ,{useState,useEffect} from 'react'
import { Link, useParams,useNavigate } from 'react-router-dom';
import { useForm,setValue } from "react-hook-form";
import { Button, Form, Toast } from 'react-bootstrap';

import http from "../../http";
import { BsFillCursorFill} from "react-icons/bs";
import {lang} from '../enum/enum'
import './specialDoctor.css';
import TextLineLoader from '../../components/Loaders/TextLineLoader';
import loginCheck from '../../helpers/loginCheck';
import Layout from '../../components/layouts/layout';
import Cookies from 'universal-cookie';
import { SelectPicker } from 'rsuite';

const SpecialDoctorEdit = () => {
    const {id} = useParams()
    const [specialDoctors, setSpecialDoctors] = useState('');
    const {register, handleSubmit } = useForm();
    const [data,setData] = useState('');
    const [getSuccess,setSuccess] = useState(false);
    const [getError,setError] = useState('');
    const [getErrorObj,setErrorObj] = useState({})
    const [parents, setParents] = useState([]);
    const [getLangError,setLangError] = useState('');
    const [getLoader,setLoader] = useState(false);

    const cookies = new Cookies();
    const image = process.env.REACT_APP_IMAGE_BASE_URL

    const navigate = useNavigate();
    useEffect(()=> {
        if(!loginCheck()){
            console.log("this works")
            navigate("/login");
        }
    });

    useEffect(()=>{
      fetchAllParents();
    },[]);

    const fetchAllParents = () => {
        http.get('/doctor/speciality/parent', {
          headers:{
           "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
          }  
      }).then(res=>{
            setParents(res.data.data);
        })
    }

    useEffect(()=>{
      fetchEditSpecialDoctors();
    },[]);

    const fetchEditSpecialDoctors = () => {
        http.get('doctor/speciality/get/'+id, {
          headers:{
           "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
          }  
      }).then(res=>{
            setSpecialDoctors(removeEmpty(res.data.data));
        })
    }

    function removeEmpty(obj) {
      return Object.fromEntries(
        Object.entries(obj)
          .filter(([_, v]) => v != null && v != "")
          .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
      );
    }

    const onSubmit = (data, e) => {
      setErrorObj({})
      setLangError('')
      delete specialDoctors.lang_parent
      data = {...data, ...specialDoctors};
      data.user_id = cookies.get('userAuth').id
      // data = removeEmpty(data)
      console.log(data)
      setLoader(true)
      http.post('/doctor/speciality/update/'+id,data,{
        headers:{
            "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
           }  
      })
      .then((res)=>{
        console.log(res);
        localStorage.setItem("successEdit", true);
        // setLoader(false);
        setTimeout(()=>{
          navigate("/special-doctor");
        },500)
        })
      .catch((err)=> {
        setLoader(false);

        if(err.response.data.message == "Validation Error."){
          let errs = err.response.data.data;
          let keys = Object.keys(errs)
          console.log(keys);
          let errors = {};
          keys.map((key)=> errors[key] = errs[key][0])
          console.info(errors);
          setError(err.response.data)
          
          setErrorObj(errors)
        }
        else{
          setLangError(err.response.data.data)
        }
      })
  }

  const onError = (errors, e) => console.log(errors, e);
  const handleChange = (e) =>{
    let specialDoctor = {...specialDoctors};
    specialDoctor[e.target.name] = e.target.value
    setSpecialDoctors(specialDoctor);
}

console.log(specialDoctors.lang_parent_id);
console.log(parents);
  return (
    <>
    <Layout>

      { getLoader && 
        <div className="preloader">
            <div className="overlay__inner">
                <div className="overlay__content"><span className="spin"></span></div>
            </div>
        </div>
      }

      {specialDoctors == "" ? 
              <><TextLineLoader/><TextLineLoader/><TextLineLoader/></>:
        <div className="form_design">
            <h2 style={{color: "black",textDecoration: "underline"}}>Edit Special Doctor</h2>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group>
                  <Form.Label className="form_label">Title</Form.Label>
                  <Form.Control
                      type="text"
                      name="title"
                      defaultValue={specialDoctors.title}
                      placeholder="Enter title"
                    //   {...register("title")}
                    onChange={(e) => handleChange(e)}
                  />
                  {getErrorObj.title? 
                  <span className="text-danger">
                    {getErrorObj.title}
                  </span> : null
                  }
              </Form.Group>


              <Form.Group>
                    <Form.Label className="form_label">Language Parent(English)</Form.Label>
                    {/* <Form.Select name="lang_parent_id" {...register("lang_parent_id")} aria-label="Default select example">
                        <option value="">Select Parent</option>
                        {
                                parents.data?.map(key => 
                                    (<option value={key.id}>{key.title}</option>)                            
                                )
                        }
                    </Form.Select> */}
                    <SelectPicker 
                          defaultValue={specialDoctors.lang_parent_id}
                          data={parents?.map((item) => ({ label: item.title, value: item.id }))} 
                          style={{ width: "100%" }} 
                          onChange={(value) => setSpecialDoctors({...specialDoctors, "lang_parent_id": value})}
                      />
                      {getErrorObj.lang_parent_id? 
                      <span className="text-danger">
                        {getErrorObj.lang_parent_id}
                      </span> : null
                      }
                      {getLangError? 
                            <span className="text-danger">
                            {getLangError}
                            </span> : null
                        }
                </Form.Group>


                <Form.Group>
                    <Form.Label className="form_label">Language</Form.Label>
                    {/* <Form.Select name="lang" {...register("lang")} aria-label="Default select example">
                        <option value="">Select Language</option>
                        {
                            Object.keys(lang).map(key => 
                                <option value={key}>{lang[key]}</option>
                            )
                        }
                    </Form.Select> */}
                    <SelectPicker 
                          defaultValue={specialDoctors.lang}
                          data={Object.keys(lang).map((item) => ({ label: item, value: item }))} 
                          style={{ width: "100%" }} 
                          onChange={(value) => setSpecialDoctors({...specialDoctors, "lang": value})}
                      />
                    {getErrorObj.lang? 
                    <span className="text-danger">
                      {getErrorObj.lang}
                    </span> : null
                    }
                </Form.Group>


              <div className="mt-4">
                  <Button className="action__btn" variant="primary" type="submit">
                      Update
                  </Button>
                  <Link to="/special-doctor">
                      <Button className="action__btn" variant="info">
                          Back to Home
                      </Button>
                  </Link>
              </div>
            </Form>
          </div> 
        } 
    </Layout>
    </>
  );
};


export default SpecialDoctorEdit;