import React,{useState,useEffect } from 'react';
import './holiday.css';
import { Link,useNavigate } from 'react-router-dom';
import { Calendar, Whisper, Popover, Badge } from 'rsuite';
import loginCheck from '../../helpers/loginCheck';
import Layout from '../../components/layouts/layout';
import http from "../../http";
import { Button, Form, Toast } from 'react-bootstrap';
import Cookies from 'universal-cookie';
const Calender = () => {
  const [dateArr,setDateArr] =useState([])
  const navigate = useNavigate();
  const cookies = new Cookies();
    useEffect(()=> {
        if(!loginCheck()){
            navigate("/login");
        }
    });

    useEffect(()=>{
        fetchAllHolidays();
      },[]);
  
      const fetchAllHolidays = () => {
          let arr =[]
          http.get('holiday/admin/all/calendar', {
            headers:{
             "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
            }  
        }).then(res=>{
              res.data.data.map((d)=>{
                arr.push({
                    "title":d.title,
                    "date":d.date.substring(0,10)
                })
              })
              setDateArr(arr)
            })
        }
        // console.log(dateArr)
        

    function getTodoList(date) {
        const d = new Date(date).toISOString().split('T')[0];
        let todo = []
        // console.log(d)

        dateArr.forEach(data => {
            console.log(typeof data.date, data.date, typeof d, d, data.date === d)
            todo[data.date] = todo[data.date]?.length > 0 ? [...todo[data.date], {"title": data.title}] : [{"title": data.title}]
        })
        // dateArr.forEach(data => todo[new Date(data.date).toUTCString().toString()] = {"title": data.title})
        if(typeof todo[d] === "undefined"){
            return [];
        }
        // console.log(todo)
        //new Date(fromDate).toUTCString().toString()
        return todo[d];
        // return todo[date.getYear()+"-"+date.getMonth()+"-"+date.getDate()];
        // return todo[date];
        // const day = date.getDate();
        // console.log(date,todo)
        // switch (day) {
        //   case 10:
        //     return [
        //       { time: '10:30 am', title: 'Meeting' },
        //       { time: '12:00 pm', title: 'Lunch' }
        //     ];
        //   case 15:
        //     return [
        //       { time: '09:30 pm', title: 'Products Introduction Meeting' },
        //       { time: '12:30 pm', title: 'Client entertaining' },
        //       { time: '02:00 pm', title: 'Product design discussion' },
        //       { time: '05:00 pm', title: 'Product test and acceptance' },
        //       { time: '06:30 pm', title: 'Reporting' },
        //       { time: '10:00 pm', title: 'Going home to walk the dog' }
        //     ];
        //   default:
        //     return [];
        // }
      }

   function renderCell(date) {
    const list = getTodoList(date);
    // console.log(date,list);
    const displayList = list.filter((item, index) => index < 0);

    if (list.length) {
      const moreCount = list.length - displayList.length;
      const moreItem = (
        <li style={{listStyle: "none", textAlign: "start", wordBreak: "break-all"}}>
          <Whisper
            placement="top"
            trigger="click"
            speaker={
              <Popover>
                {list.map((item, index) => (
                  <p key={index}>
                    <Badge /> <b>{item.title}</b> {item.time? " - "+item.time : ""}
                  </p>
                ))}
              </Popover>
            }
          >
            <a><Badge /> {moreCount} Events</a>
          </Whisper>
        </li>
      );

      return (
        <ul className="calendar-todo-list">
          {displayList.map((item, index) => (
            <li key={index} style={{listStyle: "none", textAlign: "start", wordBreak: "break-all"}}>
              <Badge /> <b>{item.title}</b> {item.time? " - "+item.time : ""}
            </li>
          ))}
          {moreCount ? moreItem : null}
        </ul>
      );
    }

    return null;
  }

  return (
  
    <>
        <Layout>
        <Link to="/holiday">
            <Button className="action__btn" variant="info">
                Back
            </Button>
        </Link>
            <Calendar bordered renderCell={renderCell} />
        </Layout>
    </>
    );
};


export default Calender