import React,{useState,useEffect } from 'react';
import './nav.css';
import { Link,useNavigate,useLocation } from 'react-router-dom';
import { Button, Modal,Toast,Badge } from "react-bootstrap";
import http from "../../http"
import {GrView} from "react-icons/gr";
import {AiTwotoneEdit} from "react-icons/ai";
import {MdDelete} from "react-icons/md";
import {type,subType} from '../enum/enum'
import DataTable from 'react-data-table-component';
import Layout from '../../components/layouts/layout';
import loginCheck from '../../helpers/loginCheck';
import Cookies from 'universal-cookie';
import ComponentGuard from '../../helpers/ComponentGuard';

const Menu = () => {

    const [show, setShow] = useState(false);
    const [id, setId] = useState('');
    const [menus, setMenus] = useState([]);
    const [getDeleteMenu, setDeleteMenu] = useState(false);
    const [search, setSearch] = useState("");
    const [filterMenus, setFilterMenus] = useState([]);

    const [loading, setLoading] = useState(false);
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);
	const [pages, setPages] = useState();
    const [getCurrentPage, setCurrentPage] = useState();
    const cookies = new Cookies();
    const location = useLocation();
    
    console.log(location)

    const navigate = useNavigate();
    useEffect(()=> {
        if(!loginCheck()){
            navigate("/login");
        }
    });



    const image = process.env.REACT_APP_IMAGE_BASE_URL

    const handleClose = () => setShow(false);
    const handleShow = (id) => {
        setShow(true);
        setId(id)  
    };

    const deleteMenu = (id) => 
    {
        http.get('/menu/delete/'+id,{
            headers:{
              "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
             } 
          }).then((res)=>{
            setDeleteMenu(true);
            setTimeout(()=>{
                setDeleteMenu(false)}
            ,2000) 
                
        })
        handleClose();
    };

    // //Api
    // useEffect(()=>{
    //     fetchAllMenus();
    // },[]);
    useEffect(()=>{
        fetchAllMenus(getCurrentPage);
    },[getDeleteMenu]);

    useEffect(()=>{
        let query = search.length > 0 ? `search/${search}` : `admin/all`;

        http.get(`/menu/${query}?limit=${perPage}&page=${pages}&per_page=${perPage}&delay=1`,{
            headers:{
              "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
                }
             }).then((response)=>{
            setTotalRows(response.data.data.total);
            setFilterMenus(response.data.data.data);
        })
        
        // })
        // fetchAllMenus2()
    },[search]);

    // +++++++++++++++++++
    const fetchAllMenus = async page => {
        setCurrentPage(page)
		setLoading(true);
        setPages(page)
		await http.get(`/menu/admin/all?limit=${perPage}&page=${pages}&per_page=${perPage}&delay=1`,{
            headers:{
              "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
             } 
          }).then((response)=>{
              console.log(response.data)
              setFilterMenus(response.data.data.data)
              setMenus(response.data.data.data);
              setTotalRows(response.data.data.total);
              setLoading(false);
          });
	};

	const handlePageChange = async (page) => {
        setCurrentPage(page)
        let query = search.length > 0 ? `search/${search}` : `admin/all`;

		setLoading(true);
		await http.get(`/menu/${query}?limit=${perPage}&page=${page}&per_page=${perPage}&delay=1`,{
            headers:{
              "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
             } 
          }).then((response)=>{
            console.log(response.data);
        
            setMenus(response.data.data.data);
            console.log("+++",response.data.data.data);
            setFilterMenus(response.data.data.data)
            setPerPage(perPage);
            setLoading(false);
          })
	};

	useEffect(() => {
		fetchAllMenus(1); // fetch page 1 of users
		
	}, []);

    // +++++++++++++++++++



    const columns =[
        {
            name: '#',
            cell: (row, index) => ((getCurrentPage-1)*perPage)+(index + 1),  //RDT provides index by default
            width: "50px"
        },
        {
            name: "Title",
            selector: row => row.title,
            sortable:true
        },
        {
            name: "Type",
            selector: row => type[row.type],
            sortable:true
        },
        {
            name: "Sub Type",
            selector: row => (row.sub_type)?subType[row.sub_type] : <Badge bg="warning" text="dark">N/A</Badge>,
            sortable:true
        },
        {
            name: "Order",
            selector: row => (row.order)?row.order:<Badge bg="warning" text="dark">N/A</Badge>,
            sortable:true
        },
        {
            name: "Level",
            selector: row => (row.level)?row.level:<Badge bg="warning" text="dark">N/A</Badge>,
            sortable:true
        },
        {
            name: "Language",
            selector: row => row.lang,
            sortable:true
        },
        // {
        //     name: "Parent",
        //     selector: row => row.parent_id,
        //     sortable:true
        // },
        // {
        //     name: "Image",
        //     selector: row => <img width={50} height={50} src={image+'/'+row.image_url} />,
        // },
        {
            name: "Action",
            cell: (row) => 
            (
                <>
                <ComponentGuard needsPermission={['menu.details']}>
                    <Link to={"read/"+row.id}>
                        <Button className="action__btn" variant="info">
                            <GrView/>
                        </Button>
                    </Link>
                </ComponentGuard>

                <ComponentGuard needsPermission={['menu.update']}>
                    <Link to={"edit/"+row.id}>
                        <Button className="action__btn" variant="primary">
                            <AiTwotoneEdit/>
                        </Button>
                    </Link>
                </ComponentGuard>

                <ComponentGuard needsPermission={['menu.delete']}>
                    <Button className="action__btn" onClick={() =>handleShow(row.id)} variant="danger">
                        <MdDelete/>
                    </Button>
                </ComponentGuard>
                </>
            ),            
        }
    ]

    return (
        <>
        <Layout>

            {     
                (getDeleteMenu) ? 
                (        
                        <Toast bg='success' style={{position: 'fixed', top:'30px', right:'0',zIndex:'111111'}}>
                            <Toast.Body >Menu Deleted</Toast.Body>
                        </Toast>
                    )
                    :null
                }
            <ComponentGuard needsPermission={['menu.create']}>
                <Link to="create">
                    <Button className="create_btn btn btn-primary" variant="primary">Add Menu</Button>
                </Link>
            </ComponentGuard>

            <DataTable 
                title="Menu List"
                columns={columns} 
                data={filterMenus} 
                progressPending={loading}
                pagination
                paginationServer
                paginationComponentOptions={{ noRowsPerPage: true }}
                paginationTotalRows={totalRows}
                // onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                fixedHeader
                fixedHeaderScrollHeight="800px"
                highlightOnHover
                subHeader
                subHeaderComponent={
                    <input 
                    type="text" 
                    placeholder="Search Here" 
                    className="search_btn form-control"
                    value={search}
                    onChange={(e)=> setSearch(e.target.value)}
                    />
                }
                subHeaderAlign="right"
            />
            

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>are you sure?</Modal.Title>
                </Modal.Header>
                {/* <Modal.Body>Are You Sure?</Modal.Body> */}
                <Modal.Footer>
                    <Link to="/menu">
                        <Button className="action__btn" variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button className="action__btn" onClick={() => deleteMenu(id)} variant="danger">
                            Delete
                        </Button>
                    </Link>
                </Modal.Footer>
            </Modal>
        </Layout>
        </>
    );
};

export default Menu;