import React,{useState,useEffect} from 'react'
import nav from './nav.css';
import { Link, useParams,useNavigate } from 'react-router-dom';
import { Button,Badge } from 'react-bootstrap';
import http from "../../http"
import TextLineLoader from '../../components/Loaders/TextLineLoader';
import { BsFillCursorFill} from "react-icons/bs";
import loginCheck from '../../helpers/loginCheck';
import Layout from '../../components/layouts/layout';
import ComponentGuard from '../../helpers/ComponentGuard';
import Cookies from 'universal-cookie';

const MenuRead = () => {
    const [menus, setMenus] = useState('');
    const {id} = useParams()
    
    const cookies = new Cookies();
    const navigate = useNavigate();
    useEffect(()=> {
        if(!loginCheck()){
            console.log("this works")
            navigate("/login");
        }
    });

    console.log(menus)
    useEffect(()=>{
        fetchSingleMenus();
    },[]);

    const fetchSingleMenus = () => {
        http.get('menu/get/'+id, {
            headers:{
             "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
            }  
        }).then(res=>{
            setMenus(res.data.data);
        })
    }

    const image = process.env.REACT_APP_IMAGE_BASE_URL;

  return (
    <>
    <Layout>
    <div className="read">
        <div className="card-body">
            <div className="col-md-12">
            {menus == "" ? 
            <><TextLineLoader/><TextLineLoader/><TextLineLoader/></>:
                <div className="row">
                    <div className="col-sm-6">
                        <label>Title:</label>
                    </div>
                    <div className="col-sm-6">
                        {(menus.title)?menus.title : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Sub Title:</label>
                    </div>
                    <div className="col-sm-6">
                        {(menus.sub_title)?menus.sub_title : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Type:</label>
                    </div>
                    <div className="col-sm-6">
                        {(menus.type)?menus.type : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Sub Type:</label>
                    </div>
                    <div className="col-sm-6">
                        {(menus.sub_type)?menus.sub_type : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Route:</label>
                    </div>
                    <div className="col-sm-6">
                        {(menus.route)?menus.route : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Parent:</label>
                    </div>
                    <div className="col-sm-6">
                        {(menus.parent)?menus.parent.title : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Image:</label>
                    </div>
                    <div className="col-sm-6">
                        {(menus.image_url)?
                             <p>
                             {
                               (menus.image_url) ? <a href={image+'/'+menus.image_url} target="_blank"><BsFillCursorFill/> </a> : ''
                             }
                           </p>
                            : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>
                   

                    <div className="col-sm-6">
                        <label>Order:</label>
                    </div>
                    <div className="col-sm-6">
                        {(menus.order)?menus.order : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Level:</label>
                    </div>
                    <div className="col-sm-6">
                        {(menus.level)?menus.level : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Language Parent(English):</label>
                    </div>
                    <div className="col-sm-6">
                        {(menus.lang_parent)?menus.lang_parent.title : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Language:</label>
                    </div>
                    <div className="col-sm-6">
                        {(menus.lang)?menus.lang : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                </div>
            }
            </div>
        </div>
        <ComponentGuard needsPermission={['menu.update']}>
            <Link to={"/menu/edit/"+menus.id}>
                <Button className="read_back" variant='info'>Edit</Button>
            </Link>
        </ComponentGuard>
    </div>
    </Layout>
    </>
  )
}

export default MenuRead