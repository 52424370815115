import React ,{useState,useEffect} from 'react'
import { Link, useParams,useNavigate } from 'react-router-dom';
import { useForm,setValue } from "react-hook-form";
import { Button, Form, Toast } from 'react-bootstrap';

import http from "../../http";
import { BsFillCursorFill} from "react-icons/bs";
import {lang} from '../enum/enum'
import './image.css';
import TextLineLoader from '../../components/Loaders/TextLineLoader';
import loginCheck from '../../helpers/loginCheck';
import Layout from '../../components/layouts/layout';
import Cookies from 'universal-cookie';
import { SelectPicker } from 'rsuite';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FullEditor from 'ckeditor5-build-full'

const ImageEdit = () => {
    const {id} = useParams()
    const [images, setImages] = useState('');
    const {register, handleSubmit } = useForm();
    const [data,setData] = useState('');
    const [getSuccess,setSuccess] = useState(false);
    const [getError,setError] = useState('');
    const [getErrorObj,setErrorObj] = useState({})
    const [parents, setParents] = useState([]);
    const [getAlbumTitle, setAlbumTitle] = useState([]);
    const [getLangError,setLangError] = useState('');
    const [getLoader,setLoader] = useState(false);
    const cookies = new Cookies();
    const image = process.env.REACT_APP_IMAGE_BASE_URL

    const navigate = useNavigate();
    useEffect(()=> {
        if(!loginCheck()){
            console.log("this works")
            navigate("/login");
        }
    });

    // useEffect(()=>{
    //   fetchAllParents();
    // },[]);

    // const fetchAllParents = () => {
    //     http.get('/news/parent').then(res=>{
    //         setParents(res.data.data);
    //     })
    // }

    useEffect(()=>{
      if(images.album_id)
          getAlbumTitleSearch()
    },[images]);
  
  
  const getAlbumTitleSearch = (val = "") =>{
      let val1 = val.length > 0 ? val : '*';
      if(val.length > 0) {
        http.get(`/album/search/${val}`, {
          headers:{
           "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
          }  
      }).then((response)=>{
          setAlbumTitle(response.data.data);
        })
      }else if(images.album_id){
        http.get(`/album/get/${images.album_id}`, {
          headers:{
           "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
          }  
      }).then((response)=>{
          setAlbumTitle({'data': [response.data.data]});
        })
      } else {
        http.get(`/album/search/${val1}`, {
          headers:{
           "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
          }  
      }).then((response)=>{
          setAlbumTitle({'data': []});
        })
      }
  }
    // console.log(parents)

    useEffect(()=>{
      fetchEditAlbums();
    },[]);

    const fetchEditAlbums = () => {
        http.get('album/image/get/'+id, {
          headers:{
           "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
          }  
      }).then(res=>{
            setImages(removeEmpty(res.data.data));
        })
    }

    function removeEmpty(obj) {
      return Object.fromEntries(
        Object.entries(obj)
        .filter(([_, v]) => v != null && v != "")
        .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
      );
    }

    const onSubmit = (data, e) => {
      setErrorObj({})
      setLangError('')
      const image_url = data.image_url[0];
      delete images.image_url;
      // delete images.department
      data = {...data, ...images};
      // data = removeEmpty(data)
      data.image_url = image_url;
    //   data.album_id = 1;
      
      data.user_id = cookies.get('userAuth').id
      console.log(data, image_url);
      setLoader(true)

      http.post('/album/image/update/'+id,data,{
        headers:{
            "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
           }  
      })
      .then((res)=>{
        console.log(res);
        setSuccess(true);
        localStorage.setItem("successEdit", true);
        // setLoader(false);
        setTimeout(()=>{
          navigate("/image");
          },500)
        })
      .catch((err)=> {
        setLoader(false);
          // console.log(Object.keys(err.response.data.data))
          if(err.response.data.message == "Validation Error."){
            let errs = err.response.data.data;
            let keys = Object.keys(errs)
            console.log(keys);
            let errors = {};
            keys.map((key)=> errors[key] = errs[key][0])
            console.info(errors);
            setError(err.response.data)
            
            setErrorObj(errors)
          }
          else{
            setLangError(err.response.data.data)
          }
      })
  }

  const onError = (errors, e) => console.log(errors, e);

  const handleChange = (e) =>{
    console.log(e);
    let image = {...images};
    image[e.target.name] = e.target.value
    setImages(image);
}

  return (
    <>
    <Layout>

      { getLoader && 
        <div className="preloader">
            <div className="overlay__inner">
                <div className="overlay__content"><span className="spin"></span></div>
            </div>
        </div>
      }

      {images == "" ? 
              <><TextLineLoader/><TextLineLoader/><TextLineLoader/></>:
      <div className="form_design">
            <h2 style={{color: "black",textDecoration: "underline"}}>Edit Image</h2>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group>
                    <Form.Label className="form_label">Ttile</Form.Label>
                    <SelectPicker 
                          defaultValue={images.album_id}
                          data={getAlbumTitle.data?.map((item) => ({ label: item.title, value: item.id }))} 
                          style={{ width: "100%" }}
                          onSearch={(val)=>getAlbumTitleSearch(val)}
                          onChange={(value) => setImages({...images, "album_id": value})}
                          />
                    {getErrorObj.album_id? 
                    <span className="text-danger">
                      {getErrorObj.album_id}
                    </span> : null
                    }
                </Form.Group>

              <Form.Group>
                  <Form.Label className="form_label">Image</Form.Label>
                  <Form.Control
                      type="file"
                      name="image_url"
                      placeholder="Enter Image file"
                      {...register("image_url")}
                  />
                  <p>
                    {
                      (images.image_url) ? <a href={image+'/'+images.image_url} target="_blank"><BsFillCursorFill/> </a> : ''
                    }
                  </p>
                  {getErrorObj.image_url? 
                  <span className="text-danger">
                    {getErrorObj.image_url}
                  </span> : null
                  }
              </Form.Group>
              

              <div className="mt-4">
                  <Button className="action__btn" variant="primary" type="submit">
                      Update
                  </Button>
                  <Link to="/image">
                      <Button className="action__btn" variant="info">
                          Back to Home
                      </Button>
                  </Link>
              </div>
            </Form>
          </div> 
        } 
    </Layout>
    </>
  );
};


export default ImageEdit