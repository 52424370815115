import React ,{useState,useEffect} from 'react'
import './booking.css';
import { Link , useNavigate} from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Button, Form, Toast } from 'react-bootstrap';
import http from "../../http"
import {type,subType,level,order,lang,department} from '../enum/enum'
import Layout from '../../components/layouts/layout';
import loginCheck from '../../helpers/loginCheck';
import Cookies from 'universal-cookie';
import { SelectPicker, DatePicker,DateRangePicker} from 'rsuite';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FullEditor from 'ckeditor5-build-full'
import uploadPlugin from '../../components/ImageUploader/uploadPlugin';

const BookingCreate = () => {
    const { register, handleSubmit } = useForm();
    const [data,setData] = useState('');
    const [bookings,setBookings] = useState('');
    const [getSuccess,setSuccess] = useState(false);
    const [getError,setError] = useState('');
    const [getErrorObj,setErrorObj] = useState({})
    const [parents, setParents] = useState([]);
    const [rooms, setrooms] = useState([]);
    const [getLangError,setLangError] = useState('');
    const [getLoader,setLoader] = useState(false);
    const { allowedMaxDays, allowedDays, allowedRange, beforeToday, afterToday, combine } = DateRangePicker;

    const cookies = new Cookies();

    const navigate = useNavigate();

    useEffect(()=> {
        if(!loginCheck()){
            console.log("this works")
            navigate("/login");
        }
    });


    useEffect(()=>{
        fetchAllRoom();
        // fetchAllDepartments();
    },[]);

    const fetchAllRoom = () => {
        http.get('/room/getRoomName', {
            headers:{
             "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
            }  
        }).then(res=>{
           setrooms(res.data.data)
        })
    }

    // const getRoomSearch = (val) =>{
    //     if(val.length > 0){
    //         http.get(`/room/search/${val}`, {
    //             headers:{
    //              "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
    //             }  
    //         }).then((response)=>{
    //             setrooms(response.data.data.data);
    //         })
    //     }else if(bookings.room_id){
    //         http.get(`/room/get/${bookings.room_id}`, {
    //             headers:{
    //              "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
    //             }  
    //         }).then((response)=>{
    //             setrooms([response.data.data]);
    //         })
    //     } else {
    //         setrooms([]);
    //     }
    // }

    function removeEmpty(obj) {
        return Object.fromEntries(
          Object.entries(obj)
            .filter(([_, v]) => v != null && v != "")
            .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
        );
    }

    const onSubmit = (data, e) => {
        setErrorObj({})
        setLangError('')
        const file_url = data.file_url[0];        

        data = {...data, ...bookings};
        data = removeEmpty(data)
        data.user_id = cookies.get('userAuth').id
        data.file_url = file_url;

        setLoader(true)

        http.post('/booking/create',data, {
            headers:{
             "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
            }  
        }
        )
        .then((res)=>{
            console.log(res);
            localStorage.setItem("success", true);
            setTimeout(()=>{
              navigate("/booking");
              },1000)
            })
        .catch((err)=> {
            setLoader(false);
            console.log(err)
            if(err.response.data.message == "Validation Error."){
                let errs = err.response.data.data;
                let keys = Object.keys(errs)
                console.log(keys);
                let errors = {};
                keys.map((key)=> errors[key] = errs[key][0])
                console.info(errors);
                setError(err.response.data)
                
                setErrorObj(errors)
              }
              else{
                setLangError(err?.response?.data?.data)
              }
        })
    }
    const onError = (errors, e) => console.log(errors, e);
    const handleChange = (e) =>{
        console.log(e);
        let booking = {...bookings};
        booking[e.target.name] = e.target.value
        setBookings(booking);
    }

    const frommateDate = (val) =>{
        let tempDate = new Date(val).toUTCString().toString();
        // console.log(tempDate)
        let fetchDate = tempDate.substring(5, 7);

        let monthConvert = 
        tempDate.substring(8, 11)=='Jan' ? "01" :
        tempDate.substring(8, 11)=='Feb' ? "02" :
        tempDate.substring(8, 11)=='Mar' ? "03" :
        tempDate.substring(8, 11)=='Apr' ? "04" :
        tempDate.substring(8, 11)=='May' ? "05" :
        tempDate.substring(8, 11)=='Jun' ? "06" :
        tempDate.substring(8, 11)=='Jul' ? "07" :
        tempDate.substring(8, 11)=='Aug' ? "08" :
        tempDate.substring(8, 11)=='Sep' ? "09" :
        tempDate.substring(8, 11)=='Oct' ? "10" :
        tempDate.substring(8, 11)=='Nov' ? "11" : 
        tempDate.substring(8, 11)=='Dec' ? "12" : null
        // let date = fetchDate + tempDate.substring(8, 11) + ", " + tempDate.substring(12, 16) + " at " 
        let date = tempDate.substring(12, 16) + "-" + monthConvert + "-" + fetchDate    
        // console.log(date)

        let time = new Date(val).toLocaleTimeString('en-US',{
            hour12: false,
          });
    

        var combineDate = date +" "+ time;
        return combineDate;

    }

  return (
    <>
    <Layout>
        {getLoader && 
          <div className="preloader">
              <div className="overlay__inner">
                  <div className="overlay__content"><span className="spin"></span></div>
              </div>
          </div>
        }

        <div className="form_design">
            <h2 style={{color: "black",textDecoration: "underline"}}>Create Conference Room Booking</h2>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group>
                    <Form.Label className="form_label">Title</Form.Label>
                    <Form.Control
                        type="text"
                        name="title"
                        placeholder="Enter title"
                        // {...register("title")}
                        onChange={(e) => handleChange(e)}

                    />
                    {getErrorObj.title? 
                    <span className="text-danger">
                      {getErrorObj.title}
                    </span> : null
                    }
                </Form.Group>
                
                <Form.Group>
                    <Form.Label className="form_label">Room</Form.Label>
                    <SelectPicker 
                          name="room_id" 
                          data={rooms?.map((item) => ({ label: item.name, value: item.id }))} 
                          style={{ width: "100%" }} 
                        //   onSearch={(val)=>getRoomSearch(val)}
                          onChange={(value) => setBookings({...bookings, "room_id": value})}
                          />
                    {getErrorObj.room_id? 
                    <span className="text-danger">
                      The room field is required.
                    </span> : null
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">From Date Time</Form.Label>
                    <DatePicker
                        format="yyyy-MM-dd hh:mm:ss aa"
                        showMeridian
                        showWeekNumbers
                        disabledDate={beforeToday()}
                        style={{ width: "100%" }}
                        onChange={(value) => setBookings({...bookings, "from": frommateDate(value)})}
                        // onChange={(value) => frommateDate(value)}

                    />
                    {getErrorObj.from? 
                    <span className="text-danger">
                      {getErrorObj.from}
                    </span> : null
                    }
                    {getLangError? 
                    <span className="text-danger">
                      Date and Time Already Booked
                    </span> : null
                    }
                </Form.Group>


                <Form.Group>
                    <Form.Label className="form_label">To Date Time</Form.Label>
                    <DatePicker
                        format="yyyy-MM-dd hh:mm:ss aa"
                        showMeridian
                        showWeekNumbers
                        disabledDate={beforeToday()}
                        style={{ width: "100%" }}
                        onChange={(value) => setBookings({...bookings, "to": frommateDate(value)})}

                    />
                    {getErrorObj.to? 
                    <span className="text-danger">
                      {getErrorObj.to}
                    </span> : null
                    }
                    {getLangError? 
                    <span className="text-danger">
                      Date and Time Already Booked
                    </span> : null
                    }
                </Form.Group>


                <Form.Group>
                    <Form.Label className="form_label">Description</Form.Label>
                    <CKEditor as="textarea" rows={10}
                        editor={ FullEditor }
                        config={{
                            extraPlugins: [uploadPlugin]
                        }}
                        type="text"
                        name="description"
                        placeholder="Enter Description"
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            console.log( { event, editor, data } );
                            setBookings({...bookings, "description": data})
                        } }
                    />
                    {getErrorObj.description? 
                    <span className="text-danger">
                      {getErrorObj.description}
                    </span> : null
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Notice</Form.Label>
                    <Form.Control
                        type="file"
                        name="file_url"
                        placeholder="Enter Image file"
                        {...register("file_url")}
                    />
                    {getErrorObj.file_url? 
                    <span className="text-danger">
                      {getErrorObj.file_url}
                    </span> : null
                    }
                </Form.Group>

                <div className="mt-4">
                    <Button className="action__btn" variant="primary" type="submit">
                        Save
                    </Button>
                    <Link to="/booking">
                        <Button className="action__btn" variant="info">
                            Back to Home
                        </Button>
                    </Link>
                </div>
            </Form>
        </div>  
    </Layout>
    </>
  )
}

export default BookingCreate