import React ,{useState,useEffect} from 'react'
import { Link, useParams,useNavigate } from 'react-router-dom';
import { useForm,setValue } from "react-hook-form";
import { Button, Form, Toast } from 'react-bootstrap';
import http from "../../http";
import { BsFillCursorFill} from "react-icons/bs";
import {lang} from '../enum/enum'
import './file.css';
import TextLineLoader from '../../components/Loaders/TextLineLoader';
import loginCheck from '../../helpers/loginCheck';
import Layout from '../../components/layouts/layout';
import Cookies from 'universal-cookie';
import { SelectPicker } from 'rsuite';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FullEditor from 'ckeditor5-build-full'
import uploadPlugin from '../../components/ImageUploader/uploadPlugin'

const FileEdit = () => {
    const {id} = useParams()
    const [files, setFiles] = useState('');
    const {register, handleSubmit } = useForm();
    const [data,setData] = useState('');
    const [getError,setError] = useState('');
    const [getErrorObj,setErrorObj] = useState({})
    const [getLoader,setLoader] = useState(false);
    const cookies = new Cookies();
    const [getLangError,setLangError] = useState('');

    const originalUrl = process.env.REACT_APP_BASE_URL
    let image = originalUrl.replace("/api", "");

    console.log(image)

    const navigate = useNavigate();
    useEffect(()=> {
        if(!loginCheck()){
            console.log("this works")
            navigate("/login");
        }
    });
    
    useEffect(()=>{
        fetchEditFiles();
    },[]);

    const fetchEditFiles = () => {
        http.get('file-system/get/'+id, {
          headers:{
           "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
          }  
      }).then(res=>{
            setFiles(removeEmpty(res.data.data));
        })
    }
    // console.log(getAppointments);
    function removeEmpty(obj) {
      return Object.fromEntries(
        Object.entries(obj)
          .filter(([_, v]) => v != null && v != "")
          .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
      );
    }

    function handleKeyPress(e) {
        // console.log(e.code)
        if (['Minus', 'NumpadSubtract'].includes(e.code)) {
            console.log(e.code);
            e.target.value = e.target.value.slice(0, -1)
        }
        else if(/^0/.test(e.target.value)) {
            e.target.value = e.target.value.replace(/^0/, "")
        }
        let intpart = e.target.value.split(".")[0];
        let decimal = e.target.value.split(".")[1].slice(0, 4);
        decimal = decimal.length > 0 ? "."+decimal : "";
        e.target.value = intpart + decimal;
        console.log(e.target.value)
        }

    const onSubmit = (data, e) => {
        setErrorObj({})
        setLangError('')
        const file_url = data.file_url[0];
        delete files.file_url;
        delete files.file_name;
        delete files.file_type;
        data = {...data, ...files};
        // data = removeEmpty(data)
        data.file_url = file_url;
  
        setLoader(true)
      http.post('/file-system/update/'+id,data,{
        headers:{
            "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
           }  
      })
      .then((res)=>{
        console.log(res);
        localStorage.setItem("successEdit", true);
        // setLoader(false);
        setTimeout(()=>{
          navigate("/file");
          },500)
        })
      .catch((err)=> {
          setLoader(false);
          // console.log(Object.keys(err.response.data.data))
          if(err.response.data.message == "Validation Error."){
            let errs = err.response.data.data;
            let keys = Object.keys(errs)
            console.log(keys);
            let errors = {};
            keys.map((key)=> errors[key] = errs[key][0])
            console.info(errors);
            setError(err.response.data)
            
            setErrorObj(errors)
          }
          else{
            setLangError(err.response.data.data)
          }
      })
  }

  const onError = (errors, e) => console.log(errors, e);
  const handleChange = (e) =>{
    setErrorObj({})
    let file = {...files};
    file[e.target.name] = e.target.value
    setFiles(file);
    }
    // console.log(files);
  return (
    <>
    <Layout>

      { getLoader && 
        <div className="preloader">
            <div className="overlay__inner">
                <div className="overlay__content"><span className="spin"></span></div>
            </div>
        </div>
      }

      {files == "" ? 
              <><TextLineLoader/><TextLineLoader/><TextLineLoader/></>:
      <div className="form_design">
            <h2 style={{color: "black",textDecoration: "underline"}}>Edit File System</h2>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group>
                  <Form.Label className="form_label">Name</Form.Label>
                  <Form.Control
                      type="text"
                      name="name"
                      defaultValue={files.name}
                      placeholder="Enter name"
                    //   {...register("name")}
                    onChange={(e) => handleChange(e)}
                  />
                  {getErrorObj.name? 
                  <span className="text-danger">
                    {getErrorObj.name}
                  </span> : null
                  }
              </Form.Group>

              <Form.Group>
                <Form.Label className="form_label">File url</Form.Label>
                <Form.Control
                    type="file"
                    name="file_url"
                    placeholder="Enter File"
                    {...register("file_url")}
                />
                <p>
                  {
                    (files.file_url) ? <a href={image+files.file_url} target="_blank"><BsFillCursorFill/> </a> : ''
                  }
                </p>
                {getErrorObj.file_url? 
                <span className="text-danger">
                  {getErrorObj.file_url}
                </span> : null
                }
              </Form.Group>

              <div className="mt-4">
                  <Button className="action__btn" variant="primary" type="submit">
                      Update
                  </Button>
                  <Link to="/file">
                      <Button className="action__btn" variant="info">
                          Back to Home
                      </Button>
                  </Link>
              </div>
            </Form>
          </div> 
        } 
    </Layout>
    </>
  );
};


export default FileEdit