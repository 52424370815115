import React ,{useState,useEffect} from 'react'
import './doctor.css';
import { Link , useNavigate} from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Button, Form, Toast } from 'react-bootstrap';
import http from "../../http"
import {lang} from '../enum/enum'
import Layout from '../../components/layouts/layout';
import loginCheck from '../../helpers/loginCheck';
import Cookies from 'universal-cookie';
import {Radio, RadioGroup, SelectPicker} from 'rsuite';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FullEditor from 'ckeditor5-build-full'
import uploadPlugin from '../../components/ImageUploader/uploadPlugin'

const DoctorCreate = () => {
    const { register, handleSubmit } = useForm();
    const [data,setData] = useState('');
    const [doctors,setDoctors] = useState('');
    const [val,setval] = useState('');
    const [getSuccess,setSuccess] = useState(false);
    const [getError,setError] = useState('');
    const [getErrorObj,setErrorObj] = useState({})
    const [specialDoctors, setSpecialDoctors] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [parents, setParents] = useState([]);
    const [getLangError,setLangError] = useState('');
    const [getLoader,setLoader] = useState(false);
    const cookies = new Cookies();

    const navigate = useNavigate();

    useEffect(()=> {
        if(!loginCheck()){
            console.log("this works")
            navigate("/login");
        }
    });

    useEffect(()=>{
        // fetchAllDepartments();
        fetchAllParents();
    },[]);


    // const fetchAllDepartments = () => {
    //     http.get('/department/admin/all').then(res=>{
    //         setDepartments(res.data.data);
    //     })
    // }

    const fetchAllParents = () => {
        http.get('/doctor/parent', {
            headers:{
             "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
            }  
        }).then(res=>{
            setParents(res.data.data);
        })
    }

    const getSearch = (val) =>{
        if(val.length > 0){
            http.get(`/doctor/speciality/search/${val}`, {
                headers:{
                 "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
                }  
            }).then((response)=>{
                setSpecialDoctors(response.data.data.data);
            })
        }else if(doctors.dr_speciality_id){
            http.get(`/doctor/speciality/get/${doctors.dr_speciality_id}`, {
                headers:{
                 "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
                }  
            }).then((response)=>{
                setSpecialDoctors([response.data.data]);
            })
        } else {
            setSpecialDoctors([]);
        }
    }

    const getDeptSearch = (val) =>{
        if(val.length > 0){
            http.get(`/department/search/${val}`, {
                headers:{
                 "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
                }  
            }).then((response)=>{
                setDepartments(response.data.data.data);
            })
        }else if(doctors.department_id){
            http.get(`/department/get/${doctors.department_id}`, {
                headers:{
                 "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
                }  
            }).then((response)=>{
                setDepartments([response.data.data]);
            })
        } else {
            setDepartments([]);
        }
    }

    function handleKeyPress(e) {
        // console.log(e.code)
        if (['Minus', 'NumpadSubtract'].includes(e.code)) {
            console.log(e.code);
            e.target.value = e.target.value.slice(0, -1)
        }
        else if(/^0/.test(e.target.value)) {
            e.target.value = e.target.value.replace(/^0/, "")
        }
        let intpart = e.target.value.split(".")[0];
        let decimal = e.target.value.split(".")[1].slice(0, 4);
        decimal = decimal.length > 0 ? "."+decimal : "";
        e.target.value = intpart + decimal;
        }
            

    function removeEmpty(obj) {
        return Object.fromEntries(
            Object.entries(obj)
            .filter(([_, v]) => v != null && v != "")
            .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
        );
    }

    const onSubmit = (data, e) => {
        setErrorObj({})
        setLangError('')
        const image_url = data.image_url[0];        
        delete doctors.image_url;
        data = {...data, ...doctors};
        // data.user_id = cookies.get('userAuth').id
        data = removeEmpty(data)
        
        data.image_url = image_url;
        data.is_report_upload = data.is_report_upload ? data.is_report_upload : 1;

        setLoader(true)

        http.post('/doctor/create',data, {
            headers:{
             "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
            }  
        }
        )
        .then((res)=>{
            console.log(res);
            // setSuccess(true);
            localStorage.setItem("success", true);
            // setLoader(false);
            setTimeout(()=>{
              navigate("/doctor");
              },1000)
            })
        .catch((err)=> {
            setLoader(false);
            if(err.response.data.message == "Validation Error."){
                let errs = err.response.data.data;
                let keys = Object.keys(errs)
                console.log(keys);
                let errors = {};
                keys.map((key)=> errors[key] = errs[key][0])
                console.info(errors);
                setError(err.response.data)
                
                setErrorObj(errors)
              }
              else{
                setLangError(err.response.data.data)
              }
        })
    }
    const onError = (errors, e) => console.log(errors, e);
    const handleChange = (e) =>{
        let doctor = {...doctors};
        doctor[e.target.name] = e.target.value
        setDoctors(doctor);
    }

    console.log(specialDoctors);
  return (
    <>
    <Layout>
        { getLoader && 
          <div className="preloader">
              <div className="overlay__inner">
                  <div className="overlay__content"><span className="spin"></span></div>
              </div>
          </div>
        }

        <div className="form_design">
            <h2 style={{color: "black",textDecoration: "underline"}}>Create Doctor</h2>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group>
                    <Form.Label className="form_label">Name</Form.Label>
                    <Form.Control
                        type="text"
                        name="name"
                        placeholder="Enter name"
                        // {...register("name")}
                        onChange={(e) => handleChange(e)}
                    />
                    {getErrorObj.name? 
                    <span className="text-danger">
                      {getErrorObj.name}
                    </span> : null
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Minute Per Patient</Form.Label>
                    <Form.Control
                        type="number"
                        name="minute_per_patient"
                        step="1.00"
                        placeholder="Enter minute per patient"
                        onKeyUp={(e) => handleKeyPress(e)}
                        // {...register("minute_per_patient")}
                        onChange={(e) => handleChange(e)}
                        onWheel={ event => event.currentTarget.blur() }
                    />
                    {getErrorObj.minute_per_patient? 
                    <span className="text-danger">
                      {getErrorObj.minute_per_patient}
                    </span> : null
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Education</Form.Label>
                    <Form.Control
                        type="text"
                        name="education"
                        placeholder="Enter Education"
                        // {...register("name")}
                        onChange={(e) => handleChange(e)}
                    />
                    {getErrorObj.education? 
                    <span className="text-danger">
                      {getErrorObj.education}
                    </span> : null
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Designation</Form.Label>
                    <Form.Control
                        type="text"
                        name="designation"
                        placeholder="Enter Designation"
                        // {...register("name")}
                        onChange={(e) => handleChange(e)}
                    />
                    {getErrorObj.designation? 
                    <span className="text-danger">
                      {getErrorObj.designation}
                    </span> : null
                    }
                </Form.Group>


                <Form.Group>
                    <Form.Label className="form_label">Experience</Form.Label>
                    <Form.Control
                        as={"textarea"}
                        rows={5}
                        type="text"
                        name="experience"
                        placeholder="Enter Experience"
                        // {...register("name")}
                        onChange={(e) => handleChange(e)}
                    />
                    {getErrorObj.experience? 
                    <span className="text-danger">
                      {getErrorObj.experience}
                    </span> : null
                    }
                </Form.Group>


                <Form.Group>
                    <Form.Label className="form_label">Speciality</Form.Label>
                      <SelectPicker 
                          name="dr_speciality_id" 
                          data={specialDoctors?.map((item) => ({ label: item.title, value: item.id }))} 
                          style={{ width: "100%" }} 
                          onSearch={(val)=>getSearch(val)}
                          onChange={(value) => setDoctors({...doctors, "dr_speciality_id": value})}
                      />
                    {getErrorObj.dr_speciality_id? 
                    <span className="text-danger">
                      {getErrorObj.dr_speciality_id}
                    </span> : null
                    }
                </Form.Group>




                <Form.Group>
                    <Form.Label className="form_label">Email</Form.Label>
                    <Form.Control
                        type="email"
                        name="email"
                        placeholder="Enter email"
                        // {...register("email")}
                        onChange={(e) => handleChange(e)}
                    />
                    {getErrorObj.email? 
                    <span className="text-danger">
                      {getErrorObj.email}
                    </span> : null
                    }
                </Form.Group>
            
                <Form.Group>
                    <Form.Label className="form_label">Image url</Form.Label>
                    <Form.Control
                        type="file"
                        name="image_url"
                        placeholder="Enter Image file"
                        {...register("image_url")}
                    />
                    {getErrorObj.image_url? 
                    <span className="text-danger">
                      {getErrorObj.image_url}
                    </span> : null
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Department</Form.Label>
                    <SelectPicker 
                          name="department_id" 
                          data={departments?.map((item) => ({ label: item.title, value: item.id }))} 
                          style={{ width: "100%" }} 
                          onSearch={(val)=>getDeptSearch(val)}
                          onChange={(value) => setDoctors({...doctors, "department_id": value})}
                      />
                    {getErrorObj.department_id? 
                    <span className="text-danger">
                      {getErrorObj.department_id}
                    </span> : null
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Patient Report Upload</Form.Label>
                    <RadioGroup
                        name="radioList"
                        inline
                        defaultValue="1"
                        onChange={(value) =>
                            setDoctors({ ...doctors, "is_report_upload": value })
                        }
                    >
                        <Radio value="1">Yes</Radio>
                        <Radio value="0">No</Radio>
                    </RadioGroup>
                    {getErrorObj.is_report_upload ? (
                        <span className="text-danger">{getErrorObj.is_report_upload}</span>
                    ) : null}
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Visit</Form.Label>
                    <Form.Control
                        type="number"
                        name="visit"
                        step="0.01"
                        placeholder="Enter visit"
                        onKeyUp={(e) => handleKeyPress(e)}
                        // {...register("visit")}
                        onChange={(e) => handleChange(e)}
                        onWheel={ event => event.currentTarget.blur() }
                    />
                    {getErrorObj.visit? 
                    <span className="text-danger">
                      {getErrorObj.visit}
                    </span> : null
                    }
                </Form.Group>


                <Form.Group>
                    <Form.Label className="form_label">Language Parent(English)</Form.Label>
                    <SelectPicker 
                          name="lang_parent_id" 
                          data={parents?.map((item) => ({ label: item.name, value: item.id }))} 
                          style={{ width: "100%" }} 
                          onChange={(value) => setDoctors({...doctors, "lang_parent_id": value})}
                      />
                       {getErrorObj.lang_parent_id? 
                    <span className="text-danger">
                      {getErrorObj.lang_parent_id}
                    </span> : null
                    }
                    {getLangError? 
                        <span className="text-danger">
                          {getLangError}
                        </span> : null
                        }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Language</Form.Label>
                    <SelectPicker 
                          name="lang" 
                          data={Object.keys(lang).map((item) => ({ label: item, value: item }))} 
                          style={{ width: "100%" }} 
                          {...register("lang")} 
                          onChange={(value) => setDoctors({...doctors, "lang": value})}
                      />
                    {getErrorObj.lang? 
                    <span className="text-danger">
                      {getErrorObj.lang}
                    </span> : null
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Phone</Form.Label>
                    <Form.Control
                        type="text"
                        name="phone"
                        placeholder="Enter phone"
                        // {...register("phone")}
                        onChange={(e) => handleChange(e)}
                    />
                    {getErrorObj.phone? 
                    <span className="text-danger">
                      {getErrorObj.phone}
                    </span> : null
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Description</Form.Label>
                    <CKEditor as="textarea" rows={10}
                        config={{
                            extraPlugins: [uploadPlugin]
                        }}
                        editor={ FullEditor }
                        type="text"
                        name="description"
                        placeholder="Enter Description"
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            console.log( { event, editor, data } );
                            setDoctors({...doctors, "description": data})
                        } }
                    />
                    {getErrorObj.description? 
                    <span className="text-danger">
                      {getErrorObj.description}
                    </span> : null
                    }
                </Form.Group>

                <div className="mt-4">
                    <Button className="action__btn" variant="primary" type="submit">
                        Save
                    </Button>
                    <Link to="/doctor">
                        <Button className="action__btn" variant="info">
                            Back to Home
                        </Button>
                    </Link>
                </div>
            </Form>
        </div>  
    </Layout>
    </>
  )
}

export default DoctorCreate