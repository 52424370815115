import React, { useEffect } from 'react';
// import Layout from '../components/layouts/layout';
import loginCheck from '../helpers/loginCheck';
import { Link,useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
const Forbidden = () => {
    const navigate = useNavigate();

    useEffect(()=> {
        if(!loginCheck()){
            console.log("this works")
            navigate("/login");
        }
    });

    return (
        // <Layout>
        <>
            <div style={{ width: "350px",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
                }}>
               <p style={{fontSize: "17px",fontWeight:"bold"}}>Accress Denied, You are not Authorized</p>
               <Link to="/">
                    <Button className="action__btn mt-3" variant="info">
                        Back
                    </Button>
               </Link>
            </div>
        </>
        // </Layout>
    );
};

export default Forbidden;