import React,{useState,useEffect} from 'react'
import './booking.css';
import { Link, useParams,useNavigate } from 'react-router-dom';
import { Button,Badge } from 'react-bootstrap';
import http from "../../http"
import TextLineLoader from '../../components/Loaders/TextLineLoader';
import { BsFillCursorFill} from "react-icons/bs";
import loginCheck from '../../helpers/loginCheck';
import Layout from '../../components/layouts/layout';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FullEditor from 'ckeditor5-build-full'
import { SelectPicker, DatePicker} from 'rsuite';
import ComponentGuard from '../../helpers/ComponentGuard';
import Cookies from 'universal-cookie';
const BookingRead = () => {
    const [bookings, setBookings] = useState('');
    const {id} = useParams()
    const image = process.env.REACT_APP_IMAGE_BASE_URL

    const navigate = useNavigate();
    const cookies = new Cookies()
    useEffect(()=> {
        if(!loginCheck()){
            console.log("this works")
            navigate("/login");
        }
    });

    console.log(bookings)
    useEffect(()=>{
        fetchSingleBookings();
    },[]);

    const fetchSingleBookings = () => {
        http.get('booking/get/'+id, {
            headers:{
             "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
            }  
        }).then(res=>{
            setBookings(res.data.data);
        })
    }


    const formateDate = (value) =>{
        let tempDate = new Date(value).toUTCString().toString();
        let date =  tempDate.substring(0, 16)
        var hours = tempDate.substring(17, 19);
        var minutes = tempDate.substring(20, 22);
        // console.log(hours,minutes)
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        // console.log(strTime);
        return date+" "+strTime
    }

  return (
    <>
    <Layout>
    <div className="read">
        <div className="card-body">
            <div className="col-md-12">
            {bookings == "" ? 
            <><TextLineLoader/><TextLineLoader/><TextLineLoader/></>:
                <div className="row">
                    <div className="col-sm-6">
                        <label>Title:</label>
                    </div>
                    <div className="col-sm-6">
                        {(bookings.title)?bookings.title : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>Department:</label>
                    </div>
                    <div className="col-sm-6">
                        {(bookings.room_id)?bookings.room.name : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>From Date:</label>
                    </div>
                    <div className="col-sm-6">
                    {(bookings.from)? 
                            formateDate(bookings.from)
                             : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>

                    <div className="col-sm-6">
                        <label>To Date:</label>
                    </div>
                    <div className="col-sm-6">
                    {(bookings.to)? 
                           formateDate(bookings.to)
                             : 
                            <Badge bg="warning" text="dark">N/A</Badge>
                        }
                    </div>


                    <div className="col-sm-6">
                        <label>Description:</label>
                    </div>
                     {bookings.description?
                     
                        <div className="viewEditor">
                            <CKEditor as="textarea" rows={10} 
                                data={bookings.description}
                                editor={ FullEditor }
                                disabled = {true}
                            />
                        </div>
                        : 
                        <div className="col-sm-6">
                            <Badge bg="warning" text="dark">N/A</Badge>
                        </div>
                        }

                </div>
            }
            </div>
        </div>
        <ComponentGuard needsPermission={['booking.update']}>
            <Link to={"/booking/edit/"+bookings.id}>
                <Button className="read_back" variant='info'>Edit</Button>
            </Link>
        </ComponentGuard>
    </div>
    </Layout>
    </>
  )
}

export default BookingRead