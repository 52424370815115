import React ,{useState,useEffect} from 'react'
import { Link, useParams,useNavigate } from 'react-router-dom';
import { useForm, set } from "react-hook-form";
import { Button, Form, Toast } from 'react-bootstrap';
import http from "../../http";
import { BsFillCursorFill} from "react-icons/bs";
import {type,subType,level,order,lang} from '../enum/enum'
import './nav.css';
import TextLineLoader from '../../components/Loaders/TextLineLoader';
import loginCheck from '../../helpers/loginCheck';
import Layout from '../../components/layouts/layout';
import Cookies from 'universal-cookie';
import { SelectPicker } from 'rsuite';

const MenuEdit = () => {
    const {id} = useParams()
    const [menus, setMenus] = useState('');
    const {register, handleSubmit } = useForm();
    const [data,setData] = useState('');
    const [getError,setError] = useState('');
    const [getErrorObj,setErrorObj] = useState({})
    const [parents, setParents] = useState([]);
    const [getLangError,setLangError] = useState('');
    const [getLoader,setLoader] = useState(false);

    const cookies = new Cookies();
    
    const navigate = useNavigate();
    useEffect(()=> {
        if(!loginCheck()){
            console.log("this works")
            navigate("/login");
        }
    });

    useEffect(()=>{
      fetchAllParents();
    },[]);

    const fetchAllParents = () => {
        http.get('/menu/parent', {
          headers:{
           "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
          }  
      }).then(res=>{
            setParents(res.data.data);
        })
    }


    useEffect(()=>{
      fetchEditMenus();
    },[]);

    const fetchEditMenus = () => {
        http.get('menu/get/'+id, {
          headers:{
           "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
          }  
      }).then(res=>{
            setMenus(removeEmpty(res.data.data));
        })
    }

    function removeEmpty(obj) {
      return Object.fromEntries(
        Object.entries(obj)
          .filter(([_, v]) => v != null && v != "")
          .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
      );
    }

    const onSubmit = (data, e) => {
      setErrorObj({})
      setLangError('')
      const image_url = data.image_url[0];
      delete menus.image_url;
      delete menus.lang_parent;
      delete menus.parent;
      data = {...data, ...menus};
      data.user_id = cookies.get('userAuth').id

      // data = removeEmpty(data)
      data.image_url = image_url;

      setLoader(true)
      http.post('/menu/update/'+id,data, {
        headers:{
         "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
        }  
      }
      )
      .then((res)=>{
        console.log(res);
        localStorage.setItem("successEdit", true);
        // setLoader(false);
        setTimeout(()=>{
          navigate("/menu");
          },500)
        })
      .catch((err)=> {
          setLoader(false);
          // console.log(Object.keys(err.response.data.data))
          if(err.response.data.message == "Validation Error."){
            let errs = err.response.data.data;
            let keys = Object.keys(errs)
            console.log(keys);
            let errors = {};
            keys.map((key)=> errors[key] = errs[key][0])
            console.info(errors);
            setError(err.response.data)
            
            setErrorObj(errors)
          }
          else{
            setLangError(err.response.data.data)
          }
      })
  }

  const onError = (errors, e) => console.log(errors, e);
  
  const image = process.env.REACT_APP_IMAGE_BASE_URL;

  const handleChange = (e) =>{
    console.log(e);
    let menu = {...menus};
    menu[e.target.name] = e.target.value
    setMenus(menu);
  }

  return (
    <>
    <Layout>

      { getLoader && 
        <div className="preloader">
            <div className="overlay__inner">
                <div className="overlay__content"><span className="spin"></span></div>
            </div>
        </div>
      }
      {menus == "" ? 
              <><TextLineLoader/><TextLineLoader/><TextLineLoader/></>:
      <div className="form_design">
            <h2 style={{color: "black",textDecoration: "underline"}}>Edit Menu</h2>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group>
                  <Form.Label className="form_label">Title</Form.Label>
                  <Form.Control
                      type="text"
                      name="title"
                      defaultValue={menus.title}
                      placeholder="Enter title"
                      onChange={(e) => handleChange(e)}
                      // {...register("title")}
                  />
                  {getErrorObj.title? 
                  <span className="text-danger">
                    {getErrorObj.title}
                  </span> : null
                  }
              </Form.Group>

              <Form.Group>
              <Form.Label className="form_label">Short Description</Form.Label>
                  <Form.Control as="textarea" rows={5}
                      type="text"
                      name="sub_title"
                      defaultValue={menus.sub_title}
                      placeholder="Enter Short Description"
                      onChange={(e) => handleChange(e)}
                  />
                  {getErrorObj.sub_title? 
                  <span className="text-danger">
                    {getErrorObj.sub_title}
                  </span> : null
                  }
              </Form.Group>

              <Form.Group>
                  <Form.Label className="form_label">Type</Form.Label>
                  <SelectPicker 
                          name="type"
                          defaultValue={menus.type}
                          data={Object.keys(type).map((item) => ({label: type[item], value: item }))}                           
                          style={{ width: "100%" }} 
                          onChange={(value) => setMenus({...menus, "type": value})}
                  />
                  {getErrorObj.type? 
                  <span className="text-danger">
                    {getErrorObj.type}
                  </span> : null
                  }
              </Form.Group>

              <Form.Group>
                  <Form.Label className="form_label">Sub Type</Form.Label>
                  <SelectPicker 
                          name="sub_type"
                          defaultValue={menus.sub_type}
                          data={Object.keys(subType).map((item) => ({label: subType[item], value: item }))}                           
                          style={{ width: "100%" }} 
                          onChange={(value) => setMenus({...menus, "sub_type": value})}
                  />
                  {getErrorObj.sub_type? 
                  <span className="text-danger">
                    {getErrorObj.sub_type}
                  </span> : null
                  }
              </Form.Group>

          
              <Form.Group>
                  <Form.Label className="form_label">Route</Form.Label>
                  <Form.Control
                      type="text"
                      name="route"
                      defaultValue={menus.route}
                      placeholder="Enter Route"
                      // {...register("route")}
                      onChange={(e) => handleChange(e)}
                  />
                  {getErrorObj.route? 
                  <span className="text-danger">
                    {getErrorObj.route}
                  </span> : null
                  }
              </Form.Group>


              <Form.Group>
                  <Form.Label className="form_label">Image</Form.Label>
                  <Form.Control
                      type="file"
                      name="image_url"
                      placeholder="Enter Image file"
                      {...register("image_url")}
                  />
                  <p>
                    {
                      (menus.image_url) ? <a href={image+'/'+menus.image_url} target="_blank"><BsFillCursorFill/> </a> : ''
                    }
                  </p>
                  {getErrorObj.image_url? 
                  <span className="text-danger">
                    {getErrorObj.image_url}
                  </span> : null
                  }
              </Form.Group>

              <Form.Group>
                  <Form.Label className="form_label">Level</Form.Label>
                  <SelectPicker 
                          name="level"
                          defaultValue={menus.level?.toString()}
                          data={Object.keys(level).map((item) => ({label: item, value: item }))}                           
                          style={{ width: "100%" }} 
                          onChange={(value) => setMenus({...menus, "level": value})}
                      />
                  {getErrorObj.level? 
                  <span className="text-danger">
                    {getErrorObj.level}
                  </span> : null
                  }
              </Form.Group>

              <Form.Group>
                  <Form.Label className="form_label">Order</Form.Label>
                   <SelectPicker 
                          name="order"
                          defaultValue={menus.order?.toString()}
                          data={Object.keys(order).map((item) => ({label: item, value: item }))}                           
                          style={{ width: "100%" }} 
                          onChange={(value) => setMenus({...menus, "order": value})}
                      />
                  {getErrorObj.order? 
                  <span className="text-danger">
                    {getErrorObj.order}
                  </span> : null
                  }
              </Form.Group>

              <Form.Group>
                  <Form.Label className="form_label">Parent</Form.Label>
                  <SelectPicker 
                          defaultValue={menus.parent_id}
                          data={parents?.map((item) => ({ label: item.title, value: item.id }))} 
                          style={{ width: "100%" }} 
                          onChange={(value) => setMenus({...menus, "parent_id": value})}
                      />
                      {getErrorObj.parent_id? 
                      <span className="text-danger">
                        {getErrorObj.parent_id}
                      </span> : null
                      }
              </Form.Group>

              <Form.Group>
                  <Form.Label className="form_label">Language Parent(English)</Form.Label>
                  <SelectPicker 
                          defaultValue={menus.lang_parent_id}
                          data={parents?.map((item) => ({ label: item.title, value: item.id }))} 
                          style={{ width: "100%" }} 
                          onChange={(value) => setMenus({...menus, "lang_parent_id": value})}

                      />
                  {getErrorObj.lang_parent_id? 
                  <span className="text-danger">
                    {getErrorObj.lang_parent_id}
                  </span> : null
                  }
                  {getLangError? 
                    <span className="text-danger">
                      {getLangError}
                    </span> : null
                  }
              </Form.Group>


              <Form.Group>
                  <Form.Label className="form_label">Language</Form.Label>
                  <SelectPicker 
                          defaultValue={menus.lang}
                          data={Object.keys(lang).map((item) => ({ label: item, value: item }))} 
                          style={{ width: "100%" }} 
                          onChange={(value) => setMenus({...menus, "lang": value})}
                      />
                  {getErrorObj.lang? 
                  <span className="text-danger">
                    {getErrorObj.lang}
                  </span> : null
                  }
              </Form.Group>

              <div className="mt-4">
                  <Button className="action__btn" variant="primary" type="submit">
                      Update
                  </Button>
                  <Link to="/menu">
                      <Button className="action__btn" variant="info">
                          Back to Home
                      </Button>
                  </Link>
              </div>
            </Form>
          </div> 
        } 
    </Layout>
    </>
  );
};


export default MenuEdit