
import React from 'react'
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const API_URl = process.env.REACT_APP_BASE_URL
const UPLOAD_ENDPOINT = "album/image/upload";

function uploadAdapter(loader) {
    return {
        upload: () => {
            return new Promise((resolve, reject) => {
                const body = new FormData();
                loader.file.then((file) => {
                    body.append("uploadImg", file);
                    fetch(`${API_URl}/${UPLOAD_ENDPOINT}`, {
                        headers:{
                            "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
                        },
                        method: "post",
                        body: body
                    })
                        .then((res => res.json()))
                        .then((res) => {
                            console.log(res)
                            resolve({ default: "data:image/png;base64,"+res.data })
                        })
                        .catch((err) => {
                            reject(err);
                        })
                })
            })
        }
    }
}

export default function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return uploadAdapter(loader);
    }
}


