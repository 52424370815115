import  { Link,useNavigate } from 'react-router-dom'
import Layout from '../../components/layouts/layout';
import Cookies from 'universal-cookie';

import { useSelector, useDispatch } from 'react-redux';
import { fetchUsers, fetchUpdatedUsers} from '../../redux';
import React ,{ useEffect,useState } from 'react';
import loginCheck from '../../helpers/loginCheck';

import NotFoundLogin from '../../pages/NotFound';
// import Loader from '../../components/loader';
import avater from '../../images/avater.svg';
import './profile.scss'

export default function MakeAppoinment() {

    const navigate = useNavigate();
    useEffect(()=> {
        if(!loginCheck()){
            navigate("/login");
        }
    }); 
    const image = process.env.REACT_APP_IMAGE_BASE_URL

  // get all input data 
  const [getUser, SetUser] = useState('');
  const [getActive, setActive] = useState(false);
  const [selectedImage, setSelectedImage] = useState(avater);
  const [getUserData, SetUserData] = useState([]);

  const optionList =[
    {
    label: 'Male',
    value:'MALE'},
    {
    label: 'Female',
    value:'FEMALE'}]

  // get user id from rought
//   const router = useRouter()
//   const doctor_id =  router.query.doctorAppId;
//   console.log(doctor_id)

  // dispatch function from redux
  const dispatch = useDispatch()

    // get all redux state data
    const {  User, UpdatedUser } = useSelector(state=> state);

//   console.log( "User", User)
//   console.log( "getUserData", getUserData)
//   console.log( "UpdatedUser", UpdatedUser)
//   console.log( "UserPay", UserPay);
//   console.log( "UserPayment", UserPayment);
//   console.log( getUser.name);


    const cookies = new Cookies();


    // useEffect(()=> {
    //     if(!loginCheck()){
    //         console.log("this works")
    //         // cookies.remove('userAuth')

    //         router.push("/login");
    //     }
    // });
    // setTimeout(()=>{
        
    // },1000)
    function removeEmpty(obj) {
        return Object.fromEntries(
          Object.entries(obj)
  
          .filter(([_, v]) => v != null && v != "")
  
          .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
  
        );
  
      }
    
    const editableData =()=>{
        SetUserData({
            address: User.data.address || null?User.data.address:'',
            birth_date: User.data.birth_date || null?new Date(User.data.created_at).toISOString().substring(0, 10):'',
            city: User.data.city || null ?User.data.city:'',
            email: User.data.email || null?User.data.email:'',
            gender: User.data.gender || null?User.data.gender:'',
            // image_url:User.data.image_url?User.data.image_url:'',
            name: User.data.name || null?User.data.name:'',
            nationality: User.data.nationality || null?User.data.nationality:'',
            phone: User.data.phone || null?User.data.phone:'',
            post_code: User.data.post_code || null?User.data.post_code:'',
        })
        console.log('first')
    }
    const handleEdit = ()=>{
        setActive(true)
    }
    const handleBack = ()=>{
        editableData()
        setActive(false);
    }
    
   
    
    function handleChange(evt) {
        const value = evt.target.value;

        SetUserData({
          ...getUserData,
          [evt.target.name]: value,
        });
        if(evt.target.files){
            SetUserData({
                ...getUserData,
                [evt.target.name]: value,
              //   image_url: reader
                image_url:  evt.target.files && evt.target.files[0]
              });
            setSelectedImage( URL.createObjectURL(evt.target.files[0]));
        }
      }
    //   console.log(getUserData)


  // fetch all data from api
  useEffect(()=>{
      dispatch(fetchUsers());
      SetUser(cookies.get('userAuth').token);
  },[])


  const handleSubmit = ()=>{
    const image_url = getUserData.image_url
    let data = removeEmpty(getUserData)
    data.image_url = image_url
      
    dispatch(fetchUpdatedUsers({userData: data, userId:User.data.id}))
    
}
useEffect(()=>{
    setActive(false)
    dispatch(fetchUsers());
},[UpdatedUser])
  // fetch all data from api
  useEffect(()=>{
    // setSelectedImage(`${process.env.BASE_URL}/storage/${User.data.image_url}`)
    setSelectedImage((User.data.image_url!=null) ? image+'/'+User.data.image_url : avater)
    editableData()
},[User])
 if(!getUser){
    return <NotFoundLogin/>
  }
//   if(!User.data){
//     <Loader/>
//   }
console.log(selectedImage,User.data.image_url,avater);

  return (
    <Layout>
        <div className="user_profile">
                {/* <section className='hero_section'>
            <div className="container g-0">
                <h2> Doctors of <br /> Ibrahim Cardiac Center</h2>
            </div>
            </section> */}
            {/* <section className="up_hearder_section">
                <div className="container g-0">
                    <div className="breadcrumb">
                        <ul className='breadcrumb_content'>
                        <li className='breadcrumb_list'>
                            <Link to="/">
                            <a className='breadcrumb_itme'>Home</a>
                            </Link>
                        </li>
                        <li className='breadcrumb_list'>
                            <Link to="/user/profile">
                            <a className='breadcrumb_itme'>{User.data.name}</a>
                            </Link>
                        </li>
                        </ul>
                    </div>
                    <div className="sc_title_wrapper">
                        <h3 className="sc_title">
                            {User.data.name}
                        </h3>
                    </div>
                </div>
            </section> */}
            <section className="up_section">
                <div className="container g-0 justify-content-center d-block">
                    <div className={getActive ? 'up_content active': 'up_content'}>
                        <label className="up_image_wrapper" htmlFor='upload_img'>
                            <img src={ selectedImage } layout='fill' alt='content_img' objectFit='cover' />
                            <input className='invisible' type="file" name="image_url" id='upload_img' onChange={(e) => {handleChange(e)}}/>
                            <span className='upload_img_ititle'>Click to change <br /> photo</span>
                        </label>
                        <div className="up_title">
                            <h3>Account Details</h3>
                        </div>
                        <div className="input_group mb-md-4">
                            <label className='input_field_label' htmlFor="">Full name</label>
                            <input className='input_field' name='name' defaultValue={User.data.name} onChange={(e)=>handleChange(e)} placeholder='Patient full name' /> 
                            {/* <label className='error' htmlFor="input_field"> DoctorApoinmentPagination</label> */}
                        </div>
                        <div className="input_group_content">
                            <div className="input_group">
                                <label className='input_field_label' htmlFor="birdtDate">Date of Birth</label>
                                <input className='input_field' type="date" defaultValue={User.data.created_at && new Date(User.data.created_at).toISOString().substring(0, 10)} onChange={(e)=>handleChange(e)} name='birth_date' />
                            </div>
                            <div className="input_group">
                                <label className='input_field_label' htmlFor="">Select a Gender</label>
                                <select name="gender" id="" className="input_field" defaultValue={'DEFAULT'} onChange={(e)=>handleChange(e)}>
                                    <option  value='DEFAULT' hidden>Select Gender</option>
                                    {optionList.map((e,i)=> e.value == User.data.gender? 
                                    <option key={i} value={e.value} selected>{e.label}</option> : 
                                    <option key={i} value={e.value}>{e.label}</option>  
                                   )}
                                </select>
                            </div>
                        </div>
                        <div className="input_group_content">
                            <div className="input_group">
                            <label className='input_field_label' htmlFor="">Select Nationality</label>
                                <select name="nationality" id="" className="input_field" defaultValue={User.data.nationality} onChange={(e)=>handleChange(e)}>
                                    <option value='DEFAULT' hidden>Select Nationality</option>
                                    <option value="Bangladesh">Bangladesh</option>
                                    <option value="local">Others</option>
                                </select>
                            </div>
                            <div className="input_group">
                                <label className='input_field_label' htmlFor="">Select City</label>
                                <select name="city" id="" className="input_field" defaultValue={User.data.City} onChange={(e)=>handleChange(e)}>
                                    <option value="Dhaka">Dhaka </option>
                                    <option value="Chittagong">Chittagong</option>
                                    <option value="Khulna">Khulna</option>
                                    <option value="Rajshahi ">Rajshahi</option>
                                    <option value="Comilla ">Comilla</option>
                                    <option value="Mymensingh ">Mymensingh</option>
                                    <option value="Sylhet  ">Sylhet</option>
                                    <option value="Rangpur">Rangpur</option> 
                                    <option value="Barishal">Barishal</option> 
                                    <option value="Narayanganj">Narayanganj</option> 
                                    <option value="Gazipur">Gazipur</option> 
                                    <option value="Others">Others</option> 
                                </select>
                            </div>
                        </div>
                        <div className="input_group_content">
                            <div className="input_group mb-md-5">
                                <label htmlFor="input_field" className='input_field_label'>Address</label>
                                <input className="input_field" name='address' defaultValue={User.data.address} type="text" onChange={(e)=>handleChange(e)} placeholder="Physical Address" required />
                            </div>
                            <div className="input_group mb-md-5">
                                <label htmlFor="input_field" className='input_field_label'>Postal Code</label>
                                <input className="input_field" name='post_code' defaultValue={User.data.post_code} type="text" onChange={(e)=>handleChange(e)} placeholder="Physical Address" required />
                            </div>
                        </div>
                        <div className="buttons">
                            <button className='user-btn back' onClick={handleBack} >Go Back</button>
                            <button className='user-btn edit' onClick={handleSubmit} >Submit</button>
                        </div>
                    </div> 
                    <div className={getActive ? 'up_content': 'up_content active'}>
                        <label className="up_image_wrapper">
                            <img src={ User.data.image_url ? `${image}/${User.data.image_url}`: selectedImage } layout='fill' alt='content_img' objectFit='cover' />
                            {/* <h3 className='invisible' type="file" id='upload_img' name="myImage" onChange={(event) => { setSelectedImage( URL.createObjectURL(event.target.files[0])); }}>test</h3> */}
                        </label>
                        <div className="up_title">
                            <h3>Account Details</h3>
                        </div>
                        <div className="input_group">
                            <label className='input_field_label' htmlFor="">Full name</label>
                            <h3 className='input_field_value' >{User.data.name}</h3>
                            {/* <label className='error' htmlFor="input_field_value"> DoctorApoinmentPagination</label> */}
                        </div>
                        <div className="input_group">
                            <label className='input_field_label' htmlFor="">Email</label>
                            <h3 className='input_field_value'>{User.data.email}</h3>
                        </div>
                        {User.data.nationality&&<div className="input_group">
                            <label className='input_field_label' htmlFor="">NID </label>
                            <h3 className='input_field_value' >{User.data.nationality}</h3>
                        </div>}
                        <div className="input_group">
                            <label htmlFor="input_field_value" className='input_field_label'>Phone</label>
                            <h3 className="input_field_value"  >{User.data.phone}</h3>
                        </div>
                        <div className="input_group_content">
                           { User.data.birth_date && <div className="input_group">
                                <label className='input_field_label' htmlFor="birdtDate">Date of Birth</label>
                                <h3 className='input_field_value'>{`${new Date(User.data.birth_date)}`.slice(0,15)}</h3>
                            </div>}
                            {User.data.gender && <div className="input_group">
                                <label htmlFor="input_field_value" className='input_field_label'>Gender</label>
                                <h3 className="input_field_value"  >{User.data.gender}</h3>
                            </div>}
                        </div>
                        <div className="input_group_content">
                            {User.data.nationality && <div className="input_group">
                                <label htmlFor="input_field_value" className='input_field_label'>Nationality</label>
                                <h3 className="input_field_value" name='phone' type="text" placeholder="Contact Number Exe: 01XXXXXXXXX" required >{User.data.nationality}</h3>
                            </div>}
                            {User.data.city && <div className="input_group">
                                <label htmlFor="input_field_value" className='input_field_label'>Your City</label>
                                <h3 className="input_field_value" name='phone' type="text" placeholder="Contact Number Exe: 01XXXXXXXXX" required >{User.data.city}</h3>
                            </div>}
                        </div>
                        <div className="input_group_content">
                            
                            { User.data.address && <div className="input_group">
                                <label htmlFor="input_field_value" className='input_field_label'>Address</label>
                                <h3 className="input_field_value"  >{User.data.address}</h3>
                            </div>}
                            
                            { User.data.post_code && <div className="input_group">
                                <label htmlFor="input_field_value" className='input_field_label'>Address</label>
                                <h3 className="input_field_value"  >{User.data.post_code}</h3>
                            </div>}
                        </div>
                        <button className='user-btn edit' onClick={handleEdit} >Edit</button>
                    </div>
                </div>
            </section>
        </div>
    </Layout>
    )}