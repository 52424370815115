import axios from "axios"
import http from "../../http"
import Cookies from 'universal-cookie';
import { FETCH_USER_ERR, FETCH_USER_REQ, FETCH_USER_SUCC } from "./listType"


export const fetch_user_req = ()=>{
   return {
        type: FETCH_USER_REQ
   }
}

export const fetch_user_succ = (data)=>{

    return {
         type: FETCH_USER_SUCC,
         payload: data
    }
 }

 export const fetch_user_err = (err)=>{
    return {
         type: FETCH_USER_ERR,
         payload: err
    }
 }

 export const fetchUsers =()=>{
    const cookies = new Cookies();
     return (dispatch)=>{
         dispatch(fetch_user_req);
         const url = `${process.env.BASE_URL}/api/auth/self`
        //  console.log(JSON.parse(localStorage.getItem("userData"))?.token)

         setTimeout(()=>{
            http.get('/auth/self',{
                headers:{
                    "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
                }  
            })
             .then(data => {
                const list_data = data.data.data;
                // console.log(list_data)
                 dispatch(fetch_user_succ(list_data))
             })
             .catch( err =>{
                 const error  = err.message;
                 dispatch(fetch_user_err(error))
     
             })
         },1000)
     }
 
  }