export const type = {
    header: "Header",
    main_menu: "Main Menu",
    footer: "Footer",
  };

export const subType = {
    left: "Left",
    right: "Right",
    top: "Top",
    bottom: "Bottom",
  };
  
  export const level = {
    1: "1",
    2: "2",
    3: "3",
  };

  export const order = {
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
    6: "6",
    7: "7",
    8: "8",
    9: "9",
    10: "10"
  };

  export const lang = {
    EN: "EN",
    BN: "BN",
  };


  
  // export const day = {
  //   0: "Saturday",
  //   1: "Sunday",
  //   2: "Monday",
  //   3: "Tuesday",
  //   4: "Wednesday",
  //   5: "ThursDay",
  //   6: "Friday"
  // };

  export const day = {
    Saturday: "Saturday",
    Sunday: "Sunday",
    Monday: "Monday",
    Tuesday: "Tuesday",
    Wednesday: "Wednesday",
    ThursDay: "Thursday",
    Friday: "Friday"
  };

  export const SliderType = {
    banner: "Banner",
    slider: "Slider",
  };

  export const status = {
    active: "Active",
    default: "Default",
  };

  export const insidePage = {
    doctors : "Doctors",
    services: "Services",
    packages: "Packages",
    newses: "Newses",
    events: "Events",
    pages: "Pages",
    publications: "Publications",
    departments: "Departments",
    treatments: "Treatments",
    careers: "Careers"
  } 

  export const publicationType = {
    "Medical Journal":"Medical Journal",
    "Cardiac Barta":"Cardiac Barta"
  }
  