import React from 'react'
import Layout from '../../components/layouts/layout'
import { ReactFormBuilder ,ReactFormGenerator} from 'react-form-builder2';
import 'react-form-builder2/dist/app.css';

const FormBuilder = () => {
    function handleUpdate(){
        console.log("handleUpdate");
    }
    function handleSubmit(){
        console.log("handleSubmit");
    }
  return (
    <Layout>
            <ReactFormBuilder
               form_action="/path/to/form/submit"
               form_method="POST"
               onChange={handleUpdate}
               onSubmit={handleSubmit}
            />
    </Layout>
  )
}

export default FormBuilder