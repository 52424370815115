import React ,{useState,useEffect} from 'react'
import { Link, useParams,useNavigate } from 'react-router-dom';
import { useForm,setValue } from "react-hook-form";
import { Button, Form, Toast } from 'react-bootstrap';
import http from "../../http";
import { BsFillCursorFill} from "react-icons/bs";
import {lang} from '../enum/enum'
import './doctor.css';
import TextLineLoader from '../../components/Loaders/TextLineLoader';
import loginCheck from '../../helpers/loginCheck';
import Layout from '../../components/layouts/layout';
import Cookies from 'universal-cookie';
import {Radio, RadioGroup, SelectPicker} from 'rsuite';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FullEditor from 'ckeditor5-build-full'
import uploadPlugin from '../../components/ImageUploader/uploadPlugin'

const DoctorEdit = () => {
    const {id} = useParams()
    const [doctors, setDoctors] = useState('');
    const {register, handleSubmit } = useForm();
    const [data,setData] = useState('');
    const [getSuccess,setSuccess] = useState(false);
    const [getError,setError] = useState('');
    const [getErrorObj,setErrorObj] = useState({})
    const [specialDoctors, setSpecialDoctors] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [parents, setParents] = useState([]);
    const [getAppointments, setAppointments] = useState([]);
    const [getLoader,setLoader] = useState(false);
    const cookies = new Cookies();
    const [getLangError,setLangError] = useState('');
    const image = process.env.REACT_APP_IMAGE_BASE_URL

    const navigate = useNavigate();
    useEffect(()=> {
        if(!loginCheck()){
            console.log("this works")
            navigate("/login");
        }
    });

    useEffect(()=>{
      fetchAllParents();
    },[]);

    const fetchAllParents = () => {
        http.get('/doctor/parent', {
          headers:{
           "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
          }  
      }).then(res=>{
            setParents(res.data.data);
        })
    }

  
  const getSearch = (val = "") =>{
      let val1 = val.length > 0 ? val : '*';
      if(val.length > 0) {
        http.get(`/doctor/speciality/search/${val}`, {
          headers:{
           "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
          }  
      }).then((response)=>{
          setSpecialDoctors(response.data.data);
        })
      }else if(doctors.dr_speciality_id){
        http.get(`/doctor/speciality/get/${doctors.dr_speciality_id}`, {
          headers:{
           "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
          }  
      }).then((response)=>{
          setSpecialDoctors({'data': [response.data.data]});
        })
      } else {
        http.get(`/doctor/speciality/search/${val1}`, {
          headers:{
           "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
          }  
      }).then((response)=>{
          setSpecialDoctors({'data': []});
        })
      }
  }

  useEffect(()=>{
    if(doctors.dr_speciality_id)
        getSearch()
    if(doctors.department_id)
       getDeptSearch()
  },[doctors]);


const getDeptSearch = (val = "") =>{
    let val1 = val.length > 0 ? val : '*';
    if(val.length > 0) {
      http.get(`/department/search/${val}`, {
        headers:{
         "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
        }  
    }).then((response)=>{
        setDepartments(response.data.data);
      })
    }else if(doctors.department_id){
      http.get(`/department/get/${doctors.department_id}`, {
        headers:{
         "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
        }  
    }).then((response)=>{
        setDepartments({'data': [response.data.data]});
      })
    } else {
      http.get(`/department/search/${val1}`, {
        headers:{
         "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
        }  
    }).then((response)=>{
        setDepartments({'data': []});
      })
    }
}
    
    useEffect(()=>{
        fetchEditDoctors();
    },[]);

    const fetchEditDoctors = () => {
        http.get('doctor/get/'+id, {
          headers:{
           "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
          }  
      }).then(res=>{
            console.log(res)
            setAppointments(res.data.data.appointments)
            setDoctors(removeEmpty(res?.data?.data));
        })
    }
    // console.log(getAppointments);
    function removeEmpty(obj) {
      return Object.fromEntries(
        Object.entries(obj)
          .filter(([_, v]) => v != null)
          .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
      );
    }

    function handleKeyPress(e) {
        // console.log(e.code)
        if (['Minus', 'NumpadSubtract'].includes(e.code)) {
            console.log(e.code);
            e.target.value = e.target.value.slice(0, -1)
        }
        else if(/^0/.test(e.target.value)) {
            e.target.value = e.target.value.replace(/^0/, "")
        }
        let intpart = e.target.value.split(".")[0];
        let decimal = e.target.value.split(".")[1].slice(0, 4);
        decimal = decimal.length > 0 ? "."+decimal : "";
        e.target.value = intpart + decimal;
        console.log(e.target.value)
        }

    const onSubmit = (data, e) => {
        setErrorObj({})
        setLangError('')
        const image_url = data.image_url[0];
        delete doctors.image_url;
        delete doctors.department
        delete doctors.dr_speciality
        delete doctors.lang_parent
        data = {...data, ...doctors};
        // data = removeEmpty(data)
        data.image_url = image_url;

        setLoader(true)
      http.post('/doctor/update/'+id,data,{
        headers:{
            "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
           }  
      })
      .then((res)=>{
        console.log(res);
        localStorage.setItem("successEdit", true);
        // setLoader(false);

        setTimeout(()=>{
          navigate("/doctor");
          },500)
        })
      .catch((err)=> {
          setLoader(false);
          // console.log(Object.keys(err.response.data.data))
          if(err.response.data.message == "Validation Error."){
            let errs = err.response.data.data;
            let keys = Object.keys(errs)
            console.log(keys);
            let errors = {};
            keys.map((key)=> errors[key] = errs[key][0])
            console.info(errors);
            setError(err.response.data)
            
            setErrorObj(errors)
          }
          else{
            setLangError(err.response.data.data)
          }
      })
  }

  const onError = (errors, e) => console.log(errors, e);
  const handleChange = (e) =>{
    let doctor = {...doctors};
    doctor[e.target.name] = e.target.value
    setDoctors(doctor);
    }
    console.log(doctors);
  return (
    <>
    <Layout>

      { getLoader && 
        <div className="preloader">
            <div className="overlay__inner">
                <div className="overlay__content"><span className="spin"></span></div>
            </div>
        </div>
      }

      {doctors == "" ? 
              <><TextLineLoader/><TextLineLoader/><TextLineLoader/></>:
      <div className="form_design">
            <h2 style={{color: "black",textDecoration: "underline"}}>Edit Doctor</h2>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group>
                  <Form.Label className="form_label">Name</Form.Label>
                  <Form.Control
                      type="text"
                      name="name"
                      defaultValue={doctors.name}
                      placeholder="Enter name"
                    //   {...register("name")}
                    onChange={(e) => handleChange(e)}
                  />
                  {getErrorObj.name? 
                  <span className="text-danger">
                    {getErrorObj.name}
                  </span> : null
                  }
              </Form.Group>

              <Form.Group>
                    <Form.Label className="form_label">Minute Per Patient</Form.Label>
                    <Form.Control
                        type="number"
                        defaultValue={doctors.minute_per_patient}
                        name="minute_per_patient"
                        step="1.00"
                        readOnly={(getAppointments.length>0) ? true :false }
                        placeholder="Enter Minute Per Patient"
                        onKeyUp={(e) => handleKeyPress(e)}
                        // {...register("minute_per_patient")}
                        onChange={(e) => handleChange(e)}
                        onWheel={ event => event.currentTarget.blur() }
                    />
                    {getErrorObj.minute_per_patient? 
                    <span className="text-danger">
                      {getErrorObj.minute_per_patient}
                    </span> : null
                    }
                </Form.Group>

              <Form.Group>
                    <Form.Label className="form_label">Education</Form.Label>
                    <Form.Control
                        type="text"
                        name="education"
                        defaultValue={doctors.education}
                        placeholder="Enter Education"
                        // {...register("name")}
                        onChange={(e) => handleChange(e)}
                    />
                    {getErrorObj.education? 
                    <span className="text-danger">
                      {getErrorObj.education}
                    </span> : null
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Designation</Form.Label>
                    <Form.Control
                        type="text"
                        name="designation"
                        defaultValue={doctors.designation}
                        placeholder="Enter Designation"
                        // {...register("name")}
                        onChange={(e) => handleChange(e)}
                    />
                    {getErrorObj.designation? 
                    <span className="text-danger">
                      {getErrorObj.designation}
                    </span> : null
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Experience</Form.Label>
                    <Form.Control
                        as={"textarea"}
                        rows={5}
                        type="text"
                        name="experience"
                        defaultValue={doctors.experience}
                        placeholder="Enter Experience"
                        // {...register("name")}
                        onChange={(e) => handleChange(e)}
                    />
                    {getErrorObj.experience? 
                    <span className="text-danger">
                      {getErrorObj.experience}
                    </span> : null
                    }
                </Form.Group>

              <Form.Group>
                    <Form.Label className="form_label">Special Doctor</Form.Label>
                      <SelectPicker 
                          defaultValue={doctors.dr_speciality?.id}
                          data={specialDoctors.data?.map((item) => ({ label: item.title, value: item.id }))} 
                          style={{ width: "100%" }} 
                          onSearch={(val)=>getSearch(val)}
                          onChange={(value) => setDoctors({...doctors, "dr_speciality_id": value})}
                      />
                    {getErrorObj.dr_speciality_id? 
                    <span className="text-danger">
                      {getErrorObj.dr_speciality_id}
                    </span> : null
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Email</Form.Label>
                    <Form.Control
                        type="email"
                        name="email"
                        placeholder="Enter email"
                        defaultValue={doctors.email}
                        // {...register("email")}
                        onChange={(e) => handleChange(e)}
                    />
                    {getErrorObj.email? 
                    <span className="text-danger">
                      {getErrorObj.email}
                    </span> : null
                    }
                </Form.Group>

                <Form.Group>
                  <Form.Label className="form_label">Image url</Form.Label>
                  <Form.Control
                      type="file"
                      name="image_url"
                      placeholder="Enter Image file"
                      {...register("image_url")}
                  />
                  <p>
                    {
                      (doctors.image_url) ? <a href={image+'/'+doctors.image_url} target="_blank"><BsFillCursorFill/> </a> : ''
                    }
                  </p>
                  {getErrorObj.image_url? 
                  <span className="text-danger">
                    {getErrorObj.image_url}
                  </span> : null
                  }
              </Form.Group>

                
                <Form.Group>
                    <Form.Label className="form_label">Department</Form.Label>
                    <SelectPicker 
                          defaultValue={doctors.department_id}
                          data={departments.data?.map((item) => ({ label: item.title, value: item.id }))} 
                          style={{ width: "100%" }} 
                          onSearch={(val)=>getDeptSearch(val)}
                          onChange={(value) => setDoctors({...doctors, "department_id": value})}
                      />
                    {getErrorObj.department_id? 
                    <span className="text-danger">
                      {getErrorObj.department_id}
                    </span> : null
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Patient Report Upload</Form.Label>
                    <RadioGroup
                        name="radioList"
                        inline
                        onChange={(value) =>
                            setDoctors({ ...doctors, "is_report_upload": value })
                        }
                        value={doctors.is_report_upload.toString()}
                    >
                        <Radio value="1">Yes</Radio>
                        <Radio value="0">No</Radio>
                    </RadioGroup>
                    {getErrorObj.is_report_upload ? (
                        <span className="text-danger">{getErrorObj.is_report_upload}</span>
                    ) : null}
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Visit</Form.Label>
                    <Form.Control
                        type="number"
                        defaultValue={doctors.visit}
                        name="visit"
                        step="0.0001"
                        placeholder="Enter visit"
                        onKeyUp={(e) => handleKeyPress(e)}
                        // {...register("visit")}
                        onChange={(e) => handleChange(e)}
                        onWheel={ event => event.currentTarget.blur() }
                    />
                    {getErrorObj.visit? 
                    <span className="text-danger">
                      {getErrorObj.visit}
                    </span> : null
                    }
                </Form.Group>
              

              <Form.Group>
                    <Form.Label className="form_label">Language Parent(English)</Form.Label>
                    <SelectPicker 
                          defaultValue={doctors.lang_parent_id}
                          data={parents?.map((item) => ({ label: item.name, value: item.id }))} 
                          style={{ width: "100%" }} 
                          onChange={(value) => setDoctors({...doctors, "lang_parent_id": value})}

                      />
                      {getErrorObj.lang_parent_id? 
                    <span className="text-danger">
                      {getErrorObj.lang_parent_id}
                    </span> : null
                    }
                    {getLangError? 
                        <span className="text-danger">
                          {getLangError}
                        </span> : null
                        }
                </Form.Group>


                <Form.Group>
                    <Form.Label className="form_label">Language</Form.Label>
                    <SelectPicker 
                          defaultValue={doctors.lang}
                          data={Object.keys(lang).map((item) => ({ label: item, value: item }))} 
                          style={{ width: "100%" }} 
                          onChange={(value) => setDoctors({...doctors, "lang": value})}
                      />
                    {getErrorObj.lang? 
                    <span className="text-danger">
                      {getErrorObj.lang}
                    </span> : null
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Phone</Form.Label>
                    <Form.Control
                        type="text"
                        name="phone"
                        placeholder="Enter phone"
                        // {...register("phone")}
                        defaultValue={doctors.phone}
                        onChange={(e) => handleChange(e)}
                    />
                    {getErrorObj.phone? 
                    <span className="text-danger">
                      {getErrorObj.phone}
                    </span> : null
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Description</Form.Label>
                    <CKEditor as="textarea" rows={10}
                        config={{
                          extraPlugins: [uploadPlugin]
                        }}
                        data={doctors.description}
                        editor={ FullEditor }
                        type="text"
                        name="description"
                        placeholder="Enter Description"
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            console.log( { event, editor, data } );
                            setDoctors({...doctors, "description": data})
                        } }
                    />
                    {getErrorObj.description? 
                    <span className="text-danger">
                      {getErrorObj.description}
                    </span> : null
                    }
                </Form.Group>

              <div className="mt-4">
                  <Button className="action__btn" variant="primary" type="submit">
                      Update
                  </Button>
                  <Link to="/doctor">
                      <Button className="action__btn" variant="info">
                          Back to Home
                      </Button>
                  </Link>
              </div>
            </Form>
          </div> 
        } 
    </Layout>
    </>
  );
};


export default DoctorEdit