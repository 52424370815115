import React ,{useState,useEffect} from 'react'
import { Link, useParams,useNavigate } from 'react-router-dom';
import { useForm, set } from "react-hook-form";
import { Button, Form, Toast } from 'react-bootstrap';

import http from "../../http";
import { BsFillCursorFill} from "react-icons/bs";
import {status,order,lang,SliderType} from '../enum/enum'
import './slider.css';
import TextLineLoader from '../../components/Loaders/TextLineLoader';
import loginCheck from '../../helpers/loginCheck';
import Layout from '../../components/layouts/layout';
import Cookies from 'universal-cookie';
import { SelectPicker } from 'rsuite';

const SliderEdit = () => {
    const {id} = useParams()
    const [sliders, setSliders] = useState('');
    const {register, handleSubmit } = useForm();
    const [data,setData] = useState('');
    const [getSuccess,setSuccess] = useState(false);
    const [getError,setError] = useState('');
    const [getErrorObj,setErrorObj] = useState({})
    const [parents, setParents] = useState([]);
    const [getLangError,setLangError] = useState('');
    const [getLoader,setLoader] = useState(false);
    const cookies = new Cookies();
    
    const navigate = useNavigate();
    useEffect(()=> {
        if(!loginCheck()){
            console.log("this works")
            navigate("/login");
        }
    });

    useEffect(()=>{
      fetchAllParents();
    },[]);

    const fetchAllParents = () => {
        http.get('/slider/parent', {
          headers:{
           "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
          }  
      }).then(res=>{
            setParents(res.data.data);
        })
    }


    useEffect(()=>{
      fetchEditSliders();
    },[]);

    const fetchEditSliders = () => {
        http.get('slider/get/'+id, {
          headers:{
           "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
          }  
      }).then(res=>{
            setSliders(removeEmpty(res.data.data));
        })
    }

    function removeEmpty(obj) {
      return Object.fromEntries(
        Object.entries(obj)
          .filter(([_, v]) => v != null && v != "")
          .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
      );
    }

    const onSubmit = (data, e) => {
      setErrorObj({})
      setLangError('')
      const image_url = data.image_url[0];
      delete sliders.image_url;
      delete sliders.lang_parent;

      data = {...data, ...sliders};
      data.user_id = cookies.get('userAuth').id
      // data = removeEmpty(data)
      data.image_url = image_url;

      setLoader(true)
      http.post('/slider/update/'+id,data, {
        headers:{
         "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
        }  
      }
      )
      .then((res)=>{
        console.log(res);
        localStorage.setItem("successEdit", true);
        // setLoader(false);
        setTimeout(()=>{
          navigate("/slider");
          },500)
        })
      .catch((err)=> {
        setLoader(false);
          // console.log(Object.keys(err.response.data.data))
          if(err.response.data.message == "Validation Error."){
            let errs = err.response.data.data;
            let keys = Object.keys(errs)
            console.log(keys);
            let errors = {};
            keys.map((key)=> errors[key] = errs[key][0])
            console.info(errors);
            setError(err.response.data)
            
            setErrorObj(errors)
          }
          else{
            setLangError(err.response.data.data)
          }
      })
  }

  const onError = (errors, e) => console.log(errors, e);
  
  const image = process.env.REACT_APP_IMAGE_BASE_URL;

  const handleChange = (e) =>{
    console.log(e);
    let slider = {...sliders};
    slider[e.target.name] = e.target.value
    setSliders(slider);
  }

  return (
    <>
    <Layout>

      { getLoader && 
        <div className="preloader">
            <div className="overlay__inner">
                <div className="overlay__content"><span className="spin"></span></div>
            </div>
        </div>
      }

      {sliders == "" ? 
              <><TextLineLoader/><TextLineLoader/><TextLineLoader/></>:
      <div className="form_design">
            <h2 style={{color: "black",textDecoration: "underline"}}>Edit Slider</h2>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group>
                  <Form.Label className="form_label">Title</Form.Label>
                  <Form.Control
                      type="text"
                      name="title"
                      defaultValue={sliders.title}
                      placeholder="Enter title"
                      onChange={(e) => handleChange(e)}
                      // {...register("title")}
                  />
                  {getErrorObj.title? 
                  <span className="text-danger">
                    {getErrorObj.title}
                  </span> : null
                  }
              </Form.Group>

              <Form.Group>
                  <Form.Label className="form_label">Sub title</Form.Label>
                  <Form.Control
                      type="text"
                      name="sub_title"
                      defaultValue={sliders.sub_title}
                      placeholder="Enter sub title"
                      onChange={(e) => handleChange(e)}
                  />
                  {getErrorObj.sub_title? 
                  <span className="text-danger">
                    {getErrorObj.sub_title}
                  </span> : null
                  }
              </Form.Group>

              <Form.Group>
                  <Form.Label className="form_label">Type</Form.Label>
                  <SelectPicker 
                          name="type"
                          defaultValue={sliders.type}
                          data={Object.keys(SliderType).map((item) => ({label: SliderType[item], value: item }))}                           
                          style={{ width: "100%" }} 
                          onChange={(value) => setSliders({...sliders, "type": value})}
                  />
                  {getErrorObj.type? 
                  <span className="text-danger">
                    {getErrorObj.type}
                  </span> : null
                  }
              </Form.Group>

              <Form.Group>
                    <Form.Label className="form_label">Status</Form.Label>
                    <SelectPicker 
                          name="status"
                          defaultValue={sliders.status}
                          data={Object.keys(status).map((item) => ({label: status[item], value: item }))}                           
                          style={{ width: "100%" }} 
                        //   onChange={(value)=>(setSubType(value))}
                        onChange={(value) => setSliders({...sliders, "status": value})}

                      />
                    {getErrorObj.status? 
                    <span className="text-danger">
                      {getErrorObj.status}
                    </span> : null
                    }
                </Form.Group>

          
              <Form.Group>
                  <Form.Label className="form_label">Route</Form.Label>
                  <Form.Control
                      type="text"
                      name="route"
                      defaultValue={sliders.route}
                      placeholder="Enter Route"
                      // {...register("route")}
                      onChange={(e) => handleChange(e)}
                  />
                  {getErrorObj.route? 
                  <span className="text-danger">
                    {getErrorObj.route}
                  </span> : null
                  }
              </Form.Group>


              <Form.Group>
                    <Form.Label className="form_label">Button Name</Form.Label>
                    <Form.Control
                        type="text"
                        defaultValue={sliders.button_one_name}
                        name="button_one_name"
                        placeholder="Enter Button Name"
                        // {...register("route")}
                        onChange={(e) => handleChange(e)}
                    />
                    {getErrorObj.button_one_name? 
                    <span className="text-danger">
                      {getErrorObj.button_one_name}
                    </span> : null
                    }
                </Form.Group>


                <Form.Group>
                    <Form.Label className="form_label">Button URL</Form.Label>
                    <Form.Control
                        type="text"
                        defaultValue={sliders.button_one_path}
                        name="button_one_path"
                        placeholder="Enter Button URL"
                        // {...register("route")}
                        onChange={(e) => handleChange(e)}
                    />
                    {getErrorObj.button_one_path? 
                    <span className="text-danger">
                      {getErrorObj.button_one_path}
                    </span> : null
                    }
                </Form.Group>

              <Form.Group>
                  <Form.Label className="form_label">Image url</Form.Label>
                  <Form.Control
                      type="file"
                      name="image_url"
                      placeholder="Enter Image file"
                      {...register("image_url")}
                  />
                  <p>
                    {
                      (sliders.image_url) ? <a href={image+'/'+sliders.image_url} target="_blank"><BsFillCursorFill/> </a> : ''
                    }
                  </p>
                  {getErrorObj.image_url? 
                  <span className="text-danger">
                    {getErrorObj.image_url}
                  </span> : null
                  }
              </Form.Group>


              <Form.Group>
                  <Form.Label className="form_label">Order</Form.Label>
                   <SelectPicker 
                          name="order"
                          defaultValue={sliders.order?.toString()}
                          data={Object.keys(order).map((item) => ({label: item, value: item }))}                           
                          style={{ width: "100%" }} 
                          onChange={(value) => setSliders({...sliders, "order": value})}
                      />
                  {getErrorObj.order? 
                  <span className="text-danger">
                    {getErrorObj.order}
                  </span> : null
                  }
              </Form.Group>


              <Form.Group>
                  <Form.Label className="form_label">Language Parent(English)</Form.Label>
                  <SelectPicker 
                          defaultValue={sliders.lang_parent_id}
                          data={parents?.map((item) => ({ label: item.title, value: item.id }))} 
                          style={{ width: "100%" }} 
                          onChange={(value) => setSliders({...sliders, "lang_parent_id": value})}

                      />
                  {getErrorObj.lang_parent_id? 
                  <span className="text-danger">
                    {getErrorObj.lang_parent_id}
                  </span> : null
                  }
                  {getLangError? 
                            <span className="text-danger">
                            {getLangError}
                            </span> : null
                        }
              </Form.Group>


              <Form.Group>
                  <Form.Label className="form_label">Language</Form.Label>
                  <SelectPicker 
                          defaultValue={sliders.lang}
                          data={Object.keys(lang).map((item) => ({ label: item, value: item }))} 
                          style={{ width: "100%" }} 
                          onChange={(value) => setSliders({...sliders, "lang": value})}
                      />
                  {getErrorObj.lang? 
                  <span className="text-danger">
                    {getErrorObj.lang}
                  </span> : null
                  }
              </Form.Group>

              <div className="mt-4">
                  <Button className="action__btn" variant="primary" type="submit">
                      Update
                  </Button>
                  <Link to="/slider">
                      <Button className="action__btn" variant="info">
                          Back to Home
                      </Button>
                  </Link>
              </div>
            </Form>
          </div> 
        } 
    </Layout>
    </>
  );
};


export default SliderEdit