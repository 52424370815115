import React ,{useState,useEffect} from 'react'
import nav from './nav.css';
import { Link , useNavigate} from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Button, Form, Toast } from 'react-bootstrap';
import http from "../../http"
import {type,subType,level,order,lang} from '../enum/enum'
import Layout from '../../components/layouts/layout';
import loginCheck from '../../helpers/loginCheck';
import Cookies from 'universal-cookie';
import { SelectPicker } from 'rsuite';


const MenuCreate = () => {
    const { register, handleSubmit } = useForm();
    const [data,setData] = useState('');
    const [menus, setMenus] = useState('');
    const [getSuccess,setSuccess] = useState(false);
    const [getError,setError] = useState('');
    const [getErrorObj,setErrorObj] = useState({})
    const [parents, setParents] = useState([]);
    const [getLangError,setLangError] = useState('');
    const [getLoader,setLoader] = useState(false);
    const cookies = new Cookies();

    const navigate = useNavigate();
    useEffect(()=> {
        if(!loginCheck()){
            console.log("this works")
            navigate("/login");
        }
    });

    useEffect(()=>{
        fetchAllParents();
    },[]);

    const fetchAllParents = () => {
        http.get('/menu/parent', {
          headers:{
           "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
          }  
      }).then(res=>{
            setParents(res.data.data);
        })
    }
    
    function removeEmpty(obj) {
      return Object.fromEntries(
        Object.entries(obj)
          .filter(([_, v]) => v != null && v != "")
          .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
      );
    }

    const onSubmit = (data, e) => {
        setErrorObj({})
        setLangError('')
        const image_url = data.image_url[0];        
        delete menus.image_url;
        delete menus.sub_title;
        data = {...data, ...menus};
        data = removeEmpty(data)
        
        data.image_url = image_url;

        data.user_id = cookies.get('userAuth').id
        setLoader(true)
        http.post('/menu/create',data, {
            headers:{
             "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
            }  
        })
        .then((res)=>{
            console.log(res);
            // setSuccess(true);
            localStorage.setItem("success", true);
            // setLoader(false);
            setTimeout(()=>{
              navigate("/menu");
              },1000)
            })
        .catch((err)=> {
          setLoader(false);
            // console.log(Object.keys(err.response.data.data))
            // console.log(err.response.data.message);
            if(err.response.data.message == "Validation Error."){
              let errs = err.response.data.data;
              let keys = Object.keys(errs)
              console.log(keys);
              let errors = {};
              keys.map((key)=> errors[key] = errs[key][0])
              console.info(errors);
              setError(err.response.data)
              
              setErrorObj(errors)
            }
            else{
              setLangError(err.response.data.data)
            }
        })
    }
    const onError = (errors, e) => console.log(errors, e);

    const handleChange = (e) =>{
        // console.log(e);
        let menu = {...menus};
        menu[e.target.name] = e.target.value
        setMenus(menu);
    }
    
  return (
    <>
    <Layout>
        {/* {
            getSuccess ? 
                (
                    <Toast bg='success' style={{position: 'fixed', top:'30px', right:'0'}}>
                        <Toast.Body >Succesfully Added</Toast.Body>
                    </Toast>
                )
            :null
        } */}
        { getLoader && 
          <div className="preloader">
              <div className="overlay__inner">
                  <div className="overlay__content"><span className="spin"></span></div>
              </div>
          </div>
        }

        <div className="form_design">
            <h2 style={{color: "black",textDecoration: "underline"}}>Create Menu</h2>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group>
                    <Form.Label className="form_label">Title</Form.Label>
                    <Form.Control
                        type="text"
                        name="title"
                        placeholder="Enter title"
                        // {...register("title")}
                        onChange={(e) => handleChange(e)}
                    />
                    {getErrorObj.title? 
                    <span className="text-danger">
                      {getErrorObj.title}
                    </span> : null
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Short Description</Form.Label>
                    <Form.Control
                        as="textarea" rows={5}
                        type="text"
                        name="sub_title"
                        placeholder="Enter Short Description"
                        // {...register("title")}
                        onChange={(e) => handleChange(e)}
                    />
                    {getErrorObj.sub_title? 
                    <span className="text-danger">
                      {getErrorObj.sub_title}
                    </span> : null
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Type</Form.Label>
                    <SelectPicker 
                          name="type"
                          data={Object.keys(type).map((item) => ({label: type[item], value: item }))}                           
                          style={{ width: "100%" }} 
                        //   onChange={(value)=>(setType(value))}
                        onChange={(value) => setMenus({...menus, "type": value})}
                      />
                    {getErrorObj.type? 
                    <span className="text-danger">
                      {getErrorObj.type}
                    </span> : null
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Sub Type</Form.Label>
                    <SelectPicker 
                          name="sub_type"
                          data={Object.keys(subType).map((item) => ({label: subType[item], value: item }))}                           
                          style={{ width: "100%" }} 
                        //   onChange={(value)=>(setSubType(value))}
                        onChange={(value) => setMenus({...menus, "sub_type": value})}

                      />
                    {getErrorObj.sub_type? 
                    <span className="text-danger">
                      {getErrorObj.sub_type}
                    </span> : null
                    }
                </Form.Group>

            
                <Form.Group>
                    <Form.Label className="form_label">Route</Form.Label>
                    <Form.Control
                        type="text"
                        name="route"
                        placeholder="Enter Route"
                        // {...register("route")}
                        onChange={(e) => handleChange(e)}
                    />
                    {getErrorObj.route? 
                    <span className="text-danger">
                      {getErrorObj.route}
                    </span> : null
                    }
                </Form.Group>


                <Form.Group>
                    <Form.Label className="form_label">Image</Form.Label>
                    <Form.Control
                        type="file"
                        name="image_url"
                        placeholder="Enter Image file"
                        {...register("image_url")}
                    />
                    {getErrorObj.image_url? 
                    <span className="text-danger">
                      {getErrorObj.image_url}
                    </span> : null
                    }
                </Form.Group>
                
                <Form.Group>
                    <Form.Label className="form_label">Level</Form.Label>
                    <SelectPicker 
                          name="level"
                          data={Object.keys(level).map((item) => ({label: item, value: item }))}                           
                          style={{ width: "100%" }} 
                        //   onChange={(value)=>(setLevel(value))}
                        onChange={(value) => setMenus({...menus, "level": value})}
                      />
                    {getErrorObj.level? 
                    <span className="text-danger">
                      {getErrorObj.level}
                    </span> : null
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Order</Form.Label>
                    <SelectPicker 
                          name="order"
                          data={Object.keys(order).map((item) => ({label: item, value: item }))}                           
                          style={{ width: "100%" }} 
                        //   onChange={(value)=>(setOrder(value))}
                        onChange={(value) => setMenus({...menus, "order": value})}
                      />
                    {getErrorObj.order? 
                    <span className="text-danger">
                      {getErrorObj.order}
                    </span> : null
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Parent</Form.Label>
                    <SelectPicker 
                          name="parent_id" 
                          data={parents?.map((item) => ({ label: item.title, value: item.id }))} 
                          style={{ width: "100%" }} 
                        //   onChange={(value)=>(setPar(value))}
                        onChange={(value) => setMenus({...menus, "parent_id": value})}
                      />
                    {getErrorObj.parent_id? 
                    <span className="text-danger">
                      {getErrorObj.parent_id}
                    </span> : null
                    }
                </Form.Group>


                <Form.Group>
                    <Form.Label className="form_label">Language Parent(English)</Form.Label>
                    <SelectPicker 
                          name="lang_parent_id" 
                          data={parents?.map((item) => ({ label: item.title, value: item.id }))} 
                          style={{ width: "100%" }} 
                        //   onChange={(value)=>(setAuthor(value))}
                        onChange={(value) => setMenus({...menus, "lang_parent_id": value})}
                      />
                      {getErrorObj.lang_parent_id? 
                        <span className="text-danger">
                          {getErrorObj.lang_parent_id}
                        </span> : null
                      }

                      {getLangError? 
                        <span className="text-danger">
                          {getLangError}
                        </span> : null
                      }

                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Language</Form.Label>
                    <SelectPicker 
                          name="lang" 
                          data={Object.keys(lang).map((item) => ({ label: item, value: item }))} 
                          style={{ width: "100%" }} 
                          {...register("lang")} 
                        //   onChange={(value)=>(setLang(value))}
                        onChange={(value) => setMenus({...menus, "lang": value})}
                      />
                    {getErrorObj.lang? 
                    <span className="text-danger">
                      {getErrorObj.lang}
                    </span> : null
                    }
                </Form.Group>
                <div className="mt-4">
                    <Button className="action__btn" variant="primary" type="submit">
                        Save
                    </Button>
                    <Link to="/menu">
                        <Button className="action__btn" variant="info">
                            Back to Home
                        </Button>
                    </Link>
                </div>
            </Form>
        </div> 
    </Layout>
    </>
    )
}

export default MenuCreate