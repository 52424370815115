import React ,{useState,useEffect} from 'react'
import './role.css';
import { Link , useNavigate,useParams} from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Button, Form, Toast,Badge } from 'react-bootstrap';
import http from "../../http"
import {type,subType,level,order,lang,department} from '../enum/enum'
import Layout from '../../components/layouts/layout';
import loginCheck from '../../helpers/loginCheck';
import Cookies from 'universal-cookie';
import { SelectPicker,CheckPicker } from 'rsuite';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FullEditor from 'ckeditor5-build-full'
import TextLineLoader from '../../components/Loaders/TextLineLoader';

const RoleRevokePermission = () => {
    const { register, handleSubmit } = useForm();
    const [data,setData] = useState('');
    const [roles, setRoles] = useState('');
    const [keyss,setKeys] = useState([])
    const [values,setValues] = useState([])
    const [getSuccess,setSuccess] = useState(false);
    const [getError,setError] = useState('');
    const [getErrorObj,setErrorObj] = useState({})
    const [permissions, setPermissions] = useState([]);
    const [getPer, setPer] = useState([]);
    const [test, setTest] = useState([]);
    const [getLoader,setLoader] = useState(false);
    const cookies = new Cookies();
    const navigate = useNavigate();
    const {id} = useParams()

    console.log(id);
    useEffect(()=> {
        if(!loginCheck()){
            console.log("this works")
            navigate("/login");
        }
    });

    useEffect(()=>{
        fetchAllPermission();
    },[]);

    const fetchAllPermission = () => {
        http.get(`/role/get/${id}`,{
            headers:{
              "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
             } 
          }).then(res=>{
            console.log(res.data.data)
            setPer(res.data.data);
            setTest(hello(res.data.data))
        })
    }

    const hello = (val) =>{
        console.log(val);
        let testArr = []
        val.permissions.map(ele=>{
        testArr.push(ele.id)
        })
        console.log(testArr)
        return testArr
    }

    useEffect(()=>{
        if(getPer.permissions)
           getSearch()
      },[getPer.permissions]);
    
     const getSearch = (val = "") =>{
         let val1 = val.length > 0 ? val : '*';
         console.log(val1)

        
         if(val.length > 0) {
          http.get(`/permission/search/${val}`,{
                headers:{
                    "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
                   } 
                }).then((response)=>{
                    console.log(response.data.data)
            setValues(response.data.data);
          })
         }else if(getPer.permissions){
           http.get(`/role/get/${id}`,{
            headers:{
                "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
               } 
            }).then((response)=>{
                console.log(response.data.data);  
                let a = response.data.data.permissions.map(ele=> ({ name: ele.name, id: ele.id }))
                console.log(a);            
                setValues({'data': a});
           })
         } else {
           http.get(`/permission/search/${val1}`,{
            headers:{
                "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
               } 
            }).then((response)=>{
             setValues({'data': []});
           })
         }
     }
     console.log(values);

   
    const onSubmit = (data, e) => {       
        data = {...data, ...permissions,test};
        // data.user_id = cookies.get('userAuth').id
        data.permissions = (data.permissions === undefined) ? data.test : data.permissions
        console.log(data)
        setLoader(true)
        http.post(`/role/revoke-permission-from-role?id=${id}`,data, {
            headers:{
             "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
             "Content-type": "Application/json"
            }  
        }).then((res)=>{
            console.log(res);
            localStorage.setItem("successEdit", true);
            // setLoader(false);
            setTimeout(()=>{
              navigate("/role");
              },500)
            })
        .catch((err)=> {
            setLoader(false);
            console.log(err)
            // console.log(Object.keys(err.response.data.data))
            let errs = err.response.data.data;
            let keys = Object.keys(errs)
            console.log(keys);
            let errors = {};
            keys.map((key)=> errors[key] = errs[key][0])
            console.info(errors);
            setError(err.response.data)
            
            setErrorObj(errors)
        })
    }
    const onError = (errors, e) => console.log(errors, e);

  return (
    <>
    <Layout>
      { getLoader && 
        <div className="preloader">
            <div className="overlay__inner">
                <div className="overlay__content"><span className="spin"></span></div>
            </div>
        </div>
      }
    {test  == "" ? 
              <><TextLineLoader/><TextLineLoader/><TextLineLoader/></>:

        <div className="form_design">
            <h2 style={{color: "black",textDecoration: "underline"}}>Revoke Permission</h2>
            <Form onSubmit={handleSubmit(onSubmit)}>

                <Form.Group>
                    {getPer && 
                    <>
                        <Form.Label className="form_label">Permission List</Form.Label>
                        <p>Role Name: {getPer.name}</p>
                        <div className="d-flex gap-1 p-2 flex-wrap" style={{border:"1px solid #e5e5ea",borderRadius:"6px"}}>
                                {
                                    getPer?.permissions?.map(ele =>
                                        <Badge style={{fontSize:"1em",}}>{ele.name}</Badge>
                                        )
                                }
                        </div>
                    </>
                    }
                </Form.Group>
                    
                <Form.Group>
                    <Form.Label className="form_label">Delete Permission</Form.Label>
                    <CheckPicker  
                        name="permission" 
                        defaultValue={test}
                        data={values.data?.map((item) => ({ label: item.name, value: item.id }))} 
                        style={{ width: "100%" }} 
                        // onSearch={(value) => getSearch(value)} 
                        onChange={(value) => setPermissions({...permissions, "permissions": value})} 
                    />

                    {getErrorObj.permissions? 
                    <span className="text-danger">
                    {getErrorObj.permissions}
                    </span> : null
                    }
                </Form.Group>

                <div className="mt-4">
                    <Button className="action__btn" variant="primary" type="submit">
                        Save
                    </Button>
                    <Link to="/role">
                        <Button className="action__btn" variant="info">
                            Back to Home
                        </Button>
                    </Link>
                </div>
            </Form>
        </div>  
    }
    </Layout>
    </>
  )
}

export default RoleRevokePermission