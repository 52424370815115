import React ,{useState,useEffect} from 'react'
import { Link , useNavigate} from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Button, Form, Toast } from 'react-bootstrap';
import http from "../../http"
import Layout from '../../components/layouts/layout';
import loginCheck from '../../helpers/loginCheck';
import Cookies from 'universal-cookie';
import { SelectPicker } from 'rsuite';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const YoutubeLinkCreate = () => {
    const { register, handleSubmit } = useForm();
    const [data,setData] = useState('');
    const [youtubeLink,setYoutubeLink] = useState('');
    const [getError,setError] = useState('');
    const [getErrorObj,setErrorObj] = useState({})
    const [getLangError,setLangError] = useState('');
    const [getLoader,setLoader] = useState(false);
    const cookies = new Cookies();

    const navigate = useNavigate();

    useEffect(()=> {
        if(!loginCheck()){
            console.log("this works")
            navigate("/login");
        }
    });

    function removeEmpty(obj) {
        return Object.fromEntries(
            Object.entries(obj)
            .filter(([_, v]) => v != null && v != "")
            .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
        );
    }

    const onSubmit = (data) => {
        setErrorObj({})
        setLangError('')
        data = {...youtubeLink};
        data = removeEmpty(data)

        setLoader(true)

        http.post('/youtube-link/create',data, {
            headers:{
             "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
            }  
        }
        )
        .then((res)=>{
            console.log(res);
            // setSuccess(true);
            localStorage.setItem("success", true);
            // setLoader(false);
            setTimeout(()=>{
              navigate("/youtube-link");
              },1000)
            })
        .catch((err)=> {
            setLoader(false);
            if(err.response.data.message == "Validation Error."){
                let errs = err.response.data.data;
                let keys = Object.keys(errs)
                console.log(keys);
                let errors = {};
                keys.map((key)=> errors[key] = errs[key][0])
                console.info(errors);
                setError(err.response.data)
                
                setErrorObj(errors)
              }
              else{
                setLangError(err.response.data.data)
              }
        })
    }
    const onError = (errors, e) => console.log(errors, e);
    const handleChange = (e) =>{
        setErrorObj({})
        let file = {...youtubeLink};
        file[e.target.name] = e.target.value
        setYoutubeLink(file);
    }

  return (
    <>
    <Layout>
        { getLoader && 
          <div className="preloader">
              <div className="overlay__inner">
                  <div className="overlay__content"><span className="spin"></span></div>
              </div>
          </div>
        }

        <div className="form_design">
            <h2 style={{color: "black",textDecoration: "underline"}}>Create Youtube Link</h2>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group>
                    <Form.Label className="form_label">Title</Form.Label>
                    <Form.Control
                        type="text"
                        name="title"
                        placeholder="Enter the title"
                        onChange={(e) => handleChange(e)}
                    />
                    {getErrorObj.title? 
                    <span className="text-danger">
                      {getErrorObj.title}
                    </span> : null
                    }
                </Form.Group>

                <Form.Group>
                  <Form.Label className="form_label">Youtube URL</Form.Label>
                  <Form.Control as="textarea" rows={2}
                      type="text"
                      name="link"
                      placeholder="Enter Youtube URL"
                      onChange={(e) => handleChange(e)}
                  />
                  {getErrorObj.link? 
                  <span className="text-danger">
                    {getErrorObj.link}
                  </span> : null
                  }
              </Form.Group>

                <div className="mt-4">
                    <Button className="action__btn" variant="primary" type="submit">
                        Save
                    </Button>
                    <Link to="/youtube-link">
                        <Button className="action__btn" variant="info">
                            Back to Home
                        </Button>
                    </Link>
                </div>
            </Form>
        </div>  
    </Layout>
    </>
  )
}

export default YoutubeLinkCreate;