import React ,{useState,useEffect} from 'react'
import './jobpost.css';
import { Link , useNavigate} from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Button, Form, Toast } from 'react-bootstrap';
import http from "../../http"
import {type,subType,level,order,lang,department} from '../enum/enum'
import Layout from '../../components/layouts/layout';
import loginCheck from '../../helpers/loginCheck';
import Cookies from 'universal-cookie';
import { SelectPicker, DatePicker,DateRangePicker} from 'rsuite';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FullEditor from 'ckeditor5-build-full'
import uploadPlugin from '../../components/ImageUploader/uploadPlugin';

const JobPostCreate = () => {
    const { register, handleSubmit } = useForm();
    const [data,setData] = useState('');
    const [jobPost,setJobPost] = useState('');
    const [getSuccess,setSuccess] = useState(false);
    const [getError,setError] = useState('');
    const [getErrorObj,setErrorObj] = useState({})
    const [parents, setParents] = useState([]);
    const [departments, setdepartments] = useState([]);
    const [getLangError,setLangError] = useState('');
    const [getLoader,setLoader] = useState(false);
    const { allowedMaxDays, allowedDays, allowedRange, beforeToday, afterToday, combine } = DateRangePicker;

    const cookies = new Cookies();

    const navigate = useNavigate();

    useEffect(()=> {
        if(!loginCheck()){
            console.log("this works")
            navigate("/login");
        }
    });


    useEffect(()=>{
        fetchAllParents();
        // fetchAllDepartments();
    },[]);

    const fetchAllParents = () => {
        http.get('/job/parent', {
            headers:{
             "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
            }  
        }).then(res=>{
            setParents(res.data.data);
        })
    }

    const getDeptSearch = (val) =>{
        if(val.length > 0){
            http.get(`/department/search/${val}`, {
                headers:{
                 "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
                }  
            }).then((response)=>{
                setdepartments(response.data.data.data);
            })
        }else if(jobPost.department_id){
            http.get(`/department/get/${jobPost.department_id}`, {
                headers:{
                 "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
                }  
            }).then((response)=>{
                setdepartments([response.data.data]);
            })
        } else {
            setdepartments([]);
        }
    }

    function removeEmpty(obj) {
        return Object.fromEntries(
          Object.entries(obj)
            .filter(([_, v]) => v != null && v != "")
            .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
        );
    }

    const onSubmit = (data, e) => {
        setErrorObj({})
        setLangError('')
        const image_url = data.image_url[0];        
        delete jobPost.image_url;
        data = {...data, ...jobPost};
        data = removeEmpty(data)
        
        data.image_url = image_url;
        data.user_id = cookies.get('userAuth').id

        console.log(data)
        setLoader(true)

        http.post('/job/create',data, {
            headers:{
             "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
            }  
        }
        )
        .then((res)=>{
            console.log(res);
            localStorage.setItem("success", true);
            setTimeout(()=>{
              navigate("/job-post");
              },1000)
            })
        .catch((err)=> {
            setLoader(false);
            // console.log(Object.keys(err.response.data.data))
            if(err.response.data.message == "Validation Error."){
                let errs = err.response.data.data;
                let keys = Object.keys(errs)
                console.log(keys);
                let errors = {};
                keys.map((key)=> errors[key] = errs[key][0])
                console.info(errors);
                setError(err.response.data)
                
                setErrorObj(errors)
              }
              else{
                setLangError(err.response.data.data)
              }
        })
    }
    const onError = (errors, e) => console.log(errors, e);
    const handleChange = (e) =>{
        console.log(e);
        let job = {...jobPost};
        job[e.target.name] = e.target.value
        setJobPost(job);
    }

    const frommateDate = (val) =>{
        let tempDate = new Date(val).toUTCString().toString();
        // console.log(tempDate)
        let fetchDate = tempDate.substring(5, 7);

        let monthConvert = 
        tempDate.substring(8, 11)=='Jan' ? "01" :
        tempDate.substring(8, 11)=='Feb' ? "02" :
        tempDate.substring(8, 11)=='Mar' ? "03" :
        tempDate.substring(8, 11)=='Apr' ? "04" :
        tempDate.substring(8, 11)=='May' ? "05" :
        tempDate.substring(8, 11)=='Jun' ? "06" :
        tempDate.substring(8, 11)=='Jul' ? "07" :
        tempDate.substring(8, 11)=='Aug' ? "08" :
        tempDate.substring(8, 11)=='Sep' ? "09" :
        tempDate.substring(8, 11)=='Oct' ? "10" :
        tempDate.substring(8, 11)=='Nov' ? "11" : 
        tempDate.substring(8, 11)=='Dec' ? "12" : null
        // let date = fetchDate + tempDate.substring(8, 11) + ", " + tempDate.substring(12, 16) + " at " 
        let date = tempDate.substring(12, 16) + "-" + monthConvert + "-" + fetchDate    
        // console.log(date)

        let time = new Date(val).toLocaleTimeString('en-US',{
            hour12: false,
          });
    

        var combineDate = date +" "+ time;
        return combineDate;

    }

    
    function handleKeyPress(e) {
        // console.log(e.code)
        if (['Minus', 'NumpadSubtract'].includes(e.code)) {
            console.log(e.code);
            e.target.value = e.target.value.slice(0, -1)
        }
        else if(/^0/.test(e.target.value)) {
            e.target.value = e.target.value.replace(/^0/, "")
        }
        let intpart = e.target.value.split(".")[0];
        let decimal = e.target.value.split(".")[1].slice(0, 4);
        decimal = decimal.length > 0 ? "."+decimal : "";
        e.target.value = intpart + decimal;
        }
            

  return (
    <>
    <Layout>
        {getLoader && 
          <div className="preloader">
              <div className="overlay__inner">
                  <div className="overlay__content"><span className="spin"></span></div>
              </div>
          </div>
        }

        <div className="form_design">
            <h2 style={{color: "black",textDecoration: "underline"}}>Create Job Post</h2>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group>
                    <Form.Label className="form_label">Title</Form.Label>
                    <Form.Control
                        type="text"
                        name="title"
                        placeholder="Enter title"
                        // {...register("title")}
                        onChange={(e) => handleChange(e)}

                    />
                    {getErrorObj.title? 
                    <span className="text-danger">
                      {getErrorObj.title}
                    </span> : null
                    }
                </Form.Group>


                <Form.Group>
                    <Form.Label className="form_label">Sub Title</Form.Label>
                    <Form.Control
                        type="text"
                        name="sub_title"
                        placeholder="Enter Sub title"
                        // {...register("sub_title")}
                        onChange={(e) => handleChange(e)}

                    />
                    {getErrorObj.sub_title? 
                    <span className="text-danger">
                      {getErrorObj.sub_title}
                    </span> : null
                    }
                </Form.Group>
                
                <Form.Group>
                    <Form.Label className="form_label">Department</Form.Label>
                    <SelectPicker 
                          name="department_id" 
                          data={departments?.map((item) => ({ label: item.title, value: item.id }))} 
                          style={{ width: "100%" }} 
                          onSearch={(val)=>getDeptSearch(val)}
                          onChange={(value) => setJobPost({...jobPost, "department_id": value})}
                          />
                    {getErrorObj.department_id? 
                    <span className="text-danger">
                      {getErrorObj.department_id}
                    </span> : null
                    }
                </Form.Group>
            

                <Form.Group>
                    <Form.Label className="form_label">Image url</Form.Label>
                    <Form.Control
                        type="file"
                        name="image_url"
                        placeholder="Enter Image file"
                        {...register("image_url")}
                    />
                    {getErrorObj.image_url? 
                    <span className="text-danger">
                      {getErrorObj.image_url}
                    </span> : null
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Emplyment</Form.Label>
                    <Form.Control
                        type="text"
                        name="employment"
                        placeholder="Enter Employment"
                        // {...register("sub_title")}
                        onChange={(e) => handleChange(e)}

                    />
                    {getErrorObj.employment? 
                    <span className="text-danger">
                      {getErrorObj.employment}
                    </span> : null
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Location</Form.Label>
                    <Form.Control
                        type="text"
                        name="location"
                        placeholder="Enter Location"
                        // {...register("sub_title")}
                        onChange={(e) => handleChange(e)}

                    />
                    {getErrorObj.location? 
                    <span className="text-danger">
                      {getErrorObj.location}
                    </span> : null
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Workplace</Form.Label>
                    <Form.Control
                        type="text"
                        name="workplace"
                        placeholder="Enter Workplace"
                        // {...register("sub_title")}
                        onChange={(e) => handleChange(e)}

                    />
                    {getErrorObj.workplace? 
                    <span className="text-danger">
                      {getErrorObj.workplace}
                    </span> : null
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Email</Form.Label>
                    <Form.Control
                        type="email"
                        name="email"
                        placeholder="Enter Email"
                        // {...register("sub_title")}
                        onChange={(e) => handleChange(e)}

                    />
                    {getErrorObj.email? 
                    <span className="text-danger">
                      {getErrorObj.email}
                    </span> : null
                    }
                </Form.Group>


                <Form.Group>
                    <Form.Label className="form_label">Application Link</Form.Label>
                    <Form.Control
                        type="text"
                        name="application_link"
                        placeholder="Enter Application Link"
                        // {...register("sub_title")}
                        onChange={(e) => handleChange(e)}

                    />
                    {getErrorObj.application_link? 
                    <span className="text-danger">
                      {getErrorObj.application_link}
                    </span> : null
                    }
                </Form.Group>


                <Form.Group>
                    <Form.Label className="form_label">Source</Form.Label>
                    <Form.Control
                        type="text"
                        name="source"
                        placeholder="Enter Source"
                        // {...register("sub_title")}
                        onChange={(e) => handleChange(e)}

                    />
                    {getErrorObj.source? 
                    <span className="text-danger">
                      {getErrorObj.source}
                    </span> : null
                    }
                </Form.Group>


                <Form.Group>
                    <Form.Label className="form_label">Salary</Form.Label>
                    <Form.Control
                        type="text"
                        name="salary"
                        placeholder="Enter Salary"
                        // {...register("sub_title")}
                        onChange={(e) => handleChange(e)}

                    />
                    {getErrorObj.salary? 
                    <span className="text-danger">
                      {getErrorObj.salary}
                    </span> : null
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Vacancy</Form.Label>
                    <Form.Control
                        type="number"
                        name="vacancy"
                        step="0.01"
                        placeholder="Enter Vacancy"
                        onKeyUp={(e) => handleKeyPress(e)}
                        // {...register("visit")}
                        onChange={(e) => handleChange(e)}
                    />
                    {getErrorObj.vacancy? 
                    <span className="text-danger">
                      {getErrorObj.vacancy}
                    </span> : null
                    }
                </Form.Group>


                <Form.Group>
                    <Form.Label className="form_label">Responsibilities</Form.Label>
                    <CKEditor as="textarea" rows={10}
                        editor={ FullEditor }
                        config={{
                            extraPlugins: [uploadPlugin]
                        }}
                        type="text"
                        name="responsibilities"
                        placeholder="Enter Responsibilities"
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            console.log( { event, editor, data } );
                            setJobPost({...jobPost, "responsibilities": data})
                        } }
                    />
                    {getErrorObj.responsibilities? 
                    <span className="text-danger">
                      {getErrorObj.responsibilities}
                    </span> : null
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Description</Form.Label>
                    <CKEditor as="textarea" rows={10}
                        editor={ FullEditor }
                        config={{
                            extraPlugins: [uploadPlugin]
                        }}
                        type="text"
                        name="description"
                        placeholder="Enter Description"
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            console.log( { event, editor, data } );
                            setJobPost({...jobPost, "description": data})
                        } }
                    />
                    {getErrorObj.description? 
                    <span className="text-danger">
                      {getErrorObj.description}
                    </span> : null
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Experience</Form.Label>
                    <CKEditor as="textarea" rows={10}
                        editor={ FullEditor }
                        config={{
                            extraPlugins: [uploadPlugin]
                        }}
                        type="text"
                        name="experience"
                        placeholder="Enter Experience"
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            console.log( { event, editor, data } );
                            setJobPost({...jobPost, "experience": data})
                        } }
                    />
                    {getErrorObj.experience? 
                    <span className="text-danger">
                      {getErrorObj.experience}
                    </span> : null
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Education</Form.Label>
                    <CKEditor as="textarea" rows={10}
                        editor={ FullEditor }
                        config={{
                            extraPlugins: [uploadPlugin]
                        }}
                        type="text"
                        name="education"
                        placeholder="Enter Education"
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            console.log( { event, editor, data } );
                            setJobPost({...jobPost, "education": data})
                        } }
                    />
                    {getErrorObj.education? 
                    <span className="text-danger">
                      {getErrorObj.education}
                    </span> : null
                    }
                </Form.Group>


                <Form.Group>
                    <Form.Label className="form_label">Requirements</Form.Label>
                    <CKEditor as="textarea" rows={10}
                        editor={ FullEditor }
                        config={{
                            extraPlugins: [uploadPlugin]
                        }}
                        type="text"
                        name="requirements"
                        placeholder="Enter job Requirements"
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            console.log( { event, editor, data } );
                            setJobPost({...jobPost, "requirements": data})
                        } }
                    />
                    {getErrorObj.requirements? 
                    <span className="text-danger">
                      {getErrorObj.requirements}
                    </span> : null
                    }
                </Form.Group>


                <Form.Group>
                    <Form.Label className="form_label">Benefits</Form.Label>
                    <CKEditor as="textarea" rows={10}
                        editor={ FullEditor }
                        config={{
                            extraPlugins: [uploadPlugin]
                        }}
                        type="text"
                        name="benefits"
                        placeholder="Enter job benefits"
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            console.log( { event, editor, data } );
                            setJobPost({...jobPost, "benefits": data})
                        } }
                    />
                    {getErrorObj.benefits? 
                    <span className="text-danger">
                      {getErrorObj.benefits}
                    </span> : null
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">deadline</Form.Label>
                    <DatePicker
                        format="yyyy-MM-dd hh:mm:ss aa"
                        showMeridian
                        showWeekNumbers
                        disabledDate={beforeToday()}
                        style={{ width: "100%" }}
                        onChange={(value) => setJobPost({...jobPost, "deadline": frommateDate(value)})}
                        // onChange={(value) => frommateDate(value)}

                    />
                    {getErrorObj.from? 
                    <span className="text-danger">
                      {getErrorObj.from}
                    </span> : null
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Language Parent(English)</Form.Label>
                    <SelectPicker 
                          name="lang_parent_id" 
                          data={parents?.map((item) => ({ label: item.title, value: item.id }))} 
                          style={{ width: "100%" }} 
                          onChange={(value) => setJobPost({...jobPost, "lang_parent_id": value})}
                      />
                       {getErrorObj.lang_parent_id? 
                        <span className="text-danger">
                        {getErrorObj.lang_parent_id}
                        </span> : null
                        }
                        {getLangError? 
                        <span className="text-danger">
                          {getLangError}
                        </span> : null
                        }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Language</Form.Label>
                    <SelectPicker 
                          name="lang" 
                          data={Object.keys(lang).map((item) => ({ label: item, value: item }))} 
                          style={{ width: "100%" }} 
                          {...register("lang")} 
                          onChange={(value) => setJobPost({...jobPost, "lang": value})}
                      />
                    {getErrorObj.lang? 
                    <span className="text-danger">
                      {getErrorObj.lang}
                    </span> : null
                    }
                </Form.Group>


                <div className="mt-4">
                    <Button className="action__btn" variant="primary" type="submit">
                        Save
                    </Button>
                    <Link to="/job-post">
                        <Button className="action__btn" variant="info">
                            Back to Home
                        </Button>
                    </Link>
                </div>
            </Form>
        </div>  
    </Layout>
    </>
  )
}

export default JobPostCreate