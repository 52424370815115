import React ,{useEffect}from 'react'
import * as fa from "react-icons/fa";
import Layout from '../layouts/layout';
import { useNavigate } from 'react-router-dom';
import loginCheck from '../../helpers/loginCheck';
import { useState } from 'react';
const Icon = () => {
    const [keys , setKeys] = useState([])
    const [values , setValues] = useState([])
    const navigate = useNavigate();
    useEffect(()=> {
        if(!loginCheck()){
            navigate("/login");
        }
    });
    console.log(fa);
    let keyarr = []
    let valarr = []
    useEffect(()=> {
        Object.entries(fa).forEach(([key,value])=>{
            keyarr.push(key)
            valarr.push(value)
        })

        setKeys(keyarr);
        setValues(valarr);
    },[fa]);

    // console.log(keys);
    // console.log(values);
    return (
        <Layout>
            <div className='d-flex flex-row flex-wrap justify-content-between'>
                {values.map((key, index) => {
                    let CustomIconTag = key;
                    return (
                    <div style={{border: "2px solid black", borderRadius: "6px", padding: "10px", margin: "5px"}} className="d-flex flex-column flex-grow-1" key={key}>
                        <CustomIconTag style={{fontSize: '48'}} />
                        <p>
                            {keys[index]}  
                        </p>
                    </div>
                    );
                })}
            </div>
        </Layout>
      );
}

export default Icon;