import React ,{useState,useEffect} from 'react'
import './auth.css';
import { Link , useNavigate, useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Button, Form, Toast } from 'react-bootstrap';
import http from "../../http"
import logo from '../../images/Ibrahim-logo.png'
import emailLogo from '../../images/email.svg'
import passLogo from '../../images/pass.svg'
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const Login = () => {
    const { register, handleSubmit } = useForm();
    const [getLoginData,setLoginData] = useState('');
    const [data, setData] = useState('');
    const navigate = useNavigate();
    const [getItem, setItems] = useState([]);
    const [getError, setError] = useState({})
    const [getAuthError, setAuthError] = useState(false)
    // const history = useHistory()

    const onSubmit = (data, e) => {
        setError({})
        setAuthError(false)
        // data.image_url = data.image_url[0];
        // setData(data);

        data = {data,...getLoginData};
        console.log(data);
        http.post('/login',data)
        .then((res)=>{
            res.data.data.isSuper = res.data.data.roles_names.includes("SuperAdmin");
            console.log(res.data.data)
            // setItems(res.data.success)
            // localStorage.setItem("user-auth",res.data.success.user);
            cookies.set('userPermissions', JSON.stringify(res.data.data.permissions));
            delete res.data.data.permissions;
            delete res.data.data.roles;
            // console.log(res.data.data);
            cookies.set('userAuth', JSON.stringify(res.data.data));
            navigate("/");
            
            // setSuccess(true);
            // setTimeout(()=>{
                //     navigate("/navigation");
                // },3000)
            })
            .catch((err)=> {
                console.log(err.response.data.message)
                if(err.response.data.message == "Validation Error."){
                    let errs = err.response.data.data;
                    let keys = Object.keys(errs)
                    console.log(keys);
                    let errors = {};
                    keys.map((key)=> errors[key] = errs[key][0])
                    console.info(errors);
                    setError(errors)
                }
                else{
                    setAuthError(true)
                }
            })
        }
        // console.log(getAuthError)
    const onError = (errors, e) => console.log(errors, e);
  
 

    const handleChange = (e) => {
        setError('');
        setAuthError(false);

        console.log(e);
        let loginData = {...getLoginData};
        loginData[e.target.name] = e.target.value
        setLoginData(loginData);
    }

    

    
    return (
        <>
    <div className="form-design">
        <Form className="form-wrapper" onSubmit={handleSubmit(onSubmit)}>
            <img src={logo} style={{marginBottom:"44px"}}/>
            {getAuthError ?
                <div className="mt-4">
                    <p className="authError">
                        The Credential is not Correct
                    </p>
                </div>
                :
                null
            }
            <Form.Group className="input_wrapper">
                {/* <Form.Label className="form_label">Email</Form.Label> */}
                <Form.Control
                    type="email"
                    name="email"
                    className="email"
                    // value={id}
                    placeholder="Enter the Email"
                    // {...register("email")}
                    onChange={(e) => handleChange(e)}
                />
                <div class="input_field_icon">
                    <img alt="content_img" src={emailLogo}/>
                </div>
                {getError.email? 
                <span className="text-danger warn" style={{marginTop:"2px"}}>
                    {getError.email}
                </span> : null
                }
            </Form.Group>

            <Form.Group  className="input_wrapper">
                {/* <Form.Label className="form_label">Password</Form.Label> */}
                <Form.Control
                    type="password"
                    name="password"
                    className="password"
                    // value={id}
                    placeholder="Enter the password"
                    onChange={(e) => handleChange(e)}

                    // {...register("password")}
                />
                <div class="input_field_icon">
                    <img alt="content_img" src={passLogo}/>
                </div>
                {getError.password? 
                <span className="text-danger warn">
                    {getError.password}
                </span> : null
                }
            </Form.Group>
            <div className="mt-4">
                <Button className="login_btn" variant="primary" type="submit">
                    LOGIN
                </Button>
                <Link to="/forgot-password" className="forgot-password">Forgot Password?</Link>
            </div>
        </Form>
    </div>
    </>
  )
}

export default Login;