import React ,{useState,useEffect} from 'react'
import { Link, useParams,useNavigate } from 'react-router-dom';
import { useForm,setValue } from "react-hook-form";
import { Button, Form, Toast } from 'react-bootstrap';

import http from "../../http";
import { BsFillCursorFill} from "react-icons/bs";
import {lang} from '../enum/enum'
import './room.css';
import TextLineLoader from '../../components/Loaders/TextLineLoader';
import loginCheck from '../../helpers/loginCheck';
import Layout from '../../components/layouts/layout';
import Cookies from 'universal-cookie';
import { SelectPicker,TagInput } from 'rsuite';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FullEditor from 'ckeditor5-build-full'
import uploadPlugin from '../../components/ImageUploader/uploadPlugin'

const RoomEdit = () => {
    const {id} = useParams()
    const [rooms, setRooms] = useState('');
    const {register, handleSubmit } = useForm();
    const [data,setData] = useState('');
    const [getSuccess,setSuccess] = useState(false);
    const [getError,setError] = useState('');
    const [getErrorObj,setErrorObj] = useState({})
    const [parents, setParents] = useState([]);
    const [getLangError,setLangError] = useState('');
    const [getLoader,setLoader] = useState(false);
    const cookies = new Cookies();
    const image = process.env.REACT_APP_IMAGE_BASE_URL

    const navigate = useNavigate();
    useEffect(()=> {
        if(!loginCheck()){
            console.log("this works")
            navigate("/login");
        }
    });

    // useEffect(()=>{
    //   fetchAllParents();
    // },[]);

    // const fetchAllParents = () => {
    //     http.get('/page/parent', {
    //       headers:{
    //        "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
    //       }  
    //   }).then(res=>{
    //         setParents(res.data.data);
    //     })
    // }
    
    
    useEffect(()=>{
      fetchEditPages();
    },[]);
    
    const fetchEditPages = () => {
      http.get('room/get/'+id, {
        headers:{
         "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
        }  
    }).then(res=>{
        // console.log(">>>>>>>>>>>>>>>>>>>>>>>>",Object.values(res.data.data.meta));
        setRooms(removeEmpty(res.data.data));
      })
    }
    console.log(rooms)
    
    function removeEmpty(obj) {
      return Object.fromEntries(
        Object.entries(obj)
          .filter(([_, v]) => v != null && v != "")
          .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
      );
    }

    const onSubmit = (data, e) => {
      setErrorObj({})
      setLangError('')
      const image_url = data.image_url[0];
      delete rooms.image_url;
      data = {...data, ...rooms};
      data.user_id = cookies.get('userAuth').id
      // data = removeEmpty(data)

      data.image_url = image_url;
     
      console.log(data)
      
      setLoader(true)
      http.post('/room/update/'+id,data,{
        headers:{
            "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
           }  
      })
      .then((res)=>{
        console.log(res);
        localStorage.setItem("successEdit", true);
        // setLoader(false);
        setTimeout(()=>{
          navigate("/room");
          },500)
        })
      .catch((err)=> {
        setLoader(false);
          // console.log(Object.keys(err.response.data.data))
          if(err.response.data.message == "Validation Error."){
            let errs = err.response.data.data;
            let keys = Object.keys(errs)
            console.log(keys);
            let errors = {};
            keys.map((key)=> errors[key] = errs[key][0])
            console.info(errors);
            setError(err.response.data)
            
            setErrorObj(errors)
          }
          else{
            setLangError(err.response.data.data)
          }
      })
  }

  const onError = (errors, e) => console.log(errors, e);

  const handleChange = (e) =>{
    console.log(e);
    let room = {...rooms};
    room[e.target.name] = e.target.value
    setRooms(room);
}

function handleKeyPress(e) {
    // console.log(e.code)
    if (['Minus', 'NumpadSubtract'].includes(e.code)) {
        console.log(e.code);
        e.target.value = e.target.value.slice(0, -1)
    }
    else if(/^0/.test(e.target.value)) {
        e.target.value = e.target.value.replace(/^0/, "")
    }
    let intpart = e.target.value.split(".")[0];
    let decimal = e.target.value.split(".")[1].slice(0, 4);
    decimal = decimal.length > 0 ? "."+decimal : "";
    e.target.value = intpart + decimal;
    }


return (
    <>
    <Layout>

      { getLoader && 
        <div className="preloader">
            <div className="overlay__inner">
                <div className="overlay__content"><span className="spin"></span></div>
            </div>
        </div>
      }

      {rooms == "" ? 
              <><TextLineLoader/><TextLineLoader/><TextLineLoader/></>:
      <div className="form_design">
            <h2 style={{color: "black",textDecoration: "underline"}}>Edit Room</h2>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group>
                  <Form.Label className="form_label">Name</Form.Label>
                  <Form.Control
                      type="text"
                      name="name"
                      defaultValue={rooms.name}
                      placeholder="Enter Name"
                      // {...register("title")}
                      onChange={(e) => handleChange(e)}

                  />
                  {getErrorObj.name? 
                  <span className="text-danger">
                    {getErrorObj.name}
                  </span> : null
                  }
              </Form.Group>

              <Form.Group>
                    <Form.Label className="form_label">Room Number</Form.Label>
                    <Form.Control
                        type="number"
                        name="number"
                        step="0.01"
                        defaultValue={rooms.number}
                        placeholder="Enter Room Number"
                        onKeyUp={(e) => handleKeyPress(e)}
                        // {...register("visit")}
                        onChange={(e) => handleChange(e)}
                    />
                    {getErrorObj.number? 
                    <span className="text-danger">
                      {getErrorObj.number}
                    </span> : null
                    }
                </Form.Group>

              <Form.Group>
                  <Form.Label className="form_label">Image url</Form.Label>
                  <Form.Control
                      type="file"
                      name="image_url"
                      placeholder="Enter Image file"
                      {...register("image_url")}
                  />
                  <p>
                    {
                      (rooms.image_url) ? <a href={image+'/'+rooms.image_url} target="_blank"><BsFillCursorFill/> </a> : ''
                    }
                  </p>
                  {getErrorObj.image_url? 
                  <span className="text-danger">
                    {getErrorObj.image_url}
                  </span> : null
                  }
              </Form.Group>

              <Form.Group>
                    <Form.Label className="form_label">Description</Form.Label>
                    <CKEditor as="textarea" rows={10}
                        config={{
                          extraPlugins: [uploadPlugin]
                        }}
                        data={rooms.description}
                        editor={ FullEditor }
                        type="text"
                        name="description"
                        placeholder="Enter Description"
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            console.log( { event, editor, data } );
                            setRooms({...rooms, "description": data})
                        } }
                    />
                    {getErrorObj.description? 
                    <span className="text-danger">
                      {getErrorObj.description}
                    </span> : null
                    }
                </Form.Group>

              <div className="mt-4">
                  <Button className="action__btn" variant="primary" type="submit">
                      Update
                  </Button>
                  <Link to="/room">
                      <Button className="action__btn" variant="info">
                          Back to Home
                      </Button>
                  </Link>
              </div>
            </Form>
          </div> 
        } 
    </Layout>
    </>
  );
};


export default RoomEdit